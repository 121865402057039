<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2"
  *ngIf=" roles == 'ADMINISTRADOR'  || roles == 'ADMINISTRADOR_VALIDADOR'  || roles == 'ADMIN_COMPUTO' || roles == 'ADMIN_LAB_INGLES' || roles == 'ADMIN_VIDEO_CONFERENCIA'  || roles == 'ADMIN_SALA_ACAD'  || roles == 'ADMIN_APASUTPL' || roles == 'ADMIN_SLREUNIONES'  || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">


  <div class="card colorCard">
    <div class="card-body text-center titlePage">
      <strong>Validación de solicitud de reservas | Reservas UTPL</strong>
    </div>
    <!-- aprobrar todas las reservas solicitadas -->
    <div class="card colorCard border-0">
      <div class="row">
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body abs-center">
              <div class="  titlePage">
                <strong>Validación de solicitudes de reservas de {{CategoriaName}}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body">
              <p>Por favor, seleccione la categoría:</p>
              <select class="form-select" aria-label="Default select example" [(ngModel)]="CategoriaSelect"
                (change)="selectCategory()">
                <option *ngFor="let bsc of categorias" value="{{bsc.id}}-_{{bsc.nombreCategoria}}">
                  {{bsc.nombreCategoria}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-3">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Solicitudes de hoy</a>
        <ng-template ngbNavContent>

          <div class="card m-3"*ngIf="reservas?.length != 0" >
            <div class="p-2 table-responsive justify-content-center">
              <table datatable [dtOptions]="dtOptions[0]"  class="row-border hover table-striped table-sm" *ngIf="reservas.length">
                <thead>
                  <tr>
                    <th scope="col" class="vrt-align-center">A nombre</th>
                    <th scope="col" class="vrt-align-center">Rol del solicitante</th>
                    <th scope="col" class="vrt-align-center">Recurso solicitado</th>
                    <th scope="col" class="vrt-align-center">Ubicación y capacidad</th>
                    <th scope="col" class="vrt-align-center">Fecha y hora</th>
                    <th scope="col" class="vrt-align-center">Número de participantes </th>
                    <th scope="col" class="vrt-align-center">Tema reserva</th>
                    <th scope="col" class="vrt-align-center">Descripción reserva</th>
                    <th scope="col" class="vrt-align-center">Aceptar o Denegar</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let reserva of reservas" class="vrt-align">

                    <th class="vrt-align-center">
                      {{reserva.namesReservador}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.usuario.rol}}
                    </th>

                    <td class="vrt-align-center">
                      <div class=" cardFont">
                        <i class="bi bi-geo-fill"></i>
                        {{reserva.recurso.categoria.nombreCategoria}}
                      </div>
                      <div class=" cardFont">{{reserva.recurso.nombreRecurso}}</div>

                    </td>

                    <td class="vrt-align-center">
                      <label class="form-label">
                        <b> <div class=" cardFont2">{{reserva.recurso.pisos.nombrePiso}}</div></b>
                        Capacidad para <b>{{reserva.recurso.capacidad}}</b></label>
                    </td>

                    <td class="vrt-align-center">
                      <label class="form-label"> <b>{{reserva.fechaReservaFin?.split(" ")[0]}} </b>
                         <b>{{reserva.fechaReservaInicio?.split(" ")[1]}}</b> -
                        <b>{{reserva.fechaReservaFin?.split(" ")[1]}}</b> </label>
                    </td>

                    <th class="vrt-align-center">
                      {{reserva.numeroAsistentes}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.temaReserva}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.descripcionReserva}}
                    </th>

                    <td class="vrt-align-center">
                      <button class="btn btn-primary m-2" (click)="Aceptar(reserva)" ngbTooltip="Aceptar"><i
                          class="bi bi-pencil-square"></i></button>
                      <button (click)="RechazarSolicitud(reserva)" type="button" class="btn btn-danger "
                        ngbTooltip="Denegar"><i class="bi bi-patch-exclamation-fill"></i></button>
                    </td>
                  </tr>
                </tbody>

                <!--<tbody *ngIf="reservas?.length == 0">
                  <tr>
                    <td colspan="8">No existen solicitudes planificada para hoy</td>
                  </tr>
                </tbody>-->

                <!--<tfoot>
                  <tr>
                    <th scope="col" class="vrt-align-center">A nombre</th>
                    <th scope="col" class="vrt-align-center">Rol del solicitante</th>
                    <th scope="col" class="vrt-align-center">Recurso solicitado</th>
                    <th scope="col" class="vrt-align-center">Ubicación y capacidad</th>
                    <th scope="col" class="vrt-align-center">Fecha y hora</th>
                    <th scope="col" class="vrt-align-center">Tema reserva</th>
                    <th scope="col" class="vrt-align-center">Descripción reserva</th>
                    <th scope="col" class="vrt-align-center">Aceptar o Denegar</th>
                  </tr>
                </tfoot>-->
              </table>
            </div>
          </div>

          <div class="container col-md-11 pt-4" *ngIf="reservas?.length == 0" style="padding-bottom: 19%;">
            <div class="card ">
              <h5 class="card-header"><i class="bi bi-x-circle-fill ColorMal"></i> Alerta</h5>
              <div class="card-body">
                <p class="card-text">No existen solicitudes planificada para hoy</p>
              </div>
            </div>
          </div>

        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Próximas solicitudes</a>
        <ng-template ngbNavContent>

          <div class="card m-3"  *ngIf="reservasTomorrow?.length != 0">
            <div class="p-2 table-responsive justify-content-center">
              <table datatable [dtOptions]="dtOptions[1]"   class="row-border hover example table table-striped table-sm" *ngIf="reservasTomorrow.length">
                <thead>
                  <tr>
                    <th scope="col" class="vrt-align-center">A nombre</th>
                    <th scope="col" class="vrt-align-center">Rol del solicitante</th>
                    <th scope="col" class="vrt-align-center">Recurso solicitado</th>
                    <th scope="col" class="vrt-align-center">Ubicación y capacidad</th>
                    <th scope="col" class="vrt-align-center">Fecha y hora</th>
                    <th scope="col" class="vrt-align-center">Número de participantes</th>
                    <th scope="col" class="vrt-align-center">Tema reserva</th>
                    <th scope="col" class="vrt-align-center">Descripción reserva</th>
                    <th scope="col" class="vrt-align-center">Aceptar o Rechazar</th>
                  </tr>
                </thead>
                <tbody>
                  <!--<tr  *ngIf="registro.opcionTitulacion==='Tesis'" > -->

                  <tr *ngFor="let reserva of reservasTomorrow" class="vrt-align">

                    <th class="vrt-align-center">
                      {{reserva.namesReservador}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.usuario.rol}}
                    </th>

                    <td class="vrt-align-center">
                      <div class=" cardFont">
                        <i class="bi bi-geo-fill"></i>
                        {{reserva.recurso.categoria.nombreCategoria}}
                      </div>
                      <div class=" cardFont">{{reserva.recurso.nombreRecurso}}</div>

                    </td>

                    <td class="vrt-align-center">
                      <label class="form-label">
                        <b> <div class=" cardFont2">{{reserva.recurso.pisos.nombrePiso}}</div></b>
                        Capacidad para <b>{{reserva.recurso.capacidad}}</b></label>
                    </td>

                    <td class="vrt-align-center">
                      <label class="form-label"> <b>{{reserva.fechaReservaFin?.split(" ")[0]}} </b>
                         <b>{{reserva.fechaReservaInicio?.split(" ")[1]}}</b> -
                        <b>{{reserva.fechaReservaFin?.split(" ")[1]}}</b> </label>
                    </td>

                    <th class="vrt-align-center">
                      {{reserva.numeroAsistentes}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.temaReserva}}
                    </th>

                    <th class="vrt-align-center">
                      {{reserva.descripcionReserva}}
                    </th>

                    <td class="vrt-align-center">
                      <button class="btn btn-primary m-2" (click)="Aceptar(reserva)" ngbTooltip="Aceptar"><i
                          class="bi bi-pencil-square"></i></button>
                      <button (click)="RechazarSolicitud(reserva)" type="button" class="btn btn-danger "
                        ngbTooltip="Rechazar"><i class="bi bi-patch-exclamation-fill"></i></button>
                    </td>
                  </tr>

                </tbody>

                <!--<tbody *ngIf="reservasTomorrow?.length == 0">
                  <tr>
                    <td colspan="8">No existen solicitudes planificadas para los próximos días</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <th scope="col" class="vrt-align-center">A nombre</th>
                    <th scope="col" class="vrt-align-center">Rol del solicitante</th>
                    <th scope="col" class="vrt-align-center">Recurso solicitado</th>
                    <th scope="col" class="vrt-align-center">Ubicación y capacidad</th>
                    <th scope="col" class="vrt-align-center">Fecha y hora</th>
                    <th scope="col" class="vrt-align-center">Tema reserva</th>
                    <th scope="col" class="vrt-align-center">Descripción reserva</th>
                    <th scope="col" class="vrt-align-center">Aceptar o Rechazar</th>
                  </tr>
                </tfoot>-->
              </table>
            </div>
          </div>

          <div class="container col-md-11 pt-4" *ngIf="reservasTomorrow?.length == 0" style="padding-bottom: 19%;">
            <div class="card">
              <h5 class="card-header"><i class="bi bi-x-circle-fill ColorMal"></i> Alerta</h5>
              <div class="card-body">
                <p class="card-text">No existen solicitudes planificadas para los próximos días</p>
              </div>
            </div>
          </div>

        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <pre>Página: {{ active }}</pre>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

</section>

<section class="m-2" *ngIf="roles == 'null'  || roles == 'BIBLIOTECARIO'  || roles == 'ADMIN-BBC' || roles == 'ALUMNI' || roles === 'DOCENTE' || roles === 'ADMINISTRATIVO' || roles == 'ESTUDIANTE' || roles === 'TECNICO DOCENTE' ">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<div id="loading-screen" style="display:none; " #loadingScreens>
  <img src="./assets/img/spinning-circles.svg">
</div>


<ng-template let-d="dismiss" #contentModal data-bs-keyboard="false">
  <div class="m-3 textCenter">
    <h2 class="textCenter"> 📢 🛠Atención</h2>
  </div>
  <div class="modal-body">
    <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
  </div>
</ng-template>

<ng-template let-d="dismiss" #contentModalEdit data-bs-keyboard="false">
  <div class="m-3 textCenter">
    <h2 class="textCenter"> 📢 🛠 ⚙️ Atención</h2>
  </div>
  <div class="modal-body">
    <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
  </div>
</ng-template>
