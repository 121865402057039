<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2" *ngIf=" roles == 'ADMINISTRADOR'">
  <mat-card class="colorCard text-center">
    <h1>Listado de Categorías | Reservas UTPL</h1>
  </mat-card>
  <br>
  <div class="d-flex justify-content-end">
    <button class="btn colorUTPLModal m-1" (click)="openLg(content)">Añadir Categoría</button>
    <button class="btn colorUTPLBotton m-1" placement="top" ngbTooltip="Descargar"><i class="bi bi-file-earmark-arrow-down-fill"></i></button>
    <button class="btn colorUTPLBotton m-1" placement="left" ngbTooltip="Información"><i class="bi bi-info-circle"></i></button>
  </div>
  <hr>
  <br>

  <div class="p-2 table-responsive justify-content-center">
    <table datatable [dtOptions]="dtOptions" class="row-border hover example table table-striped table-sm"
    *ngIf="categoria.length">
      <thead>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Categoría</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </thead>
      <tbody >
        <!--<tr  *ngIf="registro.opcionTitulacion==='Tesis'" > -->

        <tr *ngFor="let cate of categoria" class="vrt-align">
          <th class="vrt-align-center">{{cate.id}}</th>
          <td class="vrt-align-center">{{cate.nombreCategoria}}</td>
          <td class="vrt-align-center">{{cate.descripcion}} </td>
          <td class="vrt-align-center">{{cate.ubicacion}} </td>
          <td class="vrt-align-center">
            <!-- <button (click)="update(registro)" type="button" class="btn btn-warning m-2">Editar</button>-->
            <button class="btn btn-warning m-2" (click)="updateShow(cate)" (click)="open(update)" ngbTooltip="Editar"><i class="bi bi-pencil-square"></i></button>
            <button (click)="deleteEnable(cate)" type="button" class="btn btn-danger " ngbTooltip="Deshabilitar"><i class="bi bi-patch-exclamation-fill"></i></button>
          </td>
        </tr>

      </tbody>

      <tfoot>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Categoría</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </tfoot>
    </table>
  </div>
</section>

<section class="m-2" *ngIf="roles == 'ESTUDIANTE' || roles == 'DOCENTE'  || roles == 'BIBLIOTECARIO' || roles == 'ADMINISTRATIVO' || roles == 'null'  || roles == 'ADMIN-BBC' || roles == 'AUTORIDADES'">
  <div class="container" >

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<!-- formulario para registar-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h3 class="modal-title" id="modal-basic-title">Formulario registro de categoría de Biblioteca</h3>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del categoría
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="categoriaSave.nombreCategoria">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="categoriaSave.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #ubicacion
            [(ngModel)]="categoriaSave.ubicacion">
        </div>

        <div class="col-md-12 mb-2">
          <label for="maximoDia" class="form-label"> Máximo tiempo para reservar en días
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="maximoDia" id="maximoDia" name="maximoDia"
              aria-describedby="basic-addon2" [(ngModel)]="categoriaSave.maximoDia">
            <span class="input-group-text" id="basic-addon2">Días</span>
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <label for="rolAccess" class="form-label"> Roles de acceso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rolAccess" name="rolAccess" #rolAccess
            [(ngModel)]="categoriaSave.rolAccess">
        </div>

        <div class="col-md-12 mb-2">
          <label for="diasNoLabora" class="form-label"> Días de no atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="diasNoLabora" name="diasNoLabora" #diasNoLabora
            [(ngModel)]="categoriaSave.diasNoLabora">
        </div>

        <div class="col-md-12 mb-2">
          <label for="horarioAtencion" class="form-label"> Horario de atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="horarioAtencion" name="horarioAtencion" #horarioAtencion
            [(ngModel)]="categoriaSave.horarioAtencion">
        </div>

        <div class="col-md-12 mb-2">
          <label for="tiempoUso" class="form-label"> Tiempo de Uso
            <i style="color: red;"><strong>*</strong></i></label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" aria-label="Username" id="tiempoUso" name="tiempoUso"
                aria-describedby="basic-addon2" [(ngModel)]="categoriaSave.tiempoUso">
                <span class="input-group-text" id="basic-addon2">Minutos</span>
            </div>
            <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>
        </div>
        <div class="col-md-12 mb-2">
          <label for="tiempoEspera" class="form-label"> Tiempo de espera
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="Username" id="tiempoEspera" name="tiempoEspera"
              aria-describedby="basic-addon2" [(ngModel)]="categoriaSave.tiempoEspera">
              <span class="input-group-text" id="basic-addon2">Minutos</span>
          </div>
          <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>

        </div>

        <div class="col-md-12 mb-2">
          <label for="diasNoLabora" class="form-label"> Días de no atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="diasNoLabora" name="diasNoLabora" #diasNoLabora
            [(ngModel)]="categoriaSave.diasNoLabora">
        </div>

        <div class="col-md-12 mb-2">
          <label for="horarioAtencion" class="form-label"> Horario de atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="horarioAtencion" name="horarioAtencion" #horarioAtencion
            [(ngModel)]="categoriaSave.horarioAtencion">
        </div>

        <div class="mb-3">
          <label for="validarReservas" class="form-label">Validación para creación de la reserva <i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="validarReservas" id="validarReservas"
            [(ngModel)]="categoriaSave.validarReservas">
            <option value="false">Validar reservas por tercero</option>
            <option value="true">Validación automáticamente</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="formFileMultiple" class="form-label">Cargar imagen
            <i style="color: red;"><strong>*</strong></i></label>
          <input class="form-control" type="file" name="files" id="formFileMultiple" (change)="dataChanged($event)" accept="image/*" multiple>
        </div>
        <div class="col-md-12 mb-2" style="display: none;">
          <label for="rutaImg" class="form-label"> rutaImg
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rutaImg" name="rutaImg" #rutaImg
            [ngModel]="categoriaSave.rutaImg">
        </div>

        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" [disabled]="!categoriaSave.ubicacion" (click)="saveRegister(categoriaSave)"
          (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario para editar-->
<ng-template #update let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h2 class="modal-title" id="modal-basic-title">Actualizar registro de categoría</h2>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del categoría
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="categoriaUpdate.nombreCategoria">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="categoriaUpdate.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #ubicacion
            [(ngModel)]="categoriaUpdate.ubicacion">
        </div>

        <div class="col-md-12 mb-2">
          <label for="maximoDia" class="form-label"> Máximo tiempo para reservar en días
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="maximoDia" id="maximoDia" name="maximoDia"
              aria-describedby="basic-addon2" [(ngModel)]="categoriaUpdate.maximoDia">
            <span class="input-group-text" id="basic-addon2">Días</span>
          </div>
        </div>


        <div class="col-md-12 mb-2">
          <label for="rolAccess" class="form-label"> Roles de acceso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rolAccess" name="rolAccess" #rolAccess
            [(ngModel)]="categoriaUpdate.rolAccess">
        </div>

        <div class="col-md-12 mb-2">
          <label for="tiempoUso" class="form-label"> Tiempo de Uso
            <i style="color: red;"><strong>*</strong></i></label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" aria-label="Username" id="tiempoUso" name="tiempoUso"
                aria-describedby="basic-addon2" [(ngModel)]="categoriaUpdate.tiempoUso">
                <span class="input-group-text" id="basic-addon2">Minutos</span>
            </div>
            <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>
        </div>
        <div class="col-md-12 mb-2">
          <label for="tiempoEspera" class="form-label"> Tiempo de espera
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="Username" id="tiempoEspera" name="tiempoEspera"
              aria-describedby="basic-addon2" [(ngModel)]="categoriaUpdate.tiempoEspera">
              <span class="input-group-text" id="basic-addon2">Minutos</span>
          </div>
          <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>
        </div>
        <div class="col-md-12 mb-2">
          <label for="diasNoLabora" class="form-label"> Días de no atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="diasNoLabora" name="diasNoLabora" #diasNoLabora
            [(ngModel)]="categoriaUpdate.diasNoLabora">
        </div>

        <div class="col-md-12 mb-2">
          <label for="horarioAtencion" class="form-label"> Horario de atención
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="horarioAtencion" name="horarioAtencion" #horarioAtencion
            [(ngModel)]="categoriaUpdate.horarioAtencion">
        </div>

        <div class="mb-3">
          <label for="validarReservas" class="form-label">Validación para creación de la reserva <i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="validarReservas" id="validarReservas"
            [(ngModel)]="categoriaUpdate.validarReservas">
            <option value="false">Validar reservas por tercero</option>
            <option value="true">Validación automáticamente</option>
          </select>
        </div>


        <div class="col-md-12">
          <label for="formFileMultiple" class="form-label">Imagen actual</label>
          <div class="container">
            <img src="{{rutaImage}}" class="container img-fluid rounded-start" alt="...">
          </div>
        </div>

        <div class="mb-3">
          <label for="formFileMultiple" class="form-label">Cargar imagen nueva
            <i style="color: red;"><strong>*</strong></i></label>
          <input class="form-control" type="file" name="files" id="formFileMultiple" (change)="dataChanged($event)" accept="image/*" multiple>
        </div>
        <div class="col-md-12 mb-2" style="display: none;">
          <label for="rutaImg" class="form-label"> rutaImg
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rutaImg" name="rutaImg" #rutaImg
            [ngModel]="categoriaUpdate.rutaImg">
        </div>
        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" (click)="actualizar(categoriaUpdate)" (click)="d('Cross click')"
          class="btn btn-warning">Actualizar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
