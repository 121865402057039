export class Usuarios {
  id!: string;
  bannerId!: string;
  names!: string;
  namesPerson!: string;
  username!: string;
  userEmail!: string;
  rol!: string;
  modalidad!: string;
  nivelAcademico!: string;
  programa!: string;
  carrera!: string;
  facultad!: string;
  departamento!: string;
  centroUniversitario!: string;
  enable!: boolean;
}
