import { Categorias } from "./Categorias";
import { Pisos } from "./Pisos";

export class Recurso {
  id!: number;
  nombreRecurso!: string;
  descripcion!: string;
  ubicacion!: string;
  estadoUso!: string;
  estadoEspera!: string;
  capacidad!: string;
  categoria!: Categorias;
  pisos!: Pisos;
}
