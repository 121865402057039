import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Categorias } from '../model/Categorias';
import { Pisos } from '../model/Pisos';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {


  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  UrlCategorias = this.URL_BASE+'/api/v1/allCategorias';

   // API PARA guardar, editar y eliminar
   UrlSaveDelete = this.URL_BASE+'/api/v1/categoria';

   //URL TO IMG SAVE
   UrlIMG = this.URL_BASE+'/api/v1/images/categoria';

   //URL TO no day atetion
   UrlNoDay = this.URL_BASE+'/api/v1/categoria/noDias';

  // URL para recuperar
  UrlCategoriaValidar = this.URL_BASE+'/api/v1/categoria/aprobationList';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  UrlCategoriaListRol = this.URL_BASE+'/api/v1/categoria/aprobationListRol';

  // API PARA OBTENER TODAS LAS CATEGORIAS POR ROL PARA EL ADMINISTRADOR DE RESERVAS GENERAL
  UrlCategoriaListRolAdmin = this.URL_BASE+'/api/v1/categoria/listAdmin';

  // API PARA OBTENER TODAS LAS CATEGORIAS QUE REQUIRAN VALIDAR
  UrlCategoriaValidation = this.URL_BASE+'/api/v1/categoria/status';

   // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) { }

  // obtener el las categorias
  getCategoria(){
    //console.log(this.http.get<Categorias[]>(this.UrlCategorias));
    return this.http.get<Categorias[]>(this.UrlCategorias, this.headers);
  }

  getCategoriaListWithRol(user: string) {
    //console.log(this.http.get<Categorias[]>(this.UrlCategorias));
    return this.http.get<Categorias[]>(this.UrlCategoriaListRol+ "/"+ user, this.headers);
  }

  // obtener las categorias de todos los que tengan un panel de administracion
  getCategoriaListRolToAdmin(user: string) {
    return this.http.get<Categorias[]>(this.UrlCategoriaListRolAdmin+ "/"+ user, this.headers);
  }

  getCategoriaValidarReserva(){
    //console.log(this.http.get<Categorias[]>(this.UrlCategoriaValidar, this.headers));
    return this.http.get<Categorias[]>(this.UrlCategoriaValidar, this.headers);
  }

   // save piso
   postCategorias(CategoriasSave:Categorias) {
    return this.http.post<Categorias>(this.UrlSaveDelete, CategoriasSave, this.headers);
  }

  UpadteRegistro(categoria:Categorias){
    return this.http.put<Categorias>(this.UrlSaveDelete+"/"+categoria.id, categoria, this.headers);
  }

  deleteEnableRegistro(categoria:Categorias){
    return this.http.delete(this.UrlSaveDelete+"/"+categoria.id, this.headers);
  }

  postImg(CategoriasSave:Categorias) {
   return this.http.post(this.UrlIMG, CategoriasSave, this.headers);
  }

  postValidate(id:number) {
    return this.http.get(this.UrlCategoriaValidation+"/"+id, this.headers);
   }


  getNoDia(id:number) {
    console.log("getNoDia "+this.UrlNoDay+"/"+id);
    return this.http.get(this.UrlNoDay+"/"+id, this.headers);
  }
}
