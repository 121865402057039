import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { EmailBody } from '../model/EmailBody';
import { PrestamoSave } from '../model/PrestamoSave';
import { Reservas } from '../model/Reservas';
import { ReservaSave } from '../model/ReservaSave';
import { reservasDto } from '../model/reservasDto';

@Injectable({
  providedIn: 'root',
})
export class ReservasService {
  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  Url = this.URL_BASE + '/api/v1/reservabiblio';
  UrlListAll = this.URL_BASE + '/api/v1/reservabiblio/all';
  UrlListAllDto = this.URL_BASE + '/api/v1/reservabiblioDto/all';
  UrlSaveList = this.URL_BASE + '/api/v1/reservabiblio';
  // api para obtener todos las reservas proximas
  UrlSaveListProx = this.URL_BASE + '/api/v1/reservabiblioProx';

  //reservabibliov2email
  UrlSaveListV2 = this.URL_BASE + '/api/v1/reservabibliov2';

  // api para prestamo o devolución de libros
  UrlSavePrestamo = this.URL_BASE + '/api/v1/prestamoLibro';

  // api de post, put, delete para reservas v3
  UrlSaveListV3 = this.URL_BASE + '/api/v1/reservabibliov3';

  // api de post, put, delete para reservas v4
  UrlSaveListV4 = this.URL_BASE + '/api/v1/reservabibliov4';

  // api de post, put, delete para reservas en DateLine
  UrlSaveListDateLine = this.URL_BASE + '/api/v1/reservaDateLine';
  UrlGetListDateLine = this.URL_BASE + '/api/v1/reservaDateLine/all';
  UrlGetRecursoDateLine = this.URL_BASE + '/api/v1/recursobiblio/all';
  // api de post, put, delete para reservas en DateLine con validacion de solicitud
  UrlSaveListDateLineTF = this.URL_BASE + '/api/v1/reservaDateLine/tf';
  UrlSaveListDateLineTFAprovate = this.URL_BASE + '/api/v1/reservaDateLine/aprovate';

  UrlSaveListDateLineTFAcept = this.URL_BASE + '/api/v1/reservaDateLineAcept';



  UrlGetRecursoDateLineV2 = this.URL_BASE + '/api/v1/recurso/biblioteca/all';
  UrlGetRecursoDateLineUserV2 = this.URL_BASE + '/api/v1/recurso/biblioteca/';
  UrlGetReservasDateLineV2 = this.URL_BASE + '/api/v1/reservaDateLine/all';
  UrlGetReservasDateLineUserV2 = this.URL_BASE + '/api/v1/reservaDateLine/';

  urlSendMail = this.URL_BASE + '/api/v1/reservaMailOk';
  urlSendMailError = this.URL_BASE + '/api/v1/reservaMailError';
  urlSendMailUpdate = this.URL_BASE + '/api/v1/reservaMailUpdate';

  /*
    Todas la reservas y solo activa la del usuario
  */

  UrlGetReservasDateCat = this.URL_BASE + '/api/v1/reservaDateLine/';

  /*
    URLS para ver los recursos segun la categoria
  */

  UrlGetRecursoDateCat = this.URL_BASE + '/api/v1/recurso/list/';

  /*
    Todas las reservas que se tenga que validar por categoria
  */
  UrlReservasCateAprobacion = this.URL_BASE + '/api/v1/reservas/poraprobacion';
  UrlReservasCateAprobacionProx = this.URL_BASE + '/api/v1/reservas/poraprobacion/prox';

  //

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic ' + btoa(this.username + ':' + this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) {}

  // save Recurso
  postPrestamo(reservaSave: PrestamoSave) {
    return this.http.post<PrestamoSave>(
      this.UrlSavePrestamo,
      reservaSave,
      this.headers
    );
  }

  // save Recurso
  postReserva(reservaSave: ReservaSave) {
    return this.http.post<ReservaSave>(
      this.UrlSaveListV4,
      reservaSave,
      this.headers
    );
  }

  // save Recurso del la version 2 de reservas
  postReservaDateLine(reservaSave: ReservaSave) {
    return this.http.post<ReservaSave>(
      //this.UrlSaveListDateLine,
      this.UrlSaveListDateLineTF,
      reservaSave,
      this.headers
    );
  }
  // editar Recurso del la version 2 de reservas
  putReservaDateLine(reservaSave: ReservaSave) {
    return this.http.put<ReservaSave>(
      this.UrlSaveListDateLine + '/' + reservaSave.id,
      reservaSave,
      this.headers
    );
  }
  // obtener el las reservas de la version 2 de reservas
  getReservaDateLine(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlGetListDateLine + '/' + fechas,
      this.headers
    );
  }

  getRecursosDateLine(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(this.UrlGetRecursoDateLine, this.headers);
  }

  getRecursosDateLinev2(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(this.UrlGetRecursoDateLineV2, this.headers);
  }

  getReservasDateLinev2(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(this.UrlGetReservasDateLineV2, this.headers);
  }

  getReservasDateLineUserv2(user: string): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(
      this.UrlGetReservasDateLineUserV2 + user,
      this.headers
    );
  }

  getRecursosDateLineUserv2(user: string): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(this.UrlGetRecursoDateLineUserV2 + user, this.headers);
  }

  deleteEnableReservaDateLine(id: string) {
    return this.http.delete(this.UrlSaveListDateLine + '/' + id, this.headers);
  }

  /*
   todas la reservas que requieren validacion
  */
  // obtener el las reservas
  getReservaPorValidar(idCat: number): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    //console.log(this.UrlReservasCateAprobacion + '/' + fechas + '/' + idCat)
    return this.http.get<Reservas[]>(
      this.UrlReservasCateAprobacion + '/' + fechas + '/' + idCat ,
      this.headers
    );
  }
  getReservaPorValidarProx(idCat: number): Observable<any> {
    var currentValue = this.getFechaTomorrow();
    var fechas = currentValue;
    console.log("fechas");
    console.log("tomorrow: "+fechas);
    //console.log(this.UrlReservasCateAprobacionProx + '/' + fechas + '/' + idCat)
    return this.http.get<Reservas[]>(
      //this.UrlSaveList + '/' + nombre + '/' + fechas,
      this.UrlReservasCateAprobacionProx + '/' + fechas + '/' + idCat ,
      this.headers
    );
  }
  acpetarSolicitud(id: number, valorRecuperado: string) {
    //console.log(this.UrlSaveListDateLineTF + '/' + id );
    return this.http.delete(this.UrlSaveListDateLineTF + '/' + id+ '/' + valorRecuperado, this.headers);
  }

  acpetarSolicitudV2(id: number, valorRecuperado: EmailBody) {
    //console.log(this.UrlSaveListDateLineTF + '/' + id );
    return this.http.put<EmailBody>(this.UrlSaveListDateLineTFAprovate + '/' + id, valorRecuperado, this.headers);
  }



  /*
    todas las reservas segun la categoria seleccionada
  */
  getReservasDateLineUserCat(id: number, user: string): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(
      this.UrlGetReservasDateCat + id + '/' + user,
      this.headers
    );
  }

  // obtener el las reservas
  getReserva(nombre: String): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlSaveList + '/' + nombre + '/' + fechas,
      this.headers
    );
  }

  // obtener el las reservas de mañana
  getReservaTomorrow(nombre: String): Observable<any> {
    var currentValue = this.getFechaTomorrow();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      //this.UrlSaveList + '/' + nombre + '/' + fechas,
      this.UrlSaveListProx + '/' + nombre + '/' + fechas,
      this.headers
    );
  }

  getReservaTomorrowV2(nombre: String): Observable<any> {
    var currentValue = this.getFechaTomorrow();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlSaveListV2 + '/' + nombre + '/' + fechas,
      this.headers
    );
  }

  // obtener el las reservas
  getReservaAll(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlListAll + '/' + fechas,
      this.headers
    );
  }

  getReservaAllV2(): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get<reservasDto[]>(
      this.UrlListAllDto + '/' + fechas,
      this.headers
    );
  }

  // obtener todas el las reservas de mañana
  getReservaAllTomorrow(): Observable<any> {
    var currentValue = this.getFechaTomorrow();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlListAll + '/' + fechas,
      this.headers
    );
  } // obtener todas el las reservas de mañana
  getReservaAllTomorrowV2(): Observable<any> {
    var currentValue = this.getFechaTomorrow();
    var fechas = currentValue;
    return this.http.get<Reservas[]>(
      this.UrlListAll + '/' + fechas,
      this.headers
    );
  }

  // editar la reserva
  putReserva(categoria: ReservaSave) {
    return this.http.put<ReservaSave>(
      this.UrlSaveListV4 + '/' + categoria.id,
      categoria,
      this.headers
    );
  }

  //Eliminar registro
  deleteEnableRegistro(reserva: ReservaSave) {
    //return this.http.delete(this.UrlSaveListV4 + '/' + id, this.headers);
    return this.http.delete(this.UrlSaveListDateLine + '/' + reserva.id, this.headers);
  }
 //Eliminar registro desde una aprobacion
  deleteEnableRegistroAprocionPersona(reserva: ReservaSave, valorRecuperado: string) {
    //return this.http.delete(this.UrlSaveListV4 + '/' + id, this.headers);
    return this.http.delete(this.UrlSaveListDateLine + '/' + reserva.id+ '/' + valorRecuperado, this.headers);
  }



  sendMail(
    email: String,
    nombre: String,
    recurso: String,
    fechaInicio: String,
    fechaFin: String
  ) {
    nombre = nombre;
    var recurso2 = recurso;
    recurso = recurso2.replace(' ', '-');
    nombre = nombre.split(' ').join('-');

    fechaInicio = fechaInicio.replace(' ', '_');
    fechaFin = fechaFin.replace(' ', '_');
    return this.http.post(
      this.urlSendMail +
        '/' +
        email +
        '/' +
        nombre +
        '/' +
        recurso +
        '/' +
        fechaInicio +
        '/' +
        fechaFin,
      nombre,
      this.headers
    );
  }

  sendMailError(
    email: String,
    nombre: String,
    recurso: String,
    fechaInicio: String,
    fechaFin: String
  ) {
    nombre = nombre;
    var recurso2 = recurso;
    recurso = recurso2.replace(' ', '-');
    nombre = nombre.split(' ').join('-');

    fechaInicio = fechaInicio.replace(' ', '_');
    fechaFin = fechaFin.replace(' ', '_');
    return this.http.post(
      this.urlSendMailError +
        '/' +
        email +
        '/' +
        nombre +
        '/' +
        recurso +
        '/' +
        fechaInicio +
        '/' +
        fechaFin,
      nombre,
      this.headers
    );
  }

  sendMailUpdate(
    email: String,
    nombre: String,
    recurso: String,
    fechaInicio: String,
    fechaFin: String
  ) {
    nombre = nombre;
    var recurso2 = recurso;
    recurso = recurso2.replace(' ', '-');
    nombre = nombre.split(' ').join('-');

    fechaInicio = fechaInicio.replace(' ', '_');
    fechaFin = fechaFin.replace(' ', '_');
    return this.http.post(
      this.urlSendMailUpdate +
        '/' +
        email +
        '/' +
        nombre +
        '/' +
        recurso +
        '/' +
        fechaInicio +
        '/' +
        fechaFin,
      nombre,
      this.headers
    );
  }

  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaTomorrow() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }

  /*
    Todos los recursos segun sea la categoria que se llame.
  */
  getRecursosDateLineUserv3(id: number, user: string): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(
      this.UrlGetRecursoDateCat + id + '/' + user,
      this.headers
    );
  }
  getRecursosDateLinev3(id: number): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(this.UrlGetRecursoDateCat + id, this.headers);
  }
  getReservasDateLinev3(id: number): Observable<any> {
    var currentValue = this.getFechaActual();
    var fechas = currentValue;
    return this.http.get(
      this.UrlGetReservasDateCat + 'all/id/' + id,
      this.headers
    );
  }
}
