import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Categorias } from '../model/Categorias';
import { Pisos } from '../model/Pisos';
import { RecursoSave } from '../model/RecursoSave';
import { CategoriaService } from '../Service/categoria.service';
import { PisosService } from '../Service/pisos.service';
import { RecursoService } from '../Service/recurso.service';
import { ToastService } from '../Service/toast.service';
import { DataTableDirective } from 'angular-datatables';
import { ThemePalette } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../Service/service.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-recursos',
  templateUrl: './recursos.component.html',
  styleUrls: ['./recursos.component.css'],
})
export class RecursosComponent implements OnInit, AfterViewInit, OnDestroy {
  // data table
  profile!: ProfileType;
  @ViewChild(DataTableDirective, {static: false})
  dtElement!: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  paramTxt: string | undefined;
  color: ThemePalette = 'accent';
  checked = true;
  disabled = false;
  recursos: RecursoSave[] = [];
  recurso_save: RecursoSave = new RecursoSave();
  recurso_update: RecursoSave = new RecursoSave();

  pisos: Pisos[] = [];
  categorias: Categorias[] = [];
  numeroDuplicar: number | undefined;
  recurso_duplicar: any;
  user: string | undefined;
  roles: any;

  constructor(
    private modalService: NgbModal,
    private title: Title,
    usernameElement: ElementRef,
    private recursosService: RecursoService,
    private pisoService: PisosService,
    private categoriaService: CategoriaService,
    private toastService: ToastService,
    private http: HttpClient,
    private service: ServiceService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Recursos | Reservas');

    this.recursosList();
    this.pisosList();
    this.getRolList();
    this.categoriasList();
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      pageLength: 25,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
      },
    };
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser(''+this.user+'').subscribe((data) => {
        this.roles = data.rol;
      });
    });
  }

  // listar recursos
  recursosList() {
    this.recursosService.getRecursoNew().subscribe((data) => {
      this.recursos = data;
      this.dtTrigger.next();
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 25,
        processing: true,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };
    });

  }

  // agregar registro
  saveRegister(recursos: RecursoSave) {
    this.recursosService.postRecursos(recursos).subscribe((data) => {
      this.showSuccess();
      location.reload();
    });
  }

  // actualizar registros
  updateShow(recursosU: RecursoSave): void {
    localStorage.setItem('id', recursosU.id.toString());
    console.log();
    this.recurso_update.nombreRecurso = recursosU.nombreRecurso;
    this.recurso_update.descripcion = recursosU.descripcion;
    this.recurso_update.ubicacion = recursosU.ubicacion;
    this.recurso_update.capacidad = recursosU.capacidad;
    this.recurso_update.idPiso = recursosU.idPiso;
    this.recurso_update.idCategoria = recursosU.idCategoria;
    this.recurso_update.id = recursosU.id;
  }
  actualizar(recursoA: RecursoSave) {
    this.recursosService.UpadteRegistro(recursoA).subscribe((data) => {
      this.recurso_update = data;
      this.showSuccess();
      location.reload();
    });
  }

  //eliminaión logica del registro
  deleteEnable(recursosDelete: RecursoSave) {
    this.recursosService
      .deleteEnableRegistro(recursosDelete)
      .subscribe((data) => {
        this.recursos = this.recursos.filter((r) => r !== recursosDelete);
        this.showDanger();
      });
  }

  // duplicar registro
  duplicarRegistro(recursosDuplicar: RecursoSave) {
    console.log(this.numeroDuplicar);
    const idDuplicar = this.numeroDuplicar;
    if(idDuplicar != null){
      this.recursosService
      .duplicarRegistro(recursosDuplicar, idDuplicar)
      .subscribe((data) => {
        this.showDuplate();
        if (data) {
          location.reload();
        }
      });
    } else {
      this.toastService.show('El campo copia está vacio!', {
        classname: 'bg-warning',
        delay: 7000,
      });
    }

  }
  duplicarList(recursosDuplicar: RecursoSave): void {
    localStorage.setItem('id', recursosDuplicar.id.toString());
    console.log(recursosDuplicar.id);
    //this.recurso_duplicar.nombreRecurso = recursosDuplicar.nombreRecurso;
  }


  // listar pisos
  pisosList() {
    this.pisoService.getPisos().subscribe((data) => {
      this.pisos = data;
    });
  }

  // listar categorias
  categoriasList() {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.categorias = data;
    });
  }

  // control de la ventana emergente
  openLg(content: any) {
    this.modalService.open(content, { scrollable: false });
  }
  open(update: any) {
    this.modalService.open(update, { scrollable: false });
  }

  // alertas
  showSuccess() {
    this.toastService.show('💾 Registro añadido con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }
  showDanger() {
    this.toastService.show('❌ Registro Eliminado con exito...!!!', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }
  showUpdate() {
    this.toastService.show('📝 Registro actualizado con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }
  showDuplate() {
    this.toastService.show('💾 Registro duplicado con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

  }

}
