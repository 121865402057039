<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2" *ngIf=" roles == 'ADMINISTRADOR'">
  <mat-card class="colorCard text-center">
    <h1>Listado de Subcategoría | Reservas UTPL</h1>
  </mat-card>
  <br>
  <div class="d-flex justify-content-end">
    <button class="btn colorUTPLModal m-1" (click)="openLg(content)">Añadir Subcategoría</button>
    <button class="btn colorUTPLBotton m-1" placement="top" ngbTooltip="Descargar"><i
        class="bi bi-file-earmark-arrow-down-fill"></i></button>
    <button class="btn colorUTPLBotton m-1" placement="left" ngbTooltip="Información"><i
        class="bi bi-info-circle"></i></button>
  </div>
  <hr>
  <br>

  <div class="p-2 table-responsive justify-content-center">
    <table datatable [dtOptions]="dtOptions" class="row-border hover example table table-striped table-sm"
      *ngIf="pisos.length">
      <thead>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Nombre de la Subcategoría</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Roles de acceso</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </thead>
      <tbody>
        <!--<tr  *ngIf="registro.opcionTitulacion==='Tesis'" > -->

        <tr *ngFor="let piso of pisos" class="vrt-align">
          <th class="vrt-align-center">{{piso.id}}</th>
          <td class="vrt-align-center">{{piso.nombrePiso}}</td>
          <td class="vrt-align-center">{{piso.ubicacion}} </td>
          <td class="vrt-align-center">{{piso.descripcion}} </td>
          <td class="vrt-align-center">{{piso.rolAccess}} </td>
          <td class="vrt-align-center">
            <!-- <button (click)="update(registro)" type="button" class="btn btn-warning m-2">Editar</button>-->
            <button class="btn btn-warning m-2" (click)="updateShow(piso)" (click)="open(update)" ngbTooltip="Editar"><i
                class="bi bi-pencil-square"></i></button>
            <button (click)="deleteEnable(piso)" type="button" class="btn btn-danger " ngbTooltip="Deshabilitar"><i
                class="bi bi-patch-exclamation-fill"></i></button>
          </td>
        </tr>

      </tbody>

      <tfoot>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Nombre de la Subcategoría</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Roles de acceso</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </tfoot>
    </table>
  </div>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<section class="m-2"
  *ngIf="roles == 'ESTUDIANTE' || roles == 'DOCENTE' || roles == 'BIBLIOTECARIO' || roles == 'ADMINISTRATIVO' || roles == 'null'  || roles == 'ADMIN-BBC' || roles == 'AUTORIDADES'">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h3 class="modal-title" id="modal-basic-title">Formulario registro de la Subcategoría</h3>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombrePiso" class="form-label">Nombre de la Subcategoría
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombrePiso" name="nombrePiso" #nombrePiso
            [(ngModel)]="pisosSave.nombrePiso">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="pisosSave.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #descripcion
            [(ngModel)]="pisosSave.ubicacion">
        </div>
        <div class="col-md-12 mb-2">
          <label for="rolAccess" class="form-label"> Roles de acceso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rolAccess" name="rolAccess" #rolAccess
            [(ngModel)]="pisosSave.rolAccess">
        </div>


        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Categoría
            <i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="pisosSave.idCategoria">
            <option selected>Seleccione una categoria</option>
            <option *ngFor="let bsc of categorias" value="{{bsc.id}}">
              {{bsc.nombreCategoria}}</option>
          </select>
        </div>





        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" [disabled]="!pisosSave.ubicacion" (click)="saveRegister(pisosSave)"
          (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #update let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h2 class="modal-title" id="modal-basic-title">Actualizar registro de la Subcategoría</h2>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <div class="col-md-12 mb-2">
        <label for="idCategoria" class="form-label"> Categoría principal
          <i style="color: red;"><strong>*</strong></i></label>
        <select class="form-select" name="idCategoria" id="idCategoria" [(ngModel)]="pisosUpdate.idCategoria">
          <option selected>Seleccione una categoria</option>
          <option *ngFor="let bsc of categorias" value="{{bsc.id}}">
            {{bsc.nombreCategoria}}</option>
        </select>
      </div>

      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombrePiso" class="form-label">Nombre de la Subcategoría
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombrePiso" name="nombrePiso" #nombrePiso
            [(ngModel)]="pisosUpdate.nombrePiso">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="pisosUpdate.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #descripcion
            [(ngModel)]="pisosUpdate.ubicacion">
        </div>

        <div class="col-md-12 mb-2">
          <label for="rolAccess" class="form-label"> Roles de acceso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rolAccess" name="rolAccess" #rolAccess
            [(ngModel)]="pisosUpdate.rolAccess">
        </div>


        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" (click)="actualizar(pisosUpdate)" (click)="d('Cross click')"
          class="btn btn-warning">Actualizar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>
