import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { Menu } from '../model/Menu';
import { ServiceService } from '../Service/service.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  profile!: ProfileType;
  loginDisplay = true;
  menu: Menu[] = [];
  menus: Menu [] = [];
  user : string | undefined;
  roles: any;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
    private http: HttpClient,private service:ServiceService
  ) { }

  ngOnInit(): void {
    this.getProfile();
    this.rolMenu();
    this.getRolList();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
      });
  }

  rolMenu(){
    this.http.get(GRAPH_ENDPOINT)
    .subscribe(profile => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.getMenu(""+this.user+"")
      .subscribe(data => {
        this.menus = data;
      });
    });
  }

  getRolList() {

    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser(''+this.user+'').subscribe((data) => {
        this.roles = data.rol;
      });
    });
  }


  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }



}
