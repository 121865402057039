import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Categorias } from 'src/app/model/Categorias';
import { Pisos } from 'src/app/model/Pisos';
import { PrestamoSave } from 'src/app/model/PrestamoSave';
import { Recurso } from 'src/app/model/Recurso';
import { Reservas } from 'src/app/model/Reservas';
import { ReservaSave } from 'src/app/model/ReservaSave';
import { CategoriaService } from 'src/app/Service/categoria.service';
import { ControllersService } from 'src/app/Service/controllers.service';
import { PisosService } from 'src/app/Service/pisos.service';
import { RecursoService } from 'src/app/Service/recurso.service';
import { ReservasService } from 'src/app/Service/reservas.service';
import { ServiceService } from 'src/app/Service/service.service';
import { ToastService } from 'src/app/Service/toast.service';
import Swal from 'sweetalert2';
import { setOptions , localeEs} from '@mobiscroll/angular';
import { EmailBody } from 'src/app/model/EmailBody';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}


setOptions({
  locale: localeEs,
  theme: 'ios',
  themeVariant: 'light',
});


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-administracion-reservas',
  templateUrl: './administracion-reservas.component.html',
  styleUrls: ['./administracion-reservas.component.css'],
})
export class AdministracionReservasComponent implements AfterViewInit, OnDestroy, OnInit  {
  // data table
  //dtOptions: DataTables.Settings = {};
  dtOptions: DataTables.Settings[] = [];
  @ViewChild(DataTableDirective, {static: false})
  dtElement!: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  //dtOptions: Promise<DataTables.Settings>;

  //Timepicker
  time: NgbTimeStruct = { hour: 8, minute: 0, second: 0 };
  timesStr: NgbTimeStruct = { hour: 8, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 15;
  secondStep = 30;
  meridian = true;
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  // data table
  profile!: ProfileType;
  reservas_save: ReservaSave = new ReservaSave();
  prestamo_save: PrestamoSave = new PrestamoSave();
  reservas_editar: ReservaSave = new ReservaSave();
  emailBodySave: EmailBody = new EmailBody();
  user: string | undefined;
  reservasListShow: Reservas[] = [];

  recurso: Recurso = new Recurso();
  roles: any;
  reserva: ReservaSave[] = [];
  recursoList: Recurso[] = [];
  pisosList: Pisos[] = [];
  pisosListado: Pisos[] = [];
  cateList: Categorias[] = [];
  catePrestamosList: Categorias[] = [];
  categorias: Categorias[] = [];
  categoriasV2: any;
  fechaList: any;
  fechaListV2: any;

  // para las fechas
  fechaId: any;
  fechaActual: any;
  horaInicio: string | undefined;
  horaFin: string | undefined;
  minutosUso!: number;
  minutosEspera!: number;
  fechaInicioChange!: any;
  model!: NgbDateStruct;
  @ViewChild('fechaRsrv') fechaRsrv!: ElementRef;
  @ViewChild('fechaRsrvPresmo') fechaRsrvPresmo!: ElementRef;
  @ViewChild('loadingScreens') loadingScreens!: ElementRef;

  // información adicinal
  infoNombreRecurso: any;
  infoDescripcion: any;
  infoUbicacion: any;
  capacidadUso: any;
  imgRuta: any;
  datepickers!: any;
  datepickersPrestamo!: any;
  date: any;
  reservas: any;
  reservasTomorrow: any; // tomorrowany;

  //show divs actions
  public edited = false;
  public edited2 = false;
  public edited3 = false;
  public edited3_1 = false;
  public edited4 = false;
  public editedInfo = false;
  public showMore = true;
  public showLess = false;
  canCheckbox: boolean = false;
  public showDatePrestamo = false;
  public selectDate_prestamo = false;
  //action de cambio combo box
  verSeleccion: string | undefined;
  favoriteSeason: string | undefined;
  document: any;
  horaDiv: any;
  CategoriaSelectList: any;
  CategoriaSelect: any;
  CategoriaName: any;
  textEmailRechazar: any;

  // card bootstrap
  active = 1;

  // temporizador
  times!: Time;
  timerId!: number;
  // para ocultar piso
  @ViewChild('piso41') piso41!: ElementRef;

  @ViewChild('contentModal', { static: true }) contentModal!: ElementRef;
  @ViewChild('contentModalEdit', { static: true })
  contentModalEdit!: ElementRef;
  //date _picker
  constructor(
    private reservaService: ReservasService,
    private title: Title,
    private http: HttpClient,
    private modalService: NgbModal,
    private recursoService: RecursoService,
    private pisosService: PisosService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private service: ServiceService,
    private controlService: ControllersService,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.title.setTitle('Aprobación de reservas | Reservas');
    var restult = this.getHoraActual();
    this.time = { hour: restult, minute: 0, second: 0 };
    this.getDateTomorrow();
    //this.openLgHome();
    //this.openInfoAlert();
    //this.getSolicitudesList();
    //this.getRolAndList();
    //this.getCategorias();
    this.getRolList();

    setTimeout('document.location.reload()', 120000);
  }

  // listar categorias

  selectCategory() {
    this.showEventInfoShowCategorias();

    //console.log('Categoria: ' + this.CategoriaSelect);
    this.CategoriaSelectList = this.CategoriaSelect.split('-');
    //console.log('Datos: ' + this.CategoriaSelectList.length);
    if (this.CategoriaSelectList.length != 1) {
      var userStr = '';
      this.CategoriaName = this.CategoriaSelectList[1];
      this.getSolicitudesListId(this.CategoriaSelectList[0]);
      this.getSolicitudesProxId(this.CategoriaSelectList[0]);
    } else {
      setTimeout('Swal.close', 1200);
    }
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.roles = data.rol;
      });
      this.categoriaService
        .getCategoriaListWithRol('' + this.user + '')
        .subscribe((data) => {
          this.categorias = data;

          for (let i = 0; i < this.categorias.length; i++) {
            if (i == 0) {
              //console.log(this.categorias[i].nombreCategoria);
              this.CategoriaSelect =
                this.categorias[i].id +
                '-_' +
                this.categorias[i].nombreCategoria;
              this.getSolicitudesListId(this.categorias[i].id);
              this.getSolicitudesProxId(this.categorias[i].id);

              this.CategoriaName = this.categorias[i].nombreCategoria;
            } else {
              console.log('Otros');
            }
          }
        });
    });
  }

  getSolicitudesListId(id: number) {
    this.reservaService.getReservaPorValidar(id).subscribe((data) => {
      this.horaDiv = this.getFechaActualAndHora();
      this.reservas = data;
      console.log(this.reservas);

      this.dtOptions[0] = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };


      this.dtTrigger.next();
      Swal.close();
    });
  }

  getSolicitudesProxId(id: number) {
    this.reservaService.getReservaPorValidarProx(id).subscribe((data) => {
      this.horaDiv = this.getFechaActualAndHora();
      this.reservasTomorrow = data;
      console.log("reservasTomorrow");
      console.log(this.reservasTomorrow);

      this.dtOptions[1] = {
        pagingType: 'full_numbers',
        pageLength: 10,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };


      this.dtTrigger.next();
      Swal.close();
    });
  }

  openLgHome() {
    this.modalService.open(this.contentModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }


  //eliminaión logica del registro
  deleteEnable(recursosDelete: ReservaSave) {
    this.showEventDeleteReserva();
    //this.loadingScreens.nativeElement.style.display = 'block';
    this.reservaService
      .deleteEnableRegistro(recursosDelete)
      .subscribe((data) => {
        /* this.enviarMailError(
          '' + recursosDelete.materialReservado + '',
          '' + recursosDelete.fechaReservaInicio + '',
          '' + recursosDelete.fechaReservaFin + ''
        ); */
        this.showSendMailError();
        Swal.close();
        //this.loadingScreens.nativeElement.style.display = 'none';
        location.reload();
      });
  }

  //eliminaión logica de la solicitud
  RechazarSolicitud(recursosDelete: ReservaSave) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        icon: 'warning',
        title: '¿Está seguro que desea denegar la solicitud?',
        text: '¡No podrás revertir esto!',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Denegar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: (valor) => {
          if (!valor) {
            Swal.showValidationMessage(`Por favor ingrese una descripción de la denegación de la solicitud`)
          }
          return valor;
        },
        allowOutsideClick: () => !Swal.isLoading()

      })
      .then((result) => {
        if (result.isConfirmed) {
          var valorRecuperado = result.value;
          this.reservaService.deleteEnableRegistroAprocionPersona(recursosDelete, ""+valorRecuperado+"").subscribe(
            (data) => {
              Swal.close();

              location.reload();
            },
            (err) => {}
          );

          Swal.fire({
            icon: 'success',
            title: '',
            text: "La solicitud ha sido denegada con éxito!",
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            timerProgressBar: true,
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false
          });





        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Cancelado',
            text: "La solicitud esta intacta.",
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            timerProgressBar: true,
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false
          });

        }
      });
  }

  Aceptar(recursosAcepted: ReservaSave) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        icon: 'warning',
        title: '¿Está seguro que desea aceptar la solicitud?',
        text: '¡No podrás revertir esto!',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: (valor) => {
          if (!valor) {
            Swal.showValidationMessage(`Por favor ingrese una descripción de la aceptación de la solicitud`)
          }
          return valor;
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then((result) => {
        if (result.isConfirmed) {
          var valorRecuperado = result.value;

          var valorRecuperado2 = valorRecuperado.replaceAll("\"","'");

          // Expresión regular para buscar URLs
          const regex = /(https?:\/\/[^\s]+)/g;

          // Busca las URLs en el texto y reemplaza con etiquetas <a>
          const textoConLinks = valorRecuperado2.replace(regex, '<a href="$&">$&</a>');

          // Imprime el texto con las URLs reemplazadas por etiquetas <a>
          console.log(textoConLinks);

          this.emailBodySave.text = textoConLinks;

          this.reservaService.acpetarSolicitudV2(recursosAcepted.id, this.emailBodySave).subscribe(
            (data) => {
              location.reload();
            },
            (err) => {}
          );
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: 'Aceptada',
            text: "La solicitud ha sido aceptada con éxito!",
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            timerProgressBar: true,
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false
          });
        } else  if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Cancelado',
            text: "La solicitud no es aceptada hasta ahora.",
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            timerProgressBar: true,
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false
          });
        }
      });
  }

  // agregar registro
  guardar(reserva: ReservaSave) {
    this.showEventInfoOk();
    //this.loadingScreen.nativeElement.style.display = 'block';
    this.reservaService.postReserva(reserva).subscribe(
      (data) => {
        this.showSuccess();
        //this.enviarMail();
        this.showSendMail();
        Swal.close();
        //this.loadingScreen.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoError();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // agrefar registro de cita de prestamo o devolución de libro
  guardarPrestamo(reserva: PrestamoSave) {
    this.showEventInfoOk();
    //this.loadingScreen.nativeElement.style.display = 'block';
    this.reservaService.postPrestamo(reserva).subscribe(
      (data) => {
        this.showSuccess();
        //this.enviarMail();
        this.showSendMail();
        Swal.close();
        //this.loadingScreen.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoErrorPrestamo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // update reserva
  updateShow(reserva: ReservaSave) {
    //this.loadingScreens.nativeElement.style.display = 'block';
    this.showEventUpdate();
    this.reservaService.putReserva(reserva).subscribe(
      (data) => {
        //this.enviarMailUpdate();
        this.showSendMailUpdate();
        Swal.close();
        //this.loadingScreens.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoError();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // cargar el resto de datos
  desactivarInpunt() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.idRecurso;
    if (dataSelectors != 'Seleccione') {
      this.edited3 = true;
      this.recursoService
        .getRecursoId('' + this.reservas_save.idRecurso + '')
        .subscribe((data) => {
          this.reservas_save.materialReservado = data.nombreRecurso;
          this.minutosUso = data.categoria.tiempoUso;
          this.capacidadUso = data.capacidad;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.categoria.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }

  showEventInfoShowCategorias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }

  //eventos para mostrar div de información del recurso
  showInfo() {
    this.showMore = false;
    this.editedInfo = true;
    this.showLess = true;
  }
  showInfoLess() {
    this.showMore = true;
    this.editedInfo = false;
    this.showLess = false;
  }
  showFechaPrestamo() {
    this.showDatePrestamo = true;
  }

  // cargar datos del usuario al formulario de reserva
  cargarUser() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.reservas_save.namesReservador =
        this.profile.givenName + ' ' + this.profile.surname;
      this.reservas_save.emailReservador = this.profile.userPrincipalName;
    });
  }

  cargarUserPD() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.prestamo_save.namesReservador =
        this.profile.givenName + ' ' + this.profile.surname;
      this.prestamo_save.emailReservador = this.profile.userPrincipalName;
    });
  }


  //fechas
  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaActualAndHora() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var hora = hoy.getHours();
    var min = hoy.getMinutes();
    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var mint = '';
    var horas = '';

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    if (hora < 10) {
      horas = '0' + hora;
    } else {
      horas = '' + hora;
    }
    if (min < 10) {
      mint = '0' + min;
    } else {
      mint = '' + min;
    }

    fechaActual =
      hoy.getFullYear() +
      '-' +
      months +
      '-' +
      diaSL +
      ' ' +
      horas +
      ':' +
      mint +
      ':' +
      hoy.getSeconds();
    return fechaActual;
  }

  getFechaManana() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();
    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }
  getDay(dateSelect: Date) {
    const fechaComoCadena = dateSelect; // día lunes
    const dias = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia;
    console.log('Nombre de día de la semana: ', nombreDia);
  }
  getHoraActual() {
    var hoy = new Date();
    var hora = hoy.getHours();
    var fechaActual = null;
    fechaActual = hora;
    return fechaActual;
  }

  // control de la ventana emergente
  openLg2(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLg(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  //alertas
  showSuccess() {
    this.toastService.show('💾 Reserva añadido con éxito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSuccesUpdate() {
    this.toastService.show('💾 Reserva modificada con éxito...!!!', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMail() {
    this.toastService.show('📤  Reserva creada correctamente', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSendMailUpdate() {
    this.toastService.show('📤  Reserva modificada correctamente', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMailError() {
    this.toastService.show('📢 Reserva eliminada correctamente', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  showRecursos() {
    this.toastService.show('📢 Recursos de biblioteca cargados correctamente', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  showNoRecursos() {
    this.toastService.show(
      '📢  Todos los recursos han sido usados para la hora seleccionada.',
      {
        classname: 'bg-warning',
        delay: 4000,
      }
    );
  }
  showAlertTiempoUso() {
    this.toastService.show('📢 Recuerda: Las reserva se realizan para el día', {
      classname: 'bg-info',
      delay: 8000,
    });
  }
  showError() {
    this.toastService.show(
      '⚠ Error al cargar el recurso. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoRecursos() {
    this.toastService.show(
      '⚠ Error: no existe ningún recurso asociado a la búsqueda que requiere. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorDate() {
    this.toastService.show(
      '⚠ Error: La fecha seleccionada no puede ser menor a la actual',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorSave() {
    this.toastService.show(
      '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoAccess() {
    this.controlService.getControllers().subscribe((data) => {
      this.toastService.show(
        '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }
  showErrorNoDia() {
    var diasNo = '';
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        diasNo = data[0].diasAtencion;
      }
      this.toastService.show(
        '⚠ Error: No se puede reservar para le fecha seleccionado | ' +
          diasNo +
          '.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }

  showErrorNoHora() {
    this.toastService.show(
      '⚠ Error: Reservas finalizadas por hoy. Si desea realizar una reserva para mañana.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorEx() {
    this.toastService.show(
      '⚠ Error: Solo puede realizar una reserva por día.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorAforo() {
    this.toastService.show(
      '⚠ Error: Aforo completo para el horario que seleccionó.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorVacio() {
    this.toastService.show('⚠ Error: Esta vacío la seleccionado', {
      classname: 'bg-warning',
      delay: 4000,
    });
  }

  getDateTomorrow() {
    var hoy = new Date();
    var tomorrow = new Date(hoy.getTime() + 24 * 60 * 60 * 1000);
    var dds = tomorrow.getDate();
    var mms = tomorrow.getMonth() + 1; //January is 0!
    var yyyy = tomorrow.getFullYear();
    var ddst;
    var mmst;
    var tomorrowst;
    if (dds < 10) {
      ddst = '0' + dds;
    } else {
      ddst = dds;
    }
    if (mms < 10) {
      mmst = '0' + mms;
    } else {
      mmst = mms;
    }
    tomorrowst = yyyy + '-' + mmst + '-' + ddst;
    console.log(tomorrowst);
  }

  //eventos para mostrar el estado de la acción de información del recurso
  showEventInfoOk() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espero un momento',
      text: 'Estamos validando su reserva y una vez terminada le llegará un correo de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoError() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de reservas que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorPrestamo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de prestamos que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorSave() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorAforo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Aforo completo para el horario que seleccionó.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorNoAccess() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventUpdate() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title: 'Espero un momento',
      text: '⚙️ Estamos modificando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventDeleteReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Reserva eliminada',
      text: 'Estamos eliminando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  openInfoAlert2() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Se comunica que el día de hoy viernes el horario de atención será de 08:00 a 11:00 am. El día lunes retomamos en nuestro horario habitual.',
      footer: 'Agradecemos su comprensión.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 8000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }

  openInfoAlert() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Del 19 de agosto al 09 de septiembre del 2022, la Biblioteca UTPL por remodelación, atenderá a través del servicio de Teleasistencia.',
      footer:
        '<center> Horario de atención: <br>08:00 a 13:00  <br>15:00 a 18:00 <br> Agradecemos su comprensión. </center>',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 18000,
      timerProgressBar: false,
      showConfirmButton: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }

  showEliminarSolicitud() {
    let timerInterval: any = undefined!;

    Swal.fire({
      icon: 'warning',
      title: 'Reniciando contador',
      text: 'Espere por favor, esto puede tardar unos minutos.',

      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
function calculateRange(start: any, end: any, minutes: any) {
  var range = [];
  for (
    var hour = moment(start);
    hour.isBefore(end);
    hour.add(minutes, 'minutes')
  ) {
    range.push(moment(hour));
  }
  range.push(moment(end));
  return range;
}
function removeItemFromArr(foo: any, arg1: string) {
  throw new Error('Function not implemented.');
}

function contador(fecha: string, min: number) {
  var actividad = fecha;
  //var actividad = Date.UTC(2019, 8, 13, 6, 45, 0);// <-- idealmente
  var fechaI2 = new Date(actividad);
  let minutoSumar = min;

  console.log(fechaI2);

  if (minutoSumar != 0) {
    fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);

    console.log(fechaI2);
  }
  return fechaI2;
}
