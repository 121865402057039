import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Roles } from '../model/Roles';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  UrlCategorias = this.URL_BASE+'/api/v1/rolescontrols';

   // API PARA guardar, editar y eliminar
   UrlSaveDelete = this.URL_BASE+'/api/v1/rolescontrols';

   //URL TO IMG SAVE
   UrlIMG = this.URL_BASE+'/api/v1/imgbiblio';

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) { }

  // obtener el las categorias
  getRoles(){
    return this.http.get<Roles[]>(this.UrlCategorias, this.headers);
  }

   // save piso
   /* postCategorias(CategoriasSave:Categorias) {
     console.log(CategoriasSave.rutaImg);
    return this.http.post<Categorias>(this.UrlSaveDelete, CategoriasSave);
  } */

  UpadteRoles(roles:Roles){
    return this.http.put<Roles>(this.UrlSaveDelete+"/"+roles.idRol, roles, this.headers);
  }

  deleteEnableRegistro(roles:Roles){
    return this.http.delete(this.UrlSaveDelete+"/"+roles.idRol, this.headers);
  }

}
