

import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { MbscModule } from '@mobiscroll/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

// angular material
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import { CountdownModule } from 'ngx-countdown';
import {MatDatepickerModule} from '@angular/material/datepicker';
//login Azure
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { LocalizedString } from '@angular/compiler/src/output/output_ast';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastComponent } from './toast/toast.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// qr code
// the scanner!
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {WebcamModule} from 'ngx-webcam';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ReservaltsComponent } from './reservalts/reservalts.component';
import { HomeComponent } from './home/home.component';
import { PisosComponent } from './pisos/pisos.component';
import { DataTablesModule } from 'angular-datatables';
import { CategoriasComponent } from './categorias/categorias.component';
import { RecursosComponent } from './recursos/recursos.component';
import { MatRippleModule } from '@angular/material/core';
import { RolesComponent } from './roles/roles.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ControllersComponent } from './controllers/controllers.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ReservaV2Component } from './V2/reserva-v2/reserva-v2.component';
import { HomeV2Component } from './V2/home-v2/home-v2.component';
import { Navbarv2Component } from './V2/navbarv2/navbarv2.component';
import { ReservaBibliotecarioComponent } from './V2/reserva-bibliotecario/reserva-bibliotecario.component';
import { OutlookComponent } from './V2/outlook/outlook.component';
import { AdministracionReservasComponent } from './V2/administracion-reservas/administracion-reservas.component';
import { ReservasAdministractionComponent } from './V2/reservas-administraction/reservas-administraction.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {

}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // azure
      clientId: 'ecd1c982-2618-4185-ab2b-ab671772ace0',
      // adfs
      //clientId: '3276fb56-c458-4204-82a3-bfae10e514f8',
      authority: 'https://login.microsoftonline.com/6eeb49aa-436d-43e6-becd-bbdf79e5077d',
      //redirectUri: 'http://localhost:4200/inicio'
      //redirectUri: 'https://diepinto30'
      //redirectUri: 'https://bibliotecautpl.utpl.edu.ec/reservas/inicio',
      //redirectUri: 'https://reservas.utpl.edu.ec'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    ReservaltsComponent,
    HomeComponent,
    PisosComponent,
    CategoriasComponent,
    RecursosComponent,
    RolesComponent,
    ControllersComponent,
    NavbarComponent,
    ReservaV2Component,
    HomeV2Component,
    Navbarv2Component,
    ReservaBibliotecarioComponent,
    OutlookComponent,
    AdministracionReservasComponent,
    ReservasAdministractionComponent
  ],
  imports: [
    MbscModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    MsalModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    DataTablesModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatRippleModule,
    MatSelectModule,
    CountdownModule,
    MatDatepickerModule,
    ZXingScannerModule,
    WebcamModule,
    BsDatepickerModule.forRoot(),
    SweetAlert2Module.forRoot(),
    ReactiveFormsModule

  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    Title
    //{ provide: LocationStrategy, useClass: PathLocationStrategy }
    //{ provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
