import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Categorias } from '../model/Categorias';
import { Pisos } from '../model/Pisos';
import { CategoriaService } from '../Service/categoria.service';
import { PisosService } from '../Service/pisos.service';
import { ServiceService } from '../Service/service.service';
import { ToastService } from '../Service/toast.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-pisos',
  templateUrl: './pisos.component.html',
  styleUrls: ['./pisos.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class PisosComponent implements OnInit, AfterViewInit, OnDestroy {
  // data table
  profile!: ProfileType;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement!: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();

  pisos: Pisos[] = [];
  pisosSave: Pisos = new Pisos();
  pisosUpdate: Pisos = new Pisos();
  categorias: Categorias[] = [];
  user: string | undefined;
  roles: any;


  constructor(
    private modalService: NgbModal,
    private title: Title,
    usernameElement: ElementRef,
    private pisosService: PisosService,
    private toastService: ToastService,
    private http: HttpClient,
    private service: ServiceService,
    private categoriaService: CategoriaService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Pisos | Reservas');
    this.pisosList();
    this.getRolList();
    //this.categoriasList();

  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser(''+this.user+'').subscribe((data) => {
        this.roles = data.rol;
      });
    });
  }

  // listar pisos
  pisosList() {
    this.pisosService.getPisos().subscribe((data) => {
      this.pisos = data;

      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };
      this.dtTrigger.next();
    });

  }

  // agregar registro
  saveRegister(pisos: Pisos) {
    this.pisosService.postPisos(pisos).subscribe((data) => {
      this.showSuccess();
      location.reload();
    });
  }

  // actualizar registros
  updateShow(pisosU:Pisos):void{
    localStorage.setItem("id",pisosU.id.toString());
    console.log()
    this.pisosUpdate.nombrePiso = pisosU.nombrePiso;
    this.pisosUpdate.descripcion = pisosU.descripcion;
    this.pisosUpdate.ubicacion = pisosU.ubicacion;
    this.pisosUpdate.id = pisosU.id;
    this.pisosUpdate.idCategoria = pisosU.idCategoria;
    this.pisosUpdate.rolAccess = pisosU.rolAccess;
  }
  actualizar(pisosA:Pisos){
    this.pisosService.UpadteRegistro(pisosA)
    .subscribe(data=>{
      this.pisosUpdate=data;
      this.showSuccess();
      location.reload();
    });
  }

  //eliminaión logica del registro
  deleteEnable(pisoDelete:Pisos){
    this.pisosService.deleteEnableRegistro(pisoDelete)
    .subscribe(data => {
      this.pisos = this.pisos.filter(r =>r!==pisoDelete);
      this.showDanger();
    }, (err) => {
      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
      this.showNotDelete();
    })
  }

  categoriasList() {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.categorias = data;
    });
  }



  // control de la ventana emergente
  openLg(content: any) {
    this.modalService.open(content, { scrollable: false });
  }
  open(update: any) {
    this.modalService.open(update, { scrollable: false });
  }

  // alertas
  showSuccess() {
    this.toastService.show('💾 Registro añadido con exito...!!!', {
      classname: 'bg-info',
      delay: 5000,
    });
  }
  showDanger() {
    this.toastService.show('❌ Registro Eliminado con exito...!!!', {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
  showNotDelete() {
    this.toastService.show('⚠ No se puede eliminar el registro, exite un relación asociada a este registro', {
      classname: 'bg-danger text-light',
      delay: 9000,
    });
  }
  showUpdate() {
    this.toastService.show('📝 Registro actualizado con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

  }
}
