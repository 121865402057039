<nav class="navbar colorNav">
  <div class="container-fluid">
    <div class="navbar-brand example-icon" aria-label="Example icon-button with menu icon">
      <a class="btn text-light" data-bs-toggle="offcanvas" href="#offMenu" role="button"
        aria-controls="offcanvasExample">
        <i class="bi bi-menu-button-wide-fill"></i> Menú
      </a>

    </div><span class="example-spacer"></span>
    <a class="navbar-brand d-flex">
      <img src="https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/imgCantegoria/utpl-biblioteca-v3.png"
        class="img-fluid imgBiblio" width="210" height="auto">
      </a>


    <!-- <span>Reservas Biblioteca</span> -->
  </div>
</nav>
<div class="offcanvas offcanvas-start" tabindex="-1" id="offMenu" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <div>
      <strong>Menú</strong>
    </div>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>

      Bienvenido 😀, <strong>{{profile?.givenName}}</strong> <br>
      <hr>
      Seleccione el campo a redireccionar
      <!-- Borrar el rol cuando ya este finalizado el proyecto y asi poder ver el menú real -->
      <ul style="list-style:none" *ngIf="roles == 'DOCENTE' || roles == 'BIBLIOTECARIO' || roles == 'ESTUDIANTE' || roles == 'ADMINISTRADOR'  || roles === 'ADMINISTRATIVO'  || roles == 'ADMIN-BBC' || roles == 'AUTORIDADES' || roles == 'EVENTOS' || roles == 'EVENTOS_ADMIN'">

        <li><a class="dropdown-item" href="inicio"><i class="bi bi-house-fill"></i> Inicio</a></li>

        <li *ngFor="let m of menus">
          <a class="dropdown-item" href="{{m.url}}"><i class="bi {{m.nombre}}"></i> {{m.icono}}</a>
        </li>

        <li *ngIf="menus?.length == 0"><a class="dropdown-item" href="inicio"><i class="bi bi-exclamation-diamond"></i>
            No tiene Permisos</a></li>

        <li><a class="dropdown-item navbar-light" (click)="logout()"> <i class="bi bi-box-arrow-right"></i> Salir</a>
        </li>
        <br>
        <li><a class="dropdown-item navbar-light" href="#" download data-bs-toggle="tooltip" data-bs-placement="bottom"
            title="clic para visualizar el manual de usuario" target="_blank"> <i
              class="bi bi-question-octagon-fill"></i> Ayuda</a></li>
      </ul>

      <ul style="list-style:none" *ngIf=" roles == 'null'  || roles == ' '">

        <li><a class="dropdown-item" href="inicio"><i class="bi bi-house-fill"></i> Inicio</a></li>
        <li><a class="dropdown-item" href="inicio"><i class="bi bi-exclamation-diamond"></i> No tiene Permisos</a></li>

        <li><a class="dropdown-item navbar-light" (click)="logout()"> <i class="bi bi-box-arrow-right"></i> Salir</a>
        </li>
        <br>
        <li><a class="dropdown-item navbar-light" href="#" download data-bs-toggle="tooltip" data-bs-placement="bottom"
            title="clic para visualizar el manual de usuario" target="_blank"> <i
              class="bi bi-question-octagon-fill"></i> Ayuda</a></li>
      </ul>
    </div>

  </div>
</div>
