import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Categorias } from '../model/Categorias';
import { Recurso } from '../model/Recurso';
import { RecursoSave } from '../model/RecursoSave';

@Injectable({
  providedIn: 'root'
})
export class RecursoService {

  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  Url = this.URL_BASE+'/api/v1/allRecursos';
  UrlAll = this.URL_BASE+'/api/v1/recursos/all';
  UrlId = this.URL_BASE+'/api/v1/recursos';

  UrlSaveDelete = this.URL_BASE+'/api/v1/recursos';
  UrlDuplicar = this.URL_BASE+'/api/v1/recursosduplicar';

  // API PARA OBTNER LOS RECURSOS DISPONIBLES EN TIEMPO REAL DE LOS QUE NO ESTAN USADOS
  urlRecursosV2 = this.URL_BASE+'/api/v1/recursobibliov2';

  // API PARA OBTNER LOS RECURSOS DISPONIBLES EN TIEMPO REAL DE LOS QUE NO ESTAN USADOS
  urlRecursosV3 = this.URL_BASE+'/api/v1/recursobibliov3';



  // api get categoria
  urlCategoria = this.URL_BASE+'/api/v1/categoriabiblio';

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) { }

  // obtener el las recurso
  getRecurso(){
    return this.http.get<Recurso[]>(this.Url, this.headers);
  }

  // obtener el las recurso Dtos
  getRecursoNew(){
    return this.http.get<RecursoSave[]>(this.UrlAll, this.headers);
  }

  // obtener el las recurso por id
  getRecursoId(id:string){
    return this.http.get<Recurso>(this.UrlId+"/"+id, this.headers);
  }

  // obtener el las recurso por id
  getRecursoSelect(idPiso:string, idCategoria:String){
    return this.http.get<Recurso[]>(this.UrlId+"/"+idPiso+"/"+idCategoria, this.headers);
  }

   // save Recurso
   postRecursos(RecursoSave:RecursoSave) {
    return this.http.post<RecursoSave>(this.UrlSaveDelete, RecursoSave, this.headers);
  }

  UpadteRegistro(recursoU:RecursoSave){
    return this.http.put<RecursoSave>(this.UrlSaveDelete+"/"+recursoU.id, recursoU, this.headers);
  }

  deleteEnableRegistro(recurso:RecursoSave){
    return this.http.delete(this.UrlSaveDelete+"/"+recurso.id, this.headers);
  }

  duplicarRegistro(recurso:RecursoSave, idDuplicar:number | any){
    return this.http.post<RecursoSave>(this.UrlDuplicar+"/"+recurso.id +"/"+idDuplicar, recurso, this.headers);
  }


   // Version 2 - obtener el las recurso por id
   getRecursoIdv2(id:string){
    return this.http.get<Categorias>(this.urlCategoria+"/"+id, this.headers);
  }
  // Version 2 - obtener el las recurso NO USADOS POR HORA Y PISO Y CATEGORIA
  getRecursoSelectv2(idPiso:string, idCategoria:String, fechaInicio:String, fechaFin:String){
    fechaInicio = fechaInicio.replace(" ", "_");
    fechaFin = fechaFin.replace(" ", "_");
    console.log(this.urlRecursosV2+"/"+idPiso+"/"+idCategoria+"/"+fechaInicio+"/"+fechaFin);
    return this.http.get<Recurso[]>(this.urlRecursosV2+"/"+idPiso+"/"+idCategoria+"/"+fechaInicio+"/"+fechaFin, this.headers);
  }


  // Version 3 - obtener el las recurso NO USADOS POR HORA Y PISO Y CATEGORIA POR UN RANGO DE HORA Y EVITAR QUE SE CRUCEN LAS HORAS
  getRecursoSelectv3(idPiso:string, idCategoria:String, fechaInicio:String, fechaFin:String){
    fechaInicio = fechaInicio.replace(" ", "_");
    fechaFin = fechaFin.replace(" ", "_");
    console.log(this.urlRecursosV3+"/"+idPiso+"/"+idCategoria+"/"+fechaInicio+"/"+fechaFin);
    return this.http.get<Recurso[]>(this.urlRecursosV3+"/"+idPiso+"/"+idCategoria+"/"+fechaInicio+"/"+fechaFin, this.headers);
  }
}
