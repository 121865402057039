<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2" *ngIf=" rols == 'ADMINISTRADOR'">
  <mat-card class="colorCard text-center">
    <h1><i>Control de acceso a biblioteca - Biblioteca UTPL</i></h1>
  </mat-card>
  <br>
  <div class="d-flex justify-content-end">
   <!--  <button class="btn colorUTPLModal m-1" (click)="openLg(content)">Añadir Categoría</button> -->
    <button class="btn colorUTPLBotton m-1" placement="left" ngbTooltip="Información"><i class="bi bi-info-circle"></i></button>
  </div>
  <hr>
  <br>

  <div>
    <h2>Control de accesos a bibliotca</h2>
  </div>

  <div class="p-2 table-responsive justify-content-center">
    <table datatable [dtOptions]="dtOptions"
      class="row-border hover example table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Nombre</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Hora de inicio</th>
          <th scope="col" class="vrt-align-center">Hora de Fin</th>
          <th scope="col" class="vrt-align-center">Días de no atención</th>
          <th scope="col" class="vrt-align-center">Editar</th>
        </tr>
      </thead>
      <tbody *ngIf="controller?.length != 0 ">
        <tr *ngFor="let cate of controller" class="vrt-align">
          <th class="vrt-align-center">{{cate.id}}</th>
          <td class="vrt-align-center">{{cate.nombreCategoria}}</td>
          <td class="vrt-align-center">{{cate.descripcion}}</td>
          <td class="vrt-align-center">{{cate.horaInicio}} </td>
          <td class="vrt-align-center">{{cate.horaFin}} </td>
          <td class="vrt-align-center">{{cate.diasAtencion}} </td>
          <td class="vrt-align-center">
            <!-- <button (click)="update(registro)" type="button" class="btn btn-warning m-2">Editar</button>-->
            <button class="btn btn-warning m-2" (click)="updateShow(cate)" (click)="open(update)" ngbTooltip="Editar"><i class="bi bi-pencil-square"></i></button>
            <!-- <button (click)="deleteEnable(cate)" type="button" class="btn btn-danger " ngbTooltip="Deshabilitar"><i class="bi bi-patch-exclamation-fill"></i></button> -->
          </td>
        </tr>

      </tbody>
      <tbody *ngIf="controller?.length == 0">
        <tr>
          <td colspan="7">No existe ningún registro!</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Nombre</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Hora de inicio</th>
          <th scope="col" class="vrt-align-center">Hora de Fin</th>
          <th scope="col" class="vrt-align-center">Días de no atención</th>
          <th scope="col" class="vrt-align-center">Editar</th>
        </tr>
      </tfoot>
    </table>
  </div>

  <br>
  <br>
  <hr>
  <br>

  <div>
    <h2>Control de aforo de la biblioteca</h2>
  </div>

  <div class="p-2 table-responsive justify-content-center">
    <table datatable [dtOptions]="dtOptions"
      class="row-border hover example table table-striped table-sm">
      <thead>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Número de participantes</th>
          <th scope="col" class="vrt-align-center">Porcentaje de aforo</th>
          <th scope="col" class="vrt-align-center">Aforo permitido</th>
          <th scope="col" class="vrt-align-center">Editar</th>
        </tr>
      </thead>
      <tbody *ngIf="aforo?.length != 0 ">
        <tr *ngFor="let cate of aforo" class="vrt-align">
          <th class="vrt-align-center">{{cate.id}}</th>
          <td class="vrt-align-center">{{cate.numberPeople}}</td>
          <td class="vrt-align-center">{{cate.percentageCapacity}}</td>
          <td class="vrt-align-center">{{cate.peopleCapacity}} </td>
          <td class="vrt-align-center">
            <!-- <button (click)="update(registro)" type="button" class="btn btn-warning m-2">Editar</button>-->
            <button class="btn btn-warning m-2" (click)="updateShowAforo(cate)" (click)="open(updateAforo)" ngbTooltip="Editar"><i class="bi bi-pencil-square"></i></button>
            <!-- <button (click)="deleteEnable(cate)" type="button" class="btn btn-danger " ngbTooltip="Deshabilitar"><i class="bi bi-patch-exclamation-fill"></i></button> -->
          </td>
        </tr>

      </tbody>
      <tbody *ngIf="aforo?.length == 0">
        <tr>
          <td colspan="7">No existe ningún registro!</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col" class="vrt-align-center">id</th>
          <th scope="col" class="vrt-align-center">Número de participantes</th>
          <th scope="col" class="vrt-align-center">Porcentaje de aforo</th>
          <th scope="col" class="vrt-align-center">Aforo permitido</th>
          <th scope="col" class="vrt-align-center">Editar</th>
        </tr>
      </tfoot>
    </table>
  </div>
</section>

<section class="m-2" *ngIf="rols == 'ESTUDIANTE' || rols == 'DOCENTE'  || rols == 'BIBLIOTECARIO' || rols == 'ADMINISTRATIVO' || rols == 'null'  || rols == 'ADMIN-BBC' || rols == 'AUTORIDADES'">
  <div class="container" >

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<!-- formulario para registar
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h3 class="modal-title" id="modal-basic-title">Formulario registro de categoría de Biblioteca</h3>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del categoría
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="categoriaSave.nombreCategoria">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="categoriaSave.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #ubicacion
            [(ngModel)]="categoriaSave.ubicacion">
        </div>
        <div class="col-md-12 mb-2">
          <label for="tiempoUso" class="form-label"> Tiempo de Uso
            <i style="color: red;"><strong>*</strong></i></label>
            <div class="input-group mb-3">
              <input type="number" class="form-control" aria-label="Username" id="tiempoUso" name="tiempoUso"
                aria-describedby="basic-addon2" [(ngModel)]="categoriaSave.tiempoUso">
                <span class="input-group-text" id="basic-addon2">Minutos</span>
            </div>
            <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>
        </div>
        <div class="col-md-12 mb-2">
          <label for="tiempoEspera" class="form-label"> Tiempo de espera
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="Username" id="tiempoEspera" name="tiempoEspera"
              aria-describedby="basic-addon2" [(ngModel)]="categoriaSave.tiempoEspera">
              <span class="input-group-text" id="basic-addon2">Minutos</span>
          </div>
          <div id="emailHelp" class="form-text">El tiempo tiene que estar refereniado en minutos</div>

        </div>
        <div class="mb-3">
          <label for="formFileMultiple" class="form-label">Cargar imagen
            <i style="color: red;"><strong>*</strong></i></label>
          <input class="form-control" type="file" name="files" id="formFileMultiple" (change)="dataChanged($event)" multiple>
        </div>
        <div class="col-md-12 mb-2" style="display: none;">
          <label for="rutaImg" class="form-label"> rutaImg
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="rutaImg" name="rutaImg" #rutaImg
            [ngModel]="categoriaSave.rutaImg">
        </div>
        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" [disabled]="!categoriaSave.ubicacion" (click)="saveRegister(categoriaSave)"
          (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>-->

<!-- formulario para editar-->
<ng-template #update let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h2 class="modal-title" id="modal-basic-title">Actualizar registro de control de acceso</h2>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del registro
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="controllersUpdate.nombreCategoria">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="controllersUpdate.descripcion">
        </div>
        <div class="col-md-12 mb-2">
          <label for="horaInicio" class="form-label"> Hora de inicio de atención
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Username" id="horaInicio" name="horaInicio"
              aria-describedby="basic-addon2" [(ngModel)]="controllersUpdate.horaInicio">
              <span class="input-group-text" id="basic-addon2">hora</span>
          </div>
        </div>
        <div class="col-md-12 mb-2">
          <label for="horaFin" class="form-label"> Hora de Fin de atención
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Username" id="horaFin" name="horaFin"
              aria-describedby="basic-addon2" [(ngModel)]="controllersUpdate.horaFin">
              <span class="input-group-text" id="basic-addon2">hora</span>
          </div>
        </div>

        <div class="col-md-12 mb-2">
          <label for="diasAtencion" class="form-label"> días de no antención
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Username" id="diasAtencion" name="diasAtencion"
              aria-describedby="basic-addon2" [(ngModel)]="controllersUpdate.diasAtencion">
          </div>
        </div>

        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" (click)="actualizar(controllersUpdate)" (click)="d('Cross click')"
          class="btn btn-warning">Actualizar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario para editar aforo -->
<ng-template #updateAforo let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h2 class="modal-title" id="modal-basic-title">Actualizar registro de control de aforo</h2>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="numberPeople" class="form-label">Número de participantes
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="numberPeople" name="numberPeople"
            [(ngModel)]="aforoUpdate.numberPeople">
        </div>
        <div class="col-md-12 mb-2">
          <label for="percentageCapacity" class="form-label"> Porcentaje de aforo
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" aria-label="Username" id="percentageCapacity" name="percentageCapacity"
              aria-describedby="basic-addon2" [(ngModel)]="aforoUpdate.percentageCapacity">
              <span class="input-group-text" id="basic-addon2">% (Porcentaje de aforo)</span>
          </div>
        </div>
        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" (click)="actualizarAforo(aforoUpdate)" (click)="d('Cross click')"
          class="btn btn-warning">Actualizar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
