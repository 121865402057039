<nav class="navbar navbar-dark colorNav">
  <div class="container-fluid ">

      <a class="bienv" href="/">
        Bienvenido, &nbsp;<b>{{profile?.givenName}}</b>
      </a>
    <span class="example-spacer"></span>
    <a class="navbar-brand d-flex">
      <img src="https://bibliotecautpl.utpl.edu.ec/images/imgMail/reservas_utpl_mas.png"
        class="img-fluid imgBiblio" width="210" height="auto">
    </a>
  </div>
</nav>
<ul class="nav justify-content-center colorCard">
  <li class="nav-item">
    <a class="nav-link" href="/"><strong>Inicio</strong> </a>
  </li>
  <li class="nav-item"  *ngFor="let m of menus">
    <a class="nav-link" href="{{m.url}}"><strong>{{m.icono}}</strong> </a>
  </li>


  <li class="nav-item" *ngIf="roles === 'ADMIN_LAB_INGLES' || roles === 'ADMIN_COMPUTO' || roles == 'ADMINISTRADOR' || roles == 'AUTORIDADES' || roles == 'ADMINISTRADOR_JEFE'  || roles == 'ADMIN_VIDEO_CONFERENCIA' || roles == 'BIBLIOTECA_AUTO'  || roles == 'ADMIN_SLREUNIONES'  || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">
    <a  class="nav-link" href="https://app.powerbi.com/groups/me/reports/15fbe05f-20af-496b-8871-843e6b124e16?ctid=6eeb49aa-436d-43e6-becd-bbdf79e5077d&pbi_source=linkShare&bookmarkGuid=f7a53139-89d0-478c-8159-c06878bfbd6e" data-bs-toggle="tooltip" data-bs-placement="bottom"
      title="Manual de usuario" target="_blank">  <strong>Reporte Power BI</strong> </a>
  </li>

  <li class="nav-item" *ngIf=" roles === 'DOCENTE' || roles === 'TECNICO DOCENTE' || roles === 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR' || roles == 'AUTORIDADES' || roles == 'ADMINISTRADOR_JEFE' || roles == 'ADMIN_COMPUTO' || roles == 'ADMIN_VIDEO_CONFERENCIA' || roles === 'ADMIN_LAB_INGLES' || roles === 'BIBLIOTECA_AUTO'  || roles == 'ADMIN_SLREUNIONES'  || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">
    <!-- <a class="nav-link" href="https://cedia.zoom.us/j/83373375615" download data-bs-toggle="tooltip" data-bs-placement="bottom"
      title="Enlace para teleasistencia de uso de la herramienta" target="_blank">  <strong>Ayuda</strong> </a> -->
    <a  class="nav-link" href="https://utpl-my.sharepoint.com/:b:/g/personal/reservasalas_utpl_edu_ec/EbJFqjTQ2ZhCtf9H9UiskUoB-ZH3ZT-Z03kSZemztVtRig?e=lT3zlW" download data-bs-toggle="tooltip" data-bs-placement="bottom"
      title="Manual de usuario" target="_blank">  <strong>Manual de Uso</strong> </a>
  </li>
  <li class="nav-item" >
    <a class="nav-link" *ngIf="loginDisplay" (click)="logout()"><i class="bi bi-box-arrow-right"></i>&nbsp;<strong>Salir</strong> </a>
  </li>
</ul>

