import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Menu } from '../model/Menu';
import { Rol } from '../model/Rol';
import { Usuarios } from '../model/Usuarios';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  UrlMenu = this.URL_BASE+'/api/v1/menus/usuarios';
  UrlRol = this.URL_BASE+'/api/v1/rol/user';
  UrlRolDocente = this.URL_BASE+'/api/v1/rol/user';
  UrlGetUser = this.URL_BASE+'/api/v1/userDataId';
  urlSearchUser = 'https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/SearchCreatePerson';
  //urlusers = this.URL_BASE+'/api/v1/users';
  urlusers = 'https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/search/user';
  //urluserSave = this.URL_BASE+'/api/v1/userSave';
  urluserSave = 'https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/userSave';

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };
  headersBiblio = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa("reservasBiblio:reserv@sBiblio&12")
      )

      .set('Content-Type', 'application/json'),
  };


  constructor(private http: HttpClient) { }

  getPersona(cedula: String ): Observable<any> {
    return this.http.get(
      'https://srv-si-001.utpl.edu.ec/apim/netcore/1.0/api/services/app/Persona/Get?Id='+cedula,
      {
        headers: new HttpHeaders()
          /* .set(
            'Authorization',
            'Bearer eyJ4NXQiOiJNell4TW1Ga09HWXdNV0kwWldObU5EY3hOR1l3WW1NNFpUQTNNV0kyTkRBelpHUXpOR00wWkdSbE5qSmtPREZrWkRSaU9URmtNV0ZoTXpVMlpHVmxOZyIsImtpZCI6Ik16WXhNbUZrT0dZd01XSTBaV05tTkRjeE5HWXdZbU00WlRBM01XSTJOREF6WkdRek5HTTBaR1JsTmpKa09ERmtaRFJpT1RGa01XRmhNelUyWkdWbE5nX1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJVVFBMLkVEVS5FQ1wvc2ZzdWFyZXpAY2FyYm9uLnN1cGVyIiwiYXV0IjoiQVBQTElDQVRJT04iLCJhdWQiOiJoTXlsZHoxRllNT0JUZWFFNEQzUFozSmQ2d0VhIiwibmJmIjoxNjMwNjgxMzMyLCJhenAiOiJoTXlsZHoxRllNT0JUZWFFNEQzUFozSmQ2d0VhIiwic2NvcGUiOiJhbV9hcHBsaWNhdGlvbl9zY29wZSBkZWZhdWx0IiwiaXNzIjoiaHR0cHM6XC9cL3Nydi1zaS0wMDEudXRwbC5lZHUuZWM6NDQzXC9vYXV0aDJcL3Rva2VuIiwiZXhwIjoxNjMwNjg0OTMyLCJpYXQiOjE2MzA2ODEzMzIsImp0aSI6ImU0NDdkMmQxLWJhYTgtNDM5NS1iMTM5LWY1ZGZkZjU1YTlhYiJ9.HZjIs3OZ3q1-_R-J4Y7h5iVKPXB1ObgAnPe5CioHD3BqVR-MS_Ayqm25bZHD3AP9ikBUla-ObNv3Ql2UW4ghUuz3v4rWMUu2y_YlDMCmVq3Zb4EHPTkQQee-0Orbr05HFFgHqni2Z3v7dtGHZ_j8l53lY_-6AoHGQLri1szi-iXLy2NzXoaV3wii0i9wKpo-tuTiozdxcyUXYxjCYTQCxCzL3fwiJVkpUSHm4vRgmTpsToT43FuDtfZpB9gRnfIjkQzdPdrOwy_1xaWuaD3CuBHQLMUfh9a5vwF3UoBZr38f45J3fWHXyR0_i9pkaCrz9QNtt-TiLaBCaGgqZP1bEg'
          ) */
          .set(
            'apikey',
            'eyJ4NXQiOiJOVGRtWmpNNFpEazNOalkwWXpjNU1tWm1PRGd3TVRFM01XWXdOREU1TVdSbFpEZzROemM0WkE9PSIsImtpZCI6ImdhdGV3YXlfY2VydGlmaWNhdGVfYWxpYXMiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJVVFBMLkVEVS5FQ1wvc2ZzdWFyZXpAY2FyYm9uLnN1cGVyIiwiYXBwbGljYXRpb24iOnsib3duZXIiOiJVVFBMLkVEVS5FQ1wvc2ZzdWFyZXoiLCJ0aWVyUXVvdGFUeXBlIjpudWxsLCJ0aWVyIjoiVW5saW1pdGVkIiwibmFtZSI6IlJlZ2lzdHJvVGVsZWFzaXN0ZW5jaWEiLCJpZCI6MzAyLCJ1dWlkIjoiNTM0YmQ3NTEtZmIxMC00MzYxLTkwMjMtOTVlNjQyZjgxZTA2In0sImlzcyI6Imh0dHBzOlwvXC9zcnYtc2ktMDAxLnV0cGwuZWR1LmVjOjQ0M1wvb2F1dGgyXC90b2tlbiIsInRpZXJJbmZvIjp7IlVubGltaXRlZCI6eyJ0aWVyUXVvdGFUeXBlIjoicmVxdWVzdENvdW50IiwiZ3JhcGhRTE1heENvbXBsZXhpdHkiOjAsImdyYXBoUUxNYXhEZXB0aCI6MCwic3RvcE9uUXVvdGFSZWFjaCI6dHJ1ZSwic3Bpa2VBcnJlc3RMaW1pdCI6MCwic3Bpa2VBcnJlc3RVbml0IjpudWxsfX0sImtleXR5cGUiOiJQUk9EVUNUSU9OIiwicGVybWl0dGVkUmVmZXJlciI6IiIsInN1YnNjcmliZWRBUElzIjpbeyJzdWJzY3JpYmVyVGVuYW50RG9tYWluIjoiY2FyYm9uLnN1cGVyIiwibmFtZSI6Ik5ldENvcmVBcGkiLCJjb250ZXh0IjoiXC9hcGltXC9uZXRjb3JlXC8xLjAiLCJwdWJsaXNoZXIiOiJVVFBMLkVEVS5FQ1wvanNjYWxkZXJvbiIsInZlcnNpb24iOiIxLjAiLCJzdWJzY3JpcHRpb25UaWVyIjoiVW5saW1pdGVkIn1dLCJwZXJtaXR0ZWRJUCI6IiIsImlhdCI6MTYyOTgxODYxMCwianRpIjoiOTIyZmJlYTQtOWViNC00ZDExLWJjYjUtMjAyMjBlZTQyY2JmIn0=.TfrY7l7kzvvK9f3TBEqgUa9z_4tbiu0CnWYv1tSK_oyKOn1NunKmYmWyFidUotK7oIaXH7_rHK7eJTmhBxTAXkLK-Tue_aFO8sgRpBVqtkkXnbRTj8Lt0e0bZq2AZCPI_GDHDzWsj-UAhuCn_ehPZu4lprgv_FKGjNQZERgNfVhW0XOiv2In08D-VjsCwkftWAS9sfwMaffEMSy4hFqghhVbwKhs2KxkyfTIraLqHhge8rwzlkdk0gkpDP3haxZJTCDP8yWXh-jPbZt_KzCqzVNYYYKgyJ6xldCv9wJ_Y6nXxoUc1ME3GKs7VKSkFnPGmPBfT5yfbpLtsWAncVPwfg=='
          )

          .set('Content-Type', 'application/json'),
      }
    );
  }

  getPersonaUser(id: string): Observable<any> {
    return this.http.get(this.urlSearchUser+"/"+id, this.headersBiblio);
  }

  getIdUser(id: string): Observable<any> {
    return this.http.get(this.urlusers+"/"+id, this.headersBiblio);
  }

  getMenu(name:String){
    return this.http.post<Menu[]>(this.UrlMenu+"/"+name,name, this.headers);
  }

  RolUser(name:String){
    return this.http.post<Rol>(this.UrlRol+"/"+name,name, this.headers);
  }


   // save Recurso
   postUser(user:Usuarios) {
    return this.http.post<Usuarios>(this.urluserSave, user, this.headersBiblio);
  }

  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }


}
