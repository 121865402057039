<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>


<section class="m-3"
  *ngIf="roles === 'BIBLIOTECARIO' || roles === 'DOCENTE' || roles === 'TECNICO DOCENTE' || roles === 'ESTUDIANTE' || roles === 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR'  || roles == 'ADMIN-BBC'  || roles == 'AUTORIDADES' || roles == 'ADMINISTRADOR_JEFE' || roles == 'ADMIN_COMPUTO' || roles == 'ADMIN_LAB_INGLES' || roles == 'ADMIN_VIDEO_CONFERENCIA'  || roles == 'ADMIN_SALA_ACAD' || roles == 'ADMIN_APASUTPL'  || roles == 'BIBLIOTECA_AUTO'  || roles == 'ADMIN_SLREUNIONES' || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">

  <div class="card colorCard">
    <div class="card-body text-center titlePage">
      <strong>Sistema de Reservas</strong>
    </div>
    <!--Reservas para los docentes y otros roles -->
    <div class="card colorCard border-0"
      *ngIf=" roles === 'DOCENTE'  || roles === 'TECNICO DOCENTE' || roles === 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR'  || roles == 'AUTORIDADES' || roles == 'ADMINISTRADOR_JEFE' || roles == 'ADMIN_COMPUTO' || roles == 'ADMIN_LAB_INGLES' || roles == 'ADMIN_VIDEO_CONFERENCIA' || roles == 'ADMIN_SALA_ACAD' || roles === 'BIBLIOTECARIO' || roles == 'ADMIN_APASUTPL' || roles == 'BIBLIOTECA_AUTO'  || roles == 'ADMIN_SLREUNIONES' || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">
      <div class="row">
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body abs-center">
              <div class="  titlePage">
                <strong>Reservas de {{CategoriaName}}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body">
              <p>Por favor, seleccione la categoría de reserva:</p>
              <select class="form-select" aria-label="Default select example" [(ngModel)]="CategoriaSelect"
                (change)="selectCategory()">
                <option *ngFor="let bsc of categorias" value="{{bsc.id}}-_{{bsc.nombreCategoria}}_{{bsc.tiempoUso}}">
                  {{bsc.nombreCategoria}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Reservas para los estudiantes -->
    <div class="card colorCard border-0" *ngIf=" roles === 'ESTUDIANTE'  ">
      <div class="  card-body text-center titlePage">
        <strong>Reservas de {{CategoriaName}}</strong>
      </div>


    </div>
  </div>

  <br>
  <div>
    <mbsc-eventcalendar [view]="view" [dragTimeStep]="30" [data]="myEvents" [resources]="myResourcesArrs"
      [options]="eventSettings" [(selectedDate)]="calendarSelectedDate" id="eventcalendar" >
    </mbsc-eventcalendar>


    <mbsc-popup [options]="popupOptions" [anchor]="popupAnchor" [buttons]="popupButtons" [headerText]="popupHeaderText"
      #popup>
      <div class="mbsc-form-group">
        <div class="mbsc-form-group-title">Datos del usuario</div>
        <mbsc-input label="Nombres" [disabled]="state" [(ngModel)]="popupEventTitle"></mbsc-input>
        <mbsc-textarea label="Rol" [disabled]="state" [(ngModel)]="popupEventDescription"></mbsc-textarea>
        <mbsc-input label="Email" [disabled]="state" [(ngModel)]="popupEventEmail"></mbsc-input>
        <mbsc-input label="idReserva" style="display: none" [(ngModel)]="popupIdReserva"></mbsc-input>
      </div>

      <div class="mbsc-form-group">
        <div class="mbsc-form-group-title">Información de la reserva</div>
        <mbsc-input label="Asunto: " placeholder="Agregue el asunto de la reserva" *ngIf=" popupValidationPerson == 1"
          [(ngModel)]="popupTemaReserva"></mbsc-input>
        <mbsc-textarea label="Descripción: " placeholder="Incluir detalle del uso y especificar software requerido"
          *ngIf=" popupValidationPerson == 1" [(ngModel)]="popupDescripReserva"></mbsc-textarea>
        <mbsc-stepper label="Número de asistentes" description="Incluir el número de asistentes" [min]="popupCapacidadNumber1" [max]="popupCapacidadNumber"
          *ngIf=" popupValidationPerson == 1" [(ngModel)]="popupCantidadAsistentes"></mbsc-stepper>

        <mbsc-input label="Capacidad " [disabled]="state" [(ngModel)]="popupNumberCapacidad"></mbsc-input>
        <mbsc-input label="idReserva" style="display: none" [(ngModel)]="popupIdReserva"></mbsc-input>
      </div>

      <div class="mbsc-form-group">
        <div class="mbsc-form-group-title">Duración máxima de reserva - {{tiempoUsoCategoryHoras}} horas</div>
        <!--<mbsc-datepicker [(ngModel)]="popupEventDates" [options]="datePickerOptions" [controls]="datetimePickerControls" (onChange)="onChanges()"
          [startInput]="startInput" [endInput]="endInput" #datepickerTabWrapper></mbsc-datepicker>-->
        <mbsc-datepicker [(ngModel)]="popupEventDates" [options]="datePickerOptions" [controls]="datetimePickerControls"
          (onChange)="onChanges()" [startInput]="startInput" [endInput]="endInput"
          #datepickerTabWrapper></mbsc-datepicker>

        <mbsc-input #startInput label="Inicio" id="startInput"></mbsc-input>
        <mbsc-input #endInput label="Fin"></mbsc-input>
        <div class="mbsc-button-group" *ngIf="isEditPassHours">
          <mbsc-button *ngIf="isEdit" class="mbsc-button-block" color="danger" variant="outline"
            (click)="onDeleteClick()">
            Cancelar reserva
          </mbsc-button>
        </div>
      </div>
    </mbsc-popup>
  </div>


  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<section class="m-2"
  *ngIf="roles == 'null' || roles == ' ' || roles == '' || roles == 'EVENTOS_ADMIN'  || roles == 'EVENTOS'  || roles == 'ALUMNI'">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<div id="loading-screen" style="display:none; " #loadingScreens>
  <img src="./assets/img/spinning-circles.svg">
</div>
