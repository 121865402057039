<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-3"
  *ngIf="roles === 'BIBLIOTECARIO' || roles == 'ADMINISTRADOR'  || roles == 'ADMIN-BBC' || roles == 'ADMIN_COMPUTO' || roles == 'ADMIN_LAB_INGLES' || roles == 'ADMIN_VIDEO_CONFERENCIA' || roles == 'ADMIN_SALA_ACAD'  || roles == 'ADMIN_APASUTPL' || roles == 'BIBLIOTECA_AUTO'  || roles == 'ADMIN_SLREUNIONES' || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F' ">
  <div class="card colorCard">
    <div class="card-body text-center titlePage">
      <strong>Administración de reservas | Reservas UTPL</strong>
      <div class="form-text">Reservas realizadas para el día | <b>{{horaDiv}}</b></div>
    </div>
    <!-- aprobrar todas las reservas solicitadas -->
    <div class="card colorCard border-0">
      <div class="row">
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body abs-center">
              <div class="  titlePage">
                <strong>Validación de solicitudes de reservas de {{CategoriaName}}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card colorCard border-0">
            <div class="card-body">
              <p>Por favor, seleccione la categoría de reserva:</p>
              <select class="form-select" aria-label="Default select example" [(ngModel)]="CategoriaSelect"
                (change)="selectCategory()">
                <option *ngFor="let bsc of categorias" value="{{bsc.id}}-_{{bsc.nombreCategoria}}_{{bsc.tiempoUso}}">
                  {{bsc.nombreCategoria}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="d-flex justify-content-end" *ngIf="roles === 'BIBLIOTECARIO' || roles == 'ADMINISTRADOR'  || roles == 'ADMIN-BBC'">
    <button class="btn colorUTPLModalUser m-1" (click)="openLg2(contentUser)">Crear Usuario</button>
    <button class="btn colorUTPLBotton m-1" placement="left"
      ngbTooltip='Información 📢 : Al hacer clic en "Crear Usuario se desplegará un formulario para crear aquel usuario que no este registrado en el mismo'><i
        class="bi bi-info-circle"></i></button>
  </div>
  <br>
  <div>
    <mbsc-eventcalendar [view]="view" [dragTimeStep]="30" [data]="myEvents"
      [resources]="myResourcesArrs" [options]="eventSettings"
      [(selectedDate)]="calendarSelectedDate" id="eventcalendar">
    </mbsc-eventcalendar>


    <mbsc-popup [options]="popupOptions" [anchor]="popupAnchor" [buttons]="popupButtons" [headerText]="popupHeaderText"
      #popup>
      <div class="mbsc-form-group">
        <div class="mbsc-form-group"  *ngIf="isSearch">
          <mbsc-input label="Buscador" [(ngModel)]="buscarPersona" (keyup.enter)="getDatosPersona()"></mbsc-input>
          <mbsc-button class="mbsc-button-block" color="warning" variant="outline" (click)="getDatosPersona()">
            Buscar usuario
          </mbsc-button>
        </div>
        <mbsc-input label="Nombres" [disabled]="state" [(ngModel)]="popupEventTitle"></mbsc-input>
        <mbsc-textarea label="Rol" [disabled]="state" [(ngModel)]="popupEventDescription"></mbsc-textarea>
        <mbsc-input label="Email" [disabled]="state" [(ngModel)]="popupEventEmail"></mbsc-input>
        <mbsc-input label="idReserva" style="display: none" [(ngModel)]="popupIdReserva"></mbsc-input>

      </div>
      <div class="mbsc-form-group">
        <div class="mbsc-form-group-title">Información de la reserva</div>
        <mbsc-input label="Asunto: " placeholder="Agregue el asunto de la reserva" *ngIf=" popupValidationPerson == 1" [(ngModel)]="popupTemaReserva" required></mbsc-input>
        <mbsc-input label="Descripción: " placeholder="Incluir detalle del uso y especificar software requerido" *ngIf=" popupValidationPerson == 1" [(ngModel)]="popupDescripReserva" required></mbsc-input>
        <mbsc-stepper label="Número de asistentes " description="Incluir el número de asistentes" [min]="1" [max]="popupCapacidadNumber" *ngIf=" popupValidationPerson == 1" [(ngModel)]="popupCantidadAsistentes"   ></mbsc-stepper>

        <mbsc-input label="Capacidad " [disabled]="state" [(ngModel)]="popupNumberCapacidad"></mbsc-input>
        <mbsc-input label="idReserva" style="display: none" [(ngModel)]="popupIdReserva"></mbsc-input>
      </div>
      <div class="mbsc-form-group">
        <div class="mbsc-form-group-title">Duración máxima de reserva - {{tiempoUsoCategoryHoras}} horas</div>

        <mbsc-datepicker [(ngModel)]="popupEventDates" [options]="datePickerOptions" [controls]="datetimePickerControls"
          [startInput]="startInput" [endInput]="endInput"></mbsc-datepicker>
        <mbsc-input #startInput label="Inicio"></mbsc-input>
        <mbsc-input #endInput label="Fin"></mbsc-input>
        <div class="mbsc-button-group" *ngIf="isEditPassHours">
          <mbsc-button *ngIf="isEdit" class="mbsc-button-block" color="danger" variant="outline"
            (click)="onDeleteClick()">
            Eliminar reserva
          </mbsc-button>
        </div>
      </div>
    </mbsc-popup>
  </div>


  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>



  <ng-template let-d="dismiss" #contentModal data-bs-keyboard="false">
    <div class="m-3 textCenter">
      <h2 class="textCenter"> 📢 🛠Atención</h2>
    </div>
    <div class="modal-body">
      <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
    </div>
  </ng-template>




  <!-- formulario para crear usuarios -->
  <ng-template #contentUser let-c="close" let-d="dismiss">
    <div class="modal-header modal-lg">
      <h4 class="modal-title" id="modal-basic-title">Formulario de creación de usuario</h4>
      <button type="button" class="btn" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form action="" method="post" enctype="multipart/form-data">
        <div class="row m-2">
          <!-- Buscador de persona-->
          <div class="col-md-12 row">
            <label for="fechaReservaInicio" class="form-label">Búsqueda de persona<i
                style="color: red;"><strong>*</strong></i></label>
            <div class="col-lg-10">

              <input [(ngModel)]="users_save.id" type="text" class="form-control" id="cedulaDir" name="cedulaDir"
                placeholder="Eje: 1102323221" #myDivUser (keyup.enter)="getDatosUser()">
            </div>
            <div class="col-lg-2">
              <button type="button" class="btn colorUTPLBotones" id="updateData" (click)="getDatosUser()"> <i
                  class="bi bi-search"></i> Buscar</button>

            </div>
          </div>
          <br>
          <hr class="m-3 container col-md-11">
          <!-- datos personales -->
          <div class="row">
            <!-- Nombres personales -->
            <div class="col-md-6 p-2">
              <label for="names" class="form-label">Nombres del usuario<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="names" name="names" #names [(ngModel)]="users_save.names">
            </div>
            <!-- Apellidos personales -->
            <div class="col-md-6 p-2">
              <label for="namesPerson" class="form-label">Apellidos del usuario<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="namesPerson" name="namesPerson" #namesPerson
                [(ngModel)]="users_save.namesPerson">
            </div>
          </div>
          <!-- usuario y coreo personales -->
          <div class="row">
            <!-- usuario personales -->
            <div class="col-md-6 p-2">
              <label for="username" class="form-label">usuario institucional<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="username" name="username" #username
                [(ngModel)]="users_save.username">
            </div>
            <!-- email personales -->
            <div class="col-md-6 p-2">
              <label for="userEmail" class="form-label">Email institucional<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="userEmail" name="userEmail" #userEmail
                [(ngModel)]="users_save.userEmail">
            </div>
          </div>
          <!-- rol e institucion  -->
          <div class="row">
            <!-- rol personales -->
            <div class="col-md-6 p-2">
              <label for="rol" class="form-label">Rol institucional<i style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="rol" id="rol"
                [(ngModel)]="users_save.rol" (change)="rolUsers()">
                <option selected>Seleccione...</option>
                <option value="ESTUDIANTE">ESTUDIANTE</option>
                <option value="DOCENTE">DOCENTE</option>
                <option value="ADMINISTRATIVO">ADMINISTRATIVO</option>
                <option value="ALUMNI">ALUMNI</option>
                <option value="TECNICO DOCENTE">TECNICO DOCENTE</option>
                <option value="AUTORIDADES" *ngIf=" roles == 'ADMINISTRADOR'">AUTORIDADES</option>
                <option value="BIBLIOTECA_AUTO" *ngIf=" roles == 'ADMINISTRADOR'">AUTORIDAD DE BIBLIOTECA</option>
                <option value="BIBLIOTECARIO" *ngIf=" roles == 'ADMINISTRADOR'">BIBLIOTECARIO</option>
                <option value="ADMINISTRADOR" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR</option>
                <option value="EVENTOS" *ngIf=" roles == 'ADMINISTRADOR'">EVENTOS</option>
                <option value="EVENTOS_ADMIN" *ngIf=" roles == 'ADMINISTRADOR'">EVENTOS ADMINISTRADOR</option>
                <option value="ADMIN_COMPUTO" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR CENTRO DE COMPUTO</option>
                <option value="ADMIN_LAB_INGLES" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR CENTRO LABORATORIO INGLÉS</option>
                <option value="ADMIN_VIDEO_CONFERENCIA" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR DE SALAS DE VIDEO CONFERENCIAS</option>
                <option value="ADMIN_APASUTPL" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR DE SALAS APASUTPL</option>
                <option value="ADMIN_SLREUNIONES" *ngIf=" roles == 'ADMINISTRADOR'">ADMINISTRADOR DE SALAS REUNIONES</option>
                <option value="ADMIN_SLREUNIONES_EF_D" *ngIf=" roles == 'ADMINISTRADOR'">Adminde Sala de reuniones EDF D</option>
                <option value="ADMIN_SLREUNIONES_EF_C" *ngIf=" roles == 'ADMINISTRADOR'">Adminde Sala de reuniones EDF C</option>
                <option value="ADMIN_SLREUNIONES_EF_F" *ngIf=" roles == 'ADMINISTRADOR'">Adminde Sala de reuniones EDF F</option>
                <option value="OTROS" *ngIf=" roles == 'ADMINISTRADOR'">OTROS</option>
              </select>
            </div>
            <!-- Modalidad personales -->
            <div class="col-md-6 p-2">
              <label for="modalidad" class="form-label">Modalidad</label>
              <select class="form-select" aria-label="Default select example" name="modalidad" id="modalidad"
                [(ngModel)]="users_save.modalidad">
                <option selected>Seleccione...</option>
                <option value="PRESENCIAL">PRESENCIAL</option>
                <option value="DISTANCIA">DISTANCIA</option>
                <option value="ALUMNI">ALUMNI</option>
                <option value="null">ND</option>
              </select>
            </div>
          </div>
          <br><br>

          <!-- datos academicos  -->
          <div class="row" *ngIf="estudiante">
            <h5>Datos académicos de estudiante</h5>
            <!-- nivelAcademico personales -->
            <div class="col-md-6 p-2">
              <label for="nivelAcademico" class="form-label">Nivel Académico<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="nivelAcademico" id="nivelAcademico"
                [(ngModel)]="users_save.nivelAcademico">
                <option selected>Seleccione...</option>
                <option value="GRADO">GRADO</option>
                <option value="POSGRADO">POSGRADO</option>
                <option value="ALUMNI">ALUMNI</option>
              </select>
            </div>
            <!-- programa estudio -->
            <div class="col-md-6 p-2">
              <label for="programa" class="form-label">Programa académico</label>
              <input type="text" class="form-control" id="programa" name="programa" #programa
                [(ngModel)]="users_save.programa">
            </div>
          </div>
          <br><br>
          <!-- Personal administrativo o docente  -->
          <div class="row" *ngIf="docente">
            <h5>Datos Personal administrativo o docente</h5>
            <!-- rol personales -->
            <div class="col-md-6 p-2">
              <label for="facultad" class="form-label">Facultad<i style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="facultad" name="facultad" #facultad
                [(ngModel)]="users_save.facultad">
            </div>
            <!-- Modalidad personales -->
            <div class="col-md-6 p-2">
              <label for="departamento" class="form-label">Departamento</label>
              <input type="text" class="form-control" id="departamento" name="departamento" #departamento
                [(ngModel)]="users_save.departamento">
            </div>
          </div>

          <!-- Datos de Centro Universitario y Estado de activación -->
          <div class="row">
            <!-- usuario personales -->
            <div class="col-md-6 p-2">
              <label for="centroUniversitario" class="form-label">Centro Universitario<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="centroUniversitario" name="centroUniversitario"
                #centroUniversitario [(ngModel)]="users_save.centroUniversitario">
            </div>
            <!-- Estado de activación -->
            <div class="col-md-6 p-2">
              <label for="enable" class="form-label">Estado de activación<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="enable" id="enable"
                [(ngModel)]="users_save.enable">
                <option selected>Seleccione...</option>
                <option value="true">Activo</option>
                <option value="false">Deshabilitado</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-md-6 col">
          <button type="submit" (click)="guardarUser(users_save)" (click)="d('Cross click')"
            class="btn btn-primary">Guardar
          </button>
        </div>
        <div class="col-md-6 col">
          <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

        </div>
      </div>
    </div>
  </ng-template>

  <div id="loading-screen" style="display:none; " #loadingScreens>
    <img src="./assets/img/spinning-circles.svg">
  </div>
</section>
<section class="m-2" *ngIf="roles == 'null' || roles == ' '  || roles == 'AUTORIDADES'  || roles == 'EVENTOS'  || roles == 'EVENTOS_ADMIN'  || roles === 'DOCENTE' || roles === 'ESTUDIANTE' || roles === 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR_JEFE'  || roles == 'ALUMNI'">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>
