import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Categorias } from '../model/Categorias';
import { Pisos } from '../model/Pisos';
import { Recurso } from '../model/Recurso';
import { ReservaSave } from '../model/ReservaSave';
import { CategoriaService } from '../Service/categoria.service';
import { ReservasService } from '../Service/reservas.service';
import { ToastService } from '../Service/toast.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RecursoService } from '../Service/recurso.service';
import { PisosService } from '../Service/pisos.service';
import { Reservas } from '../model/Reservas';
import { Router } from '@angular/router';
import { ServiceService } from '../Service/service.service';
import { Menu } from '../model/Menu';
import { InteractionType } from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { ControllersService } from '../Service/controllers.service';
import Swal from 'sweetalert2';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  profile!: ProfileType;
  user: string | undefined;
  roles: any;
  recurso: Recurso = new Recurso();
  reservas_save: ReservaSave = new ReservaSave();
  categoriaList: Categorias[] = [];
  reservasListShow: Reservas[] = [];
  reserva: ReservaSave[] = [];
  recursoList: Recurso[] = [];
  pisosList: Pisos[] = [];
  pisosListado: Pisos[] = [];
  cateList: Categorias[] = [];
  fechaList: any;
  fechaListV2: any;

  // para las fechas
  fechaId: any;
  fechaActual: any;
  horaInicio: string | undefined;
  horaFin: string | undefined;
  horaInicioFor = '07:00:00';
  horaFinFor = '21:00:00';
  minutosUso!: number;
  minutosEspera!: number;
  fechaInicioChange: any;
  tiempoHome: any;

  // información adicinal
  infoNombreRecurso: any;
  infoDescripcion: any;
  infoUbicacion: any;
  capacidadUso: any;
  imgRuta: any;
  datepickers!: any;
  date: any;
  reservas: any;
  reservasTomorrow: any; // tomorrowany;
  divSize!: number;

  //show divs actions
  public edited = false;
  public edited2 = false;
  public edited3 = false;
  public edited3_1 = false;
  public edited4 = false;
  public editedInfo = false;
  public showMore = true;
  public showLess = false;
  canCheckbox: boolean = false;

  //action de cambio combo box
  verSeleccion: string | undefined;
  favoriteSeason: string | undefined;
  document: any;
  horaDiv: any;
  menus: Menu[] = [];

  @ViewChild('loadingScreens') loadingScreens!: ElementRef;
  @ViewChild('contentModal', { static: true }) contentModal!: ElementRef;

  constructor(
    private reservaService: ReservasService,
    private title: Title,
    private http: HttpClient,
    private modalService: NgbModal,
    private recursoService: RecursoService,
    private pisosService: PisosService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private service: ServiceService,
    private cateService: CategoriaService,
    private controlService: ControllersService,
    private router: Router,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.title.setTitle('Inicio | Reservas');
    //this.openLgHome();
    this.divSize = 3;
    //this.getCategoria();
    this.getRolAndList();
    this.getCategorias();
    this.getRolList();
    this.desablePiso();
    this.desableCategory();

    //this.openInfoAlert();
  }

  getRolAndList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.reservaService.getReserva('' + this.user + '').subscribe((data) => {
        this.horaDiv = this.getFechaActualAndHora();
        this.reservas = data;
      });
      this.reservaService
        .getReservaTomorrow('' + this.user + '')
        .subscribe((data) => {
          this.reservasTomorrow = data;
        });
      //getReservaManana
    });
  }

  getCategoria() {
    this.cateService.getCategoria().subscribe((data) => {
      this.categoriaList = data;
      for (let i = 0; i < data.length; i++) {
        this.tiempoHome = formatSeconds(data[i].tiempoUso);
      }
    });
  }

  editar(reser: Categorias) {
    localStorage.setItem('id', reser.id.toString());
    this.reservas_save.categoriaReserva = reser.id;
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.roles = data.rol;
        if (this.roles == 'ESTUDIANTE') {
          this.openInfoAlert();
        }
      });
    });
  }

  desablePiso() {
    var pisoPlantaBaja = 1;
    var pisoPrimeroPlantaAlta = 2;
    var pisoSegundaPlantaAlta = 3;
    var pisoTerceraPlantaAlta = 4;
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.pisosService.getPisos().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();
          if (rolPiso == 'ESTUDIANTE') {
            for (let i = 0; i < data.length; i++) {
              if (data[i].id != pisoTerceraPlantaAlta) {
                this.pisosListado.push(data[i]);
              }
            }
          } else if (rolPiso == 'DOCENTE' || rolPiso == 'ADMINISTRATIVO') {
            for (let i = 0; i < data.length; i++) {
              if (
                data[i].id != pisoPlantaBaja &&
                data[i].id != pisoPrimeroPlantaAlta &&
                data[i].id != pisoSegundaPlantaAlta
              ) {
                this.pisosListado.push(data[i]);
              }
            }
          } else {
            this.pisosListado = data;
          }
        });
      });
    });
  }
  desableCategory() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.cateService.getCategoria().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();

          for (let i = 0; i < data.length; i++) {
            this.tiempoHome = formatSeconds(data[i].tiempoUso);
            this.categoriaList.push(data[i]);
            this.divSize = this.categoriaList.length;
          }
        });
      });
    });
  }

  /* desableCategory() {
    var sala = 1;
    var salasDocentes = 181;
    var prestamoLibro = 161;
    var devolucionLibro = 162;
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.cateService.getCategoria().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();
          if (rolPiso == 'ESTUDIANTE') {
            for (let i = 0; i < data.length; i++) {
              if (
                data[i].id != sala &&
                data[i].id != prestamoLibro &&
                data[i].id != devolucionLibro &&
                data[i].id != salasDocentes
              ) {
                this.tiempoHome = formatSeconds(data[i].tiempoUso);
                this.categoriaList.push(data[i]);
                this.divSize = this.categoriaList.length;
              }
            }
          } else {
            if (rolPiso == 'DOCENTE') {
              for (let i = 0; i < data.length; i++) {
                if (data[i].id == sala) {
                  this.tiempoHome = formatSeconds(data[i].tiempoUso);
                  this.categoriaList.push(data[i]);
                } else {
                  if (data[i].id == salasDocentes) {
                    this.tiempoHome = formatSeconds(data[i].tiempoUso);
                  this.categoriaList.push(data[i]);
                  }
                }
                  this.divSize = this.categoriaList.length;
              }
            } else {
              if (rolPiso == 'ADMINISTRATIVO') {
                for (let i = 0; i < data.length; i++) {
                  if (data[i].id == sala) {
                    this.tiempoHome = formatSeconds(data[i].tiempoUso);
                    this.categoriaList.push(data[i]);
                  }

                  this.divSize = this.categoriaList.length;

                }
              } else {
                for (let i = 0; i < data.length; i++) {
                  if (
                    data[i].id != prestamoLibro &&
                    data[i].id != devolucionLibro
                  ) {
                    this.tiempoHome = formatSeconds(data[i].tiempoUso);
                    this.categoriaList.push(data[i]);
                    this.divSize = this.categoriaList.length;
                  }
                }
              }
            }
          }
        });
      });
    });
  } */

  // envio de mail de reserva generada
  enviarMail() {
    this.reservaService
      .sendMail(
        '' + this.reservas_save.emailReservador + '',
        '' + this.reservas_save.namesReservador + '',
        '' + this.reservas_save.materialReservado + '',
        '' + this.reservas_save.fechaReservaInicio + '',
        '' + this.reservas_save.fechaReservaFin + ''
      )
      .subscribe(
        (data) => {},
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.loadingScreens.nativeElement.style.display = 'block';
          this.showSendMail();
          location.reload();
        }
      );
  }

  // envio de mail de reserva eliminada
  enviarMailError(
    materialReservado: String,
    fechaInicio: String,
    fechaFin: String
  ) {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.reservaService
        .sendMailError(
          '' + this.profile.userPrincipalName + '',
          '' + this.profile.givenName + ' ' + this.profile.surname + '',
          '' + materialReservado + '',
          '' + fechaInicio + '',
          '' + fechaFin + ''
        )
        .subscribe(
          (data) => {},
          (err) => {
            // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
            this.showSendMailError();
            location.reload();
          }
        );
    });
  }

  // agregar registro
  guardar(reserva: ReservaSave) {
    this.showEventInfoOk();
    //this.loadingScreen.nativeElement.style.display = 'block';
    this.reservaService.postReserva(reserva).subscribe(
      (data) => {
        this.showSuccess();
        //this.enviarMail();
        this.showSendMail();
        Swal.close();
        //this.loadingScreen.nativeElement.style.display = 'none';
        //location.reload();
        this.router.navigate(['listarReservas']);
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoError();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // cargar el resto de datos
  desactivarInpunt() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.idRecurso;
    if (dataSelectors != 'Seleccione') {
      this.edited3 = true;
      this.recursoService
        .getRecursoId('' + this.reservas_save.idRecurso + '')
        .subscribe((data) => {
          this.reservas_save.materialReservado = data.nombreRecurso;
          this.minutosUso = data.categoria.tiempoUso;
          this.capacidadUso = data.capacidad;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.categoria.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }

  //eventos para mostrar div de información del recurso
  showInfo() {
    this.showMore = false;
    this.editedInfo = true;
    this.showLess = true;
  }
  showInfoLess() {
    this.showMore = true;
    this.editedInfo = false;
    this.showLess = false;
  }

  //evento para cargar las categorias
  PisoChanged() {
    this.edited = true;
  }

  //evento para cargar los recursos
  dataChanged(event: any) {
    this.edited2 = true;
    this.edited3_1 = false;
    this.getRecurso();
  }
  dataChanged2(event: any) {
    this.edited3_1 = false;
    this.getRecurso2();
  }

  // cargar datos del usuario al formulario de reserva
  cargarUser() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.reservas_save.namesReservador =
        this.profile.givenName + ' ' + this.profile.surname;
      this.reservas_save.emailReservador = this.profile.userPrincipalName;
    });
  }

  // para listar todos los recursos
  getRecurso() {
    var pisos;
    pisos = this.reservas_save.pisoReserva;
    var categorias = this.reservas_save.categoriaReserva;

    this.recursoService
      .getRecursoSelect('' + pisos + '', '' + categorias + '')
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.showRecursos();
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.minutosUso = data[i].categoria.tiempoUso;
              this.minutosEspera = data[i].categoria.tiempoEspera;
            }
          } else {
            this.recursoList = data;
            this.edited3 = false;
            this.showNoRecursosV2();
            this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_save.fechaReservaFin = '';
          }
        },
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.showNoRecursosV2();
        }
      );
  }
  getRecurso2() {
    let pisos;
    let categorias;
    if (this.reservas_save.pisoReserva == undefined) {
      pisos = 0;
    } else {
      pisos = this.reservas_save.pisoReserva;
    }

    if (this.reservas_save.categoriaReserva == undefined) {
      categorias = 0;
    } else {
      categorias = this.reservas_save.categoriaReserva;
    }

    this.recursoService
      .getRecursoSelect('' + pisos + '', '' + categorias + '')
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.showRecursos();
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.minutosUso = data[i].categoria.tiempoUso;
              this.minutosEspera = data[i].categoria.tiempoEspera;
            }
          } else {
            this.recursoList = data;
            this.showNoRecursosV2();
            this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_save.fechaReservaFin = '';
          }
        },
        (err) => {}
      );
  }

  // para listar todos los pisos de biblioteca utpl
  getPisos() {
    this.pisosService.getPisos().subscribe((data) => {
      this.pisosList = data;
    });
  }

  // para listar todos las categorias de uso
  getCategorias() {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.cateList = data;
    });
  }

  //vento para el changeDatepiker
  changeDatepikers(event: any) {
    //var fechaInicio = this.fechaRsrv.nativeElement.value.replace(' ', '');
    //console.log(this.getFechaActual());
    if (this.datepickers != undefined) {
      var datepicker = this.datepickers;

      var fechaSelect = '';
      var month = '';
      var day = '';
      if (datepicker.month < 10) {
        month = '0' + datepicker.month;
      } else {
        month = datepicker.month;
      }
      if (datepicker.day < 10) {
        day = '0' + datepicker.day;
      } else {
        day = datepicker.day;
      }

      // calculando fecha seleccionado
      fechaSelect = datepicker.year + '-' + month + '-' + day;
      const dateSelect = new Date(
        datepicker.year + '-' + month + '-' + day + 'T08:00:00'
      );

      // dias de no atencia de la biblioteca
      var diasNo = '';
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          diasNo = data[0].diasAtencion;
        }

        if (diasNo.includes(this.getDay(dateSelect))) {
          this.showErrorNoDia();
          this.edited3_1 = false;
        } else {
          if (fechaSelect < this.getFechaActual()) {
            this.showErrorDate();
            this.edited3_1 = false;
          } else {
            this.getFechaActualSelect(fechaSelect);
            this.edited3_1 = true;
          }
        }
      });
    } else {
      this.edited3_1 = false;
      this.showErrorVacio();
    }
  }

  //evento para cargar las horas disponibles
  getFechaActualSelect(fechaSelect: String) {
    if (this.minutosUso != 0) {
      var minutos = this.minutosUso + this.minutosEspera;
      var horaInicioAT = '';
      var horaFinAT = '';
      // obteniendo los datos datos del servicio donde esta el horario de atención
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          horaInicioAT = data[0].horaInicio;
          horaFinAT = data[0].horaFin;
        }

        //calcular el tiempo de uso del recurso
        var start = moment(horaInicioAT, 'H:mm:ss');
        var end = moment(horaFinAT, 'H:mm:ss');
        var rangeArray = calculateRange(start, end, 60);
        var result = rangeArray.map(function (hour) {
          return hour.format('HH:mm:ss');
        });
        var resultsV2 = [];
        resultsV2 = result.join(',').split(',');

        // crear el areglo de tiempo de uso del recurso con el tiempo de espero.
        var currentValue = this.getFechaActual();
        var fechas = currentValue;
        this.fechaActual = fechas;
        var fechaHoraActual = this.getFechaActualAndHora();
        var fechaHoraActualStr = fechaHoraActual.split(' ');
        this.fechaListV2 = [];

        // for para añadir los valores de la hora
        for (let j = 0; j < resultsV2.length - 1; j++) {
          var constList;
          if (fechaSelect == this.fechaActual) {
            if (resultsV2[j] > fechaHoraActualStr[1]) {
              constList = {
                fechaId: j + 1,
                fechaActual: fechaSelect,
                horaInicio: resultsV2[j],
                horaFin: resultsV2[j + 1],
              };
              this.fechaListV2.push(constList);
            }
          } else {
            constList = {
              fechaId: j + 1,
              fechaActual: fechaSelect,
              horaInicio: resultsV2[j],
              horaFin: resultsV2[j + 1],
            };
            this.fechaListV2.push(constList);
          }
        }
        //this.reservas_save.fechaReservaInicio = '';
        this.reservas_save.fechaReservaFin = '';
      });
    } else {
      this.fechaListV2 = [];
    }
    //console.log(this.fechaListV2);
  }
  getFechaActualEdit(fechaSelect: String) {
    var fechaSelectSalida = fechaSelect.split(' ');

    var horaInicioAT = '';
    var horaFinAT = '';
    // obteniendo los datos datos del servicio donde esta el horario de atención
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        horaInicioAT = data[0].horaInicio;
        horaFinAT = data[0].horaFin;
      }

      if (this.minutosUso != 0) {
        var minutos = this.minutosUso + this.minutosEspera;
        //calcular el tiempo de uso del recurso
        var start = moment(horaInicioAT, 'H:mm:ss');
        var end = moment(horaFinAT, 'H:mm:ss');
        var rangeArray = calculateRange(start, end, minutos);
        var result = rangeArray.map(function (hour) {
          return hour.format('HH:mm:ss');
        });
        var resultsV2 = [];
        resultsV2 = result.join(',').split(',');

        // crear el areglo de tiempo de uso del recurso con el tiempo de espero.
        var currentValue = this.getFechaActual();
        var fechas = currentValue;
        this.fechaActual = fechas;
        var fechaHoraActual = this.getFechaActualAndHora();
        var fechaHoraActualStr = fechaHoraActual.split(' ');
        this.fechaListV2 = [];

        // for para añadir los valores de la hora
        for (let j = 0; j < resultsV2.length - 1; j++) {
          var constList;

          constList = {
            fechaId: j + 1,
            fechaActual: fechaSelectSalida[0],
            horaInicio: resultsV2[j],
            horaFin: resultsV2[j + 1],
          };
          //console.log(constList);
          this.fechaListV2.push(constList);
        }
        //this.reservas_save.fechaReservaInicio = '';
        //this.reservas_save.fechaReservaFin = '';
      } else {
        this.fechaListV2 = [];
      }
      //console.log("editar"+this.fechaListV2);
    });
  }

  // Version 2 - evento para cargar los recursos
  dataChangedv2(event: any) {
    this.edited2 = true;
    this.edited3 = false;
  }
  // Version 2 - vento para el changeDatepiker
  changeDatepikersV2(event: any) {
    //var fechaInicio = this.fechaRsrv.nativeElement.value.replace(' ', '');
    //console.log(this.getFechaActual());

    this.edited3 = false;
    if (this.datepickers != undefined) {
      var datepicker = this.datepickers;

      var fechaSelect = '';
      var month = '';
      var day = '';
      if (datepicker.month < 10) {
        month = '0' + datepicker.month;
      } else {
        month = datepicker.month;
      }
      if (datepicker.day < 10) {
        day = '0' + datepicker.day;
      } else {
        day = datepicker.day;
      }

      // calculando fecha seleccionado
      fechaSelect = datepicker.year + '-' + month + '-' + day;
      const dateSelect = new Date(
        datepicker.year + '-' + month + '-' + day + 'T08:00:00'
      );

      // dias de no atencia de la biblioteca
      var diasNo = '';
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          diasNo = data[0].diasAtencion;
        }

        if (diasNo.includes(this.getDay(dateSelect))) {
          this.showErrorNoDia();
          this.edited3_1 = false;
          this.edited3 = false;
        } else {
          if (fechaSelect < this.getFechaActual()) {
            this.showErrorDate();
            this.edited3_1 = false;
            this.edited3 = false;
          } else {
            this.getFechaActualSelect(fechaSelect);
            this.edited3_1 = true;
          }
        }
      });
    } else {
      this.edited3_1 = false;
      this.showErrorVacio();
    }
  }
  // Version 2 - evento para cargar el input de salida de la fecha de fin
  changeFechaV2(event: any) {
    // obteniendo la fecha de inicio para calcular el tiempo de uso del recurso
    if (this.reservas_save.fechaReservaInicio != 'Seleccione') {
      var fechaInicioChanges = this.reservas_save.fechaReservaInicio.replace(
        ' ',
        'T'
      );
      var fechaSelectSalida = this.reservas_save.fechaReservaInicio.split(' ');

      //add minutos a la hora final
      var fechaI2 = new Date(fechaInicioChanges);
      var minutoSumar = this.minutosUso;
      var fechaSalist = '';
      var getHora;
      var getMin;

      // calculando hora de salida
      if (minutoSumar != 0) {
        fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);
        if (fechaI2.getHours() < 10) {
          getHora = '0' + fechaI2.getHours();
        } else {
          getHora = fechaI2.getHours();
        }

        if (fechaI2.getMinutes() < 10) {
          getMin = '0' + fechaI2.getMinutes();
        } else {
          getMin = fechaI2.getMinutes();
        }
        fechaSalist = getHora + ':' + getMin + ':' + fechaI2.getSeconds() + '0';
      }

      this.reservas_save.fechaReservaFin =
        fechaSelectSalida[0] + ' ' + fechaSalist;
      this.edited3 = true;
      this.RecursosV2();
    } else {
      this.reservas_save.fechaReservaFin = '';
    }
  }
  // Version 2 - cargar el resto de datos
  desactivarInpuntV2() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + this.reservas_save.categoriaReserva + '')
        .subscribe((data) => {
          //this.reservas_save.materialReservado = data.nombreCategoria;
          this.minutosUso = data.tiempoUso;
          this.minutosEspera = data.tiempoEspera;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }
  // Version 2 - cargar los recursos
  RecursosV2() {
    var pisos = this.reservas_save.pisoReserva;
    var categorias = this.reservas_save.categoriaReserva;
    var fechaInicio = this.reservas_save.fechaReservaInicio;
    var fechaFin = this.reservas_save.fechaReservaFin;

    this.recursoService
      .getRecursoSelectv3(
        '' + pisos + '',
        '' + categorias + '',
        '' + fechaInicio + '',
        '' + fechaFin + ''
      )
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.edited3 = true;
            this.showRecursos();
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.capacidadUso = data[i].capacidad;
            }
          } else {
            this.recursoList = data;
            this.edited3 = false;
            this.showNoRecursosV2();
          }
        },
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.edited3 = false;
          this.showNoRecursosV2();
        }
      );
  }
  changeRecursoSelectSaveV2() {
    var idRecurso = this.reservas_save.idRecurso;

    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoId('' + idRecurso + '')
        .subscribe((data) => {
          this.reservas_save.materialReservado = data.nombreRecurso;
        });
    } else {
      this.edited3 = false;
    }
  }

  //evento para cargar el input de salida de la fecha de fin
  changeFecha(event: any) {
    // obteniendo la fecha de inicio para calcular el tiempo de uso del recurso
    if (this.reservas_save.fechaReservaInicio != 'Seleccione') {
      var fechaInicioChanges = this.reservas_save.fechaReservaInicio.replace(
        ' ',
        'T'
      );
      var fechaSelectSalida = this.reservas_save.fechaReservaInicio.split(' ');

      //add minutos a la hora final
      var fechaI2 = new Date(fechaInicioChanges);
      var minutoSumar = this.minutosUso;
      var fechaSalist = '';
      var getHora;
      var getMin;

      // calculando hora de salida
      if (minutoSumar != 0) {
        fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);
        if (fechaI2.getHours() < 10) {
          getHora = '0' + fechaI2.getHours();
        } else {
          getHora = fechaI2.getHours();
        }

        if (fechaI2.getMinutes() < 10) {
          getMin = '0' + fechaI2.getMinutes();
        } else {
          getMin = fechaI2.getMinutes();
        }
        fechaSalist = getHora + ':' + getMin + ':' + fechaI2.getSeconds() + '0';
      }

      if (fechaSalist == '22:00:00') {
        this.reservas_save.fechaReservaFin =
          fechaSelectSalida[0] + ' ' + '21:00:00';
      } else {
        this.reservas_save.fechaReservaFin =
          fechaSelectSalida[0] + ' ' + fechaSalist;
      }
    } else {
      this.reservas_save.fechaReservaFin = '';
    }
  }

  temporizador() {
    let date = new Date('2021-11-12');
    //this.timerId = countdown(date, (ts)=> {})
  }

  //fechas
  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaActualAndHora() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var hora = hoy.getHours();
    var min = hoy.getMinutes();
    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var mint = '';
    var horas = '';

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    if (hora < 10) {
      horas = '0' + hora;
    } else {
      horas = '' + hora;
    }
    if (min < 10) {
      mint = '0' + min;
    } else {
      mint = '' + min;
    }

    fechaActual =
      hoy.getFullYear() +
      '-' +
      months +
      '-' +
      diaSL +
      ' ' +
      horas +
      ':' +
      mint +
      ':' +
      hoy.getSeconds();
    return fechaActual;
  }

  getFechaManana() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();
    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }
  getDay(dateSelect: Date) {
    const fechaComoCadena = dateSelect; // día lunes
    const dias = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia;
    console.log('Nombre de día de la semana: ', nombreDia);
  }

  // control de la ventana emergente
  openLg2(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLg(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLgHome() {
    this.modalService.open(this.contentModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  //eventos para mostrar el estado de la acción de información del recurso
  showEventInfoOk() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espero un momento',
      text: 'Estamos validando su reserva y una vez terminada le llegará un correo de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoError() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de reservas que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorSave() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorAforo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Aforo completo para el horario que seleccionó.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorNoAccess() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }

  //alertas
  showSuccess() {
    this.toastService.show('💾 Reserva añadido con éxito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSuccesUpdate() {
    this.toastService.show('💾 Reserva modificada con éxito...!!!', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMail() {
    this.toastService.show('📤  Reserva creada correctamente', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSendMailUpdate() {
    this.toastService.show('📤  Reserva modificada correctamente', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMailError() {
    this.toastService.show('📢 Reserva eliminada correctamente', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  showRecursos() {
    this.toastService.show('📢 Recursos de biblioteca cargados correctamente', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showNoRecursosV2() {
    this.toastService.show(
      '📢  Todos los recursos han sido usados para la hora seleccionada.',
      {
        classname: 'bg-warning',
        delay: 4000,
      }
    );
  }
  showNoRecursos() {
    this.toastService.show(
      '📢  No existe ningún recurso relacionado con su búsqueda',
      {
        classname: 'bg-danger text-light',
        delay: 3000,
      }
    );
  }
  showAlertTiempoUso() {
    this.toastService.show('📢 Recuerda: Las reserva se realizan para el día', {
      classname: 'bg-info',
      delay: 8000,
    });
  }
  showError() {
    this.toastService.show(
      '⚠ Error al cargar el recurso. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorDate() {
    this.toastService.show(
      '⚠ Error: La fecha seleccionada no puede ser menor a la actual',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorSave() {
    this.toastService.show(
      '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoAccess() {
    this.controlService.getControllers().subscribe((data) => {
      this.toastService.show(
        '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }
  showErrorNoDia() {
    var diasNo = '';
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        diasNo = data[0].diasAtencion;
      }
      this.toastService.show(
        '⚠ Error: No se puede reservar para le fecha seleccionado | ' +
          diasNo +
          '.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }

  showErrorNoHora() {
    this.toastService.show(
      '⚠ Error: Reservas finalizadas por hoy. Si desea realizar una reserva para mañana.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorEx() {
    this.toastService.show(
      '⚠ Error: Solo puede realizar una reserva por día.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorVacio() {
    this.toastService.show('⚠ Error: Esta vacío la seleccionado', {
      classname: 'bg-warning',
      delay: 4000,
    });
  }
  showErrorAforo() {
    this.toastService.show(
      '⚠ Error: Aforo completo para el horario que seleccionó.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  openInfoAlert() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Del 19 de agosto al 09 de septiembre del 2022, la Biblioteca UTPL por remodelación, atenderá a través del servicio de Teleasistencia.',
      footer:
        '<center> Horario de atención: <br>08:00 a 13:00  <br>15:00 a 18:00 <br> Agradecemos su comprensión. </center>',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 18000,
      timerProgressBar: false,
      showConfirmButton: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }

  openInfoAlert2() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Se comunica que el día de hoy viernes el horario de atención será de 08:00 a 11:00 am. El día lunes retomamos en nuestro horario habitual.',
      footer: 'Agradecemos su comprensión.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 8000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
}
function calculateRange(start: any, end: any, minutes: any) {
  var range = [];
  for (
    var hour = moment(start);
    hour.isBefore(end);
    hour.add(minutes, 'minutes')
  ) {
    range.push(moment(hour));
  }
  range.push(moment(end));
  return range;
}

function formatSeconds(tiempoHome: number) {
  var totalMinutes = tiempoHome;

  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  var hourstr = '';
  var minutestr = '';
  if (hours < 10) {
    hourstr = '0' + hours;
  } else {
    hourstr = '' + hours;
  }
  if (minutes < 10) {
    minutestr = '0' + minutes;
  } else {
    minutestr = '' + minutes;
  }

  return hourstr + ':' + minutestr;
}
