<!--
<nav class="navbar colorNav">
  <div class="container-fluid">
    <div class="example-icon" aria-label="Example icon-button with menu icon">
      <a class="btn text-light" data-bs-toggle="offcanvas" href="#offMenu" role="button" aria-controls="offcanvasExample">
        <i class="bi bi-menu-button-wide-fill"></i> Menú
      </a>
    </div>

    <span class="example-spacer"></span>
    <span>Reservas Biblioteca / {{profile?.givenName}}</span>
  </div>
</nav>
<div class="offcanvas offcanvas-start" tabindex="-1" id="offMenu" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <div>
      <strong>Menú</strong>
    </div>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>

      Bienvenido 😀, <strong><i> {{profile?.givenName}}</i></strong> <br>
      <hr>
      Seleccione el campo a redireccionar
      <ul style="list-style:none">

        <li><a class="dropdown-item" href="inicio"><i class="bi bi-house-fill"></i> Inicio</a></li>
        <li><a class="dropdown-item" href="listarReservas"><i class="bi bi bi-list-check"></i> Reservas</a></li>

        <li *ngFor="let m of menus">
          <a class="dropdown-item" href="{{m.url}}"><i class="bi {{m.nombre}}"></i> {{m.icono}}</a>
        </li>

        <li *ngIf="menus?.length == 0"><a class="dropdown-item" href="inicio"><i class="bi bi-exclamation-diamond"></i> No tiene Permisos</a></li>

        <li><a class="dropdown-item navbar-light" (click)="logout()"> <i class="bi bi-box-arrow-right"></i> Salir</a></li>
        <br>
        <li><a class="dropdown-item navbar-light" href="#" download data-bs-toggle="tooltip" data-bs-placement="bottom" title="clic para visualizar el manual de usuario" target="_blank"> <i class="bi bi-question-octagon-fill"></i> Ayuda</a></li>
      </ul>
    </div>

  </div>
</div> -->

<app-navbar></app-navbar>

<!--
<section class="page-section portfolio" *ngIf="roles == 'ADMINISTRADOR'">
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
        aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="https://biblioteca.utpl.edu.ec/sites/default/files/011.jpg" class="d-block w-100" alt="...">

      </div>
      <div class="carousel-item">
        <img src="https://biblioteca.utpl.edu.ec/sites/default/files/thumbnail.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="https://biblioteca.utpl.edu.ec/sites/default/files/011.jpg" class="d-block w-100" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</section> -->


<section class="m-3"
  *ngIf="roles === 'BIBLIOTECARIO' || roles === 'DOCENTE' || roles === 'ESTUDIANTE' || roles === 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR'  || roles == 'ADMIN-BBC'  || roles == 'AUTORIDADES'  || roles == 'EVENTOS'  || roles == 'EVENTOS_ADMIN'">
  <div class="card colorCard">
    <div class="card-body text-center titlePage">
      <strong>Categorías de reserva | Biblioteca UTPL</strong>
    </div>
  </div>
  <br>
  <div class="row row-cols-1 row-cols-md-{{divSize}} g-1" *ngIf="divSize != 1 ">
    <div class="col" *ngFor="let categoria of categoriaList">
      <div class="card">
        <img src="{{categoria.rutaImg}}" class="card-img-top h-25" alt="{{categoria.rutaImg}}">
        <div class="card-body">
          <div class="card-title p-2 rounded" (click)="cargarUser()" (click)="editar(categoria)"
            (click)="openLg(FormularioCreate)"
            *ngIf="roles == 'ESTUDIANTE' || roles == 'DOCENTE' || roles == 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR'  || roles == 'AUTORIDADES'  || roles == 'EVENTOS'  || roles == 'EVENTOS_ADMIN'"
            style="background-color: #eaab00; color:#003e71;">
            <strong> {{categoria.nombreCategoria}}</strong> <i class="bi bi-hand-index-thumb-fill float-end"
              placement="bottom" ngbTooltip="Click para crear una reserva"></i>
          </div>
          <h2 class="card-title" *ngIf="roles == 'null' || roles == 'BIBLIOTECARIO'  || roles == 'ADMIN-BBC'">
            <strong>{{categoria.nombreCategoria}}</strong>
          </h2>
          <div class=" card-text">
            <div class=" mb-3">
              <label class="form-label"><strong>Descripción:</strong></label> <br>
              <label class="form-label">{{categoria.descripcion}}</label>
            </div>
            <div class=" mb-3">
              <label class="form-label"><strong>Ubicación:</strong></label> <br>
              <label class="form-label">{{categoria.ubicacion}}</label>
            </div>
            <div class=" mb-3">
              <label class="form-label"><strong>Estado de Uso:</strong></label> <br>
              <label class="form-label " *ngIf="categoria.enable===true"><i
                  class="bi bi-check-lg fs-4 mb-3 ColorBien"></i> Habilitado</label>
              <label class="form-label " *ngIf="categoria.enable===false"><i
                  class="bi bi-patch-exclamation-fill fs-4 mb-3 ColorMal"></i> Deshabilitado</label>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <small class="text-muted">Tiempo uso <Strong>{{tiempoHome}}</Strong> horas | Tiempo espera
            <Strong>{{categoria.tiempoEspera}}</Strong> minutos</small>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3 " *ngIf="divSize == 1 ">
    <ng-container *ngFor="let categoria of categoriaList">
      <div class="row g-0">
        <div class="col-md-4">
          <img src="{{categoria.rutaImg}}" class="img-fluid rounded" alt="{{categoria.rutaImg}}">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <div class="card-title p-2 rounded" (click)="cargarUser()" (click)="editar(categoria)"
              (click)="openLg(FormularioCreate)"
              *ngIf="roles == 'ESTUDIANTE' || roles == 'DOCENTE' || roles == 'ADMINISTRATIVO' || roles == 'ADMINISTRADOR'  || roles == 'AUTORIDADES'"
              style="background-color: #eaab00; color:#003e71;">
              <strong> {{categoria.nombreCategoria}}</strong> <i class="bi bi-hand-index-thumb-fill float-end"
                placement="bottom" ngbTooltip="Click para crear una reserva"></i>
            </div>
            <h2 class="card-title" *ngIf="roles == 'null' || roles == 'BIBLIOTECARIO'  || roles == 'ADMIN-BBC'">
              <strong>{{categoria.nombreCategoria}}</strong>
            </h2>
            <div class=" card-text">
              <div class=" mb-3">
                <label class="form-label"><strong>Descripción:</strong></label> <br>
                <label class="form-label">{{categoria.descripcion}}</label>
              </div>
              <div class=" mb-3">
                <label class="form-label"><strong>Ubicación:</strong></label> <br>
                <label class="form-label">{{categoria.ubicacion}}</label>
              </div>
              <div class=" mb-3">
                <label class="form-label"><strong>Estado de Uso:</strong></label> <br>
                <label class="form-label " *ngIf="categoria.enable===true"><i
                    class="bi bi-check-lg fs-4 mb-3 ColorBien"></i> Habilitado</label>
                <label class="form-label " *ngIf="categoria.enable===false"><i
                    class="bi bi-patch-exclamation-fill fs-4 mb-3 ColorMal"></i> Deshabilitado</label>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card-footer">
        <small class="text-muted">Tiempo uso <Strong>{{tiempoHome}}</Strong> horas | Tiempo espera
          <Strong>{{categoria.tiempoEspera}}</Strong> minutos</small>
      </div>
    </ng-container>
  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

  <!-- formulario para crear una reserva -->
  <ng-template #content let-modal>
    <div class="modal-header ">
      <h4 class="modal-title">Formulario de Reserva Biblioteca</h4>
      <button type="button" class="btn" (click)="modal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form action="" method="post" enctype="multipart/form-data">
        <mat-card class="colorCard text-center">
          <h3><i><b>Crear reserva para recurso de Biblioteca</b></i></h3>
        </mat-card>
        <div class="row m-2">

          <!-- Datos per.-->
          <div class="col-md-12 p-2" style="display: none;">
            <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
                style="color: red;"><strong>*</strong></i></label>
            <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs"
              #nombresApellidosEs [(ngModel)]="reservas_save.namesReservador" disabled>
          </div>
          <div class="col-md-12 p-2" style="display: none;">
            <label for="emailReservador" class="form-label">Email institucional<i
                style="color: red;"><strong>*</strong></i></label>
            <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
              [(ngModel)]="reservas_save.emailReservador" disabled>
          </div>

          <!-- Categorías-->
          <div class="col-md-12 p-2">
            <label for="categoriaReserva" class="form-label">Categorías<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="categoriaReserva"
              id="categoriaReserva" [(ngModel)]="reservas_save.categoriaReserva" (change)="PisoChanged()"
              (change)="dataChanged2($event)" disabled>
              <option *ngFor="let cate of cateList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
            </select>
          </div>

          <!-- Piso-->
          <div class="col-md-12 p-2">
            <label for="pisoReserva" class="form-label">Piso<i style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="pisoReserva" id="pisoReserva"
              [(ngModel)]="reservas_save.pisoReserva" (change)="dataChanged($event)">
              <option *ngFor="let pisos of pisosListado" value="{{pisos.id}}">{{pisos.nombrePiso}}</option>
            </select>
          </div>

          <!-- Recurso de Biblioteca-->
          <div class="col-md-12 p-2" *ngIf="edited2">
            <label for="idRecurso" class="form-label">Recurso de Biblioteca<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="idRecurso" id="idRecurso"
              [(ngModel)]="reservas_save.idRecurso" (change)="desactivarInpunt()">
              <option selected>Seleccione</option>
              <option *ngFor="let bsc of recursoList" value="{{bsc.id}}">{{bsc.nombreRecurso}}</option>
            </select>
          </div>

          <!-- Fecha de la reserva-->
          <div class="col-md-12 p-2" *ngIf="edited3">
            <label for="idRecurso" class="form-label">Fecha de la reserva<i
                style="color: red;"><strong>*</strong></i></label>
            <div class="row">
              <div class="col-md-12 col">
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" type="text" name="dp" id="fechaRsrv"
                    ngbDatepicker #d="ngbDatepicker" [(ngModel)]="datepickers" #fechaRsrv (click)="d.toggle()"
                    (dateSelect)="changeDatepikers($event)">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                      (dateSelect)="changeDatepikers($event)" type="button"><i class="bi bi-calendar3"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- fecha Reserva Inicio y Fin-->
          <div class="col-md-12 p-2 row" *ngIf="edited3_1">
            <div class="col-lg-6 col">
              <label for="fechaReservaInicio" class="form-label">fecha Reserva Inicio<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
                id="fechaReservaInicio" [(ngModel)]="reservas_save.fechaReservaInicio" (change)="changeFecha($event)">
                <option selected>Seleccione</option>
                <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                  {{fechals.fechaActual}} {{fechals.horaInicio}}</option>

              </select>
            </div>
            <div class="col-lg-6 col">
              <label for="fechaReservaFin" class="form-label">fecha Reserva Fin<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="fechaReservaFin"
                id="fechaReservaFin" [(ngModel)]="reservas_save.fechaReservaFin" disabled>
                <option value="{{reservas_save.fechaReservaFin}}">{{reservas_save.fechaReservaFin}}</option>
              </select>

            </div>
          </div>

          <!-- Información adicional-->
          <div class="col-md-12 p-2" *ngIf="edited3_1">
            <hr class="container col-11">
            <div class="container p-2">
              <div class="row justify-content-center">
                <div class="col-6" style="text-align: center;vertical-align: middle;">
                  <i><b>Información de Ubicación del recurso seleccionado.</b></i>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-info" (click)="showInfo()" *ngIf="showMore"><i
                      class="bi bi-chevron-double-down"></i> Ver más</button>
                  <button type="button" class="btn btn-danger" (click)="showInfoLess()" *ngIf="showLess"><i
                      class="bi bi-chevron-double-up"></i> Ver menos</button>
                </div>
              </div>
            </div>

            <div class="container col-md-11" *ngIf="editedInfo">
              <hr class="container col-11">
              <div class="col-md-12 p-2" style="display: none;">
                <label for="materialReservado" class="form-label">Nombre<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="materialReservado" name="materialReservado"
                  [(ngModel)]="reservas_save.materialReservado" disabled>
              </div>
              <div class="col-md-12 mb-2">
                <label for="capacidadUso" class="form-label"> Capacidad de uso del recurso
                  <i style="color: red;"><strong>*</strong></i></label>
                <div class="input-group mb-3">
                  <input type="number" class="form-control" aria-label="capacidadUso" id="capacidadUso"
                    name="capacidadUso" aria-describedby="basic-addon2" [(ngModel)]="capacidadUso" disabled>
                  <span class="input-group-text" id="basic-addon2">Personas</span>
                </div>
              </div>
              <div class="col-md-12 p-2">
                <label for="infoDescripcion" class="form-label">Descripción<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="infoDescripcion" name="infoDescripcion"
                  [(ngModel)]="infoDescripcion" disabled>
              </div>
              <div class="col-md-12 p-2">
                <label for="infoUbicacion" class="form-label">Ubicación<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="infoUbicacion" name="infoUbicacion"
                  [(ngModel)]="infoUbicacion" disabled>
              </div>

              <div class="col-md-12 p-2">
                <label for="ubicacion" class="form-label">Imagen de referenia
                  <i style="color: red;"><strong>*</strong></i></label>
                <div class="container col-md-6 ">
                  <img src="{{imgRuta}}" class="img-fluid rounded" alt="{{imgRuta}}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-md-6 col">
          <button type="submit" [disabled]="!reservas_save.fechaReservaFin" (click)="guardar(reservas_save)"
            (click)="modal.close('Cross click')" class="btn btn-primary">Guardar
          </button>
        </div>
        <div class="col-md-6 col">
          <button type="button" class="btn btn-danger" (click)="modal.close('Cross click')">Cancelar</button>

        </div>
      </div>
    </div>
  </ng-template>

  <!-- formulario V2 para crear una reserva -->
  <ng-template #FormularioCreate let-c="close" let-d="dismiss">
    <div class="modal-header ">
      <h4 class="modal-title">Formulario de Reserva Biblioteca</h4>
      <button type="button" class="btn" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form action="" method="post" enctype="multipart/form-data">
        <mat-card class="colorCard text-center">
          <h3><b>Crear reserva para recurso de Biblioteca</b></h3>
        </mat-card>
        <div class="row m-2">

          <!-- Datos per.-->
          <div class="col-md-12 p-2" style="display: none;">
            <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
                style="color: red;"><strong>*</strong></i></label>
            <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs"
              #nombresApellidosEs [(ngModel)]="reservas_save.namesReservador" disabled>
          </div>
          <div class="col-md-12 p-2" style="display: none;">
            <label for="emailReservador" class="form-label">Email institucional<i
                style="color: red;"><strong>*</strong></i></label>
            <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
              [(ngModel)]="reservas_save.emailReservador" disabled>
          </div>

          <!-- Categorías-->
          <div class="col-md-12 p-2">
            <label for="categoriaReserva" class="form-label">Categorías<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="categoriaReserva"
              id="categoriaReserva" [(ngModel)]="reservas_save.categoriaReserva" (change)="PisoChanged()" disabled>
              <option *ngFor="let cate of cateList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
            </select>
          </div>

          <!-- Piso-->
          <div class="col-md-12 p-2">
            <label for="pisoReserva" class="form-label">Piso<i style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="pisoReserva" id="pisoReserva"
              [(ngModel)]="reservas_save.pisoReserva" (change)="dataChangedv2($event)" (change)="desactivarInpuntV2()">
              <option *ngFor="let pisos of pisosListado" value="{{pisos.id}}">{{pisos.nombrePiso}}</option>
            </select>
          </div>

          <!-- Fecha de la reserva-->
          <div class="col-md-12 p-2" *ngIf="edited2">
            <label for="idRecurso" class="form-label">Fecha de la reserva<i
                style="color: red;"><strong>*</strong></i></label>
            <div class="row">
              <div class="col-md-12 col">
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" type="text" name="dp" id="fechaRsrv"
                    ngbDatepicker #d="ngbDatepicker" [(ngModel)]="datepickers" #fechaRsrv (click)="d.toggle()"
                    (dateSelect)="changeDatepikersV2($event)">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                      (dateSelect)="changeDatepikersV2($event)" type="button"><i class="bi bi-calendar3"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- fecha Reserva Inicio y Fin-->
          <div class="col-md-12 p-2 row" *ngIf="edited3_1">
            <div class="col-lg-6 col">
              <label for="fechaReservaInicio" class="form-label">fecha Reserva Inicio<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
                id="fechaReservaInicio" [(ngModel)]="reservas_save.fechaReservaInicio" (change)="changeFechaV2($event)">
                <option selected>Seleccione</option>
                <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                  {{fechals.fechaActual}} {{fechals.horaInicio}}</option>

              </select>
            </div>
            <div class="col-lg-6 col">
              <label for="fechaReservaFin" class="form-label">fecha Reserva Fin<i
                  style="color: red;"><strong>*</strong></i></label>
              <select class="form-select" aria-label="Default select example" name="fechaReservaFin"
                id="fechaReservaFin" [(ngModel)]="reservas_save.fechaReservaFin" disabled>
                <option value="{{reservas_save.fechaReservaFin}}">{{reservas_save.fechaReservaFin}}</option>
              </select>

            </div>
          </div>


          <!-- Recurso de Biblioteca-->
          <div class="col-md-12 p-2" *ngIf="edited3">
            <label for="idRecurso" class="form-label">Recurso de Biblioteca<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="idRecurso" id="idRecurso"
              (change)="changeRecursoSelectSaveV2()" [(ngModel)]="reservas_save.idRecurso">
              <option selected>Seleccione</option>
              <option *ngFor="let bsc of recursoList" value="{{bsc.id}}">{{bsc.nombreRecurso}}</option>
            </select>
          </div>


          <!-- Información adicional-->
          <div class="col-md-12 p-2" *ngIf="edited3">
            <hr class="container col-11">
            <div class="container p-2">
              <div class="row justify-content-center">
                <div class="col-6" style="text-align: center;vertical-align: middle;">
                  <i><b>Información de Ubicación del recurso seleccionado.</b></i>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-info" (click)="showInfo()" *ngIf="showMore"><i
                      class="bi bi-chevron-double-down"></i> Ver más</button>
                  <button type="button" class="btn btn-danger" (click)="showInfoLess()" *ngIf="showLess"><i
                      class="bi bi-chevron-double-up"></i> Ver menos</button>
                </div>
              </div>
            </div>

            <div class="container col-md-11" *ngIf="editedInfo">
              <hr class="container col-11">
              <div class="col-md-12 p-2" style="display: none">
                <label for="materialReservado" class="form-label">Nombre<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="materialReservado" name="materialReservado"
                  [(ngModel)]="reservas_save.materialReservado" disabled>
              </div>
              <div class="col-md-12 mb-2">
                <label for="capacidadUso" class="form-label"> Capacidad de uso del recurso
                  <i style="color: red;"><strong>*</strong></i></label>
                <div class="input-group mb-3">
                  <input type="number" class="form-control" aria-label="capacidadUso" id="capacidadUso"
                    name="capacidadUso" aria-describedby="basic-addon2" [(ngModel)]="capacidadUso" disabled>
                  <span class="input-group-text" id="basic-addon2">Personas</span>
                </div>
              </div>
              <div class="col-md-12 p-2">
                <label for="infoDescripcion" class="form-label">Descripción<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="infoDescripcion" name="infoDescripcion"
                  [(ngModel)]="infoDescripcion" disabled>
              </div>
              <div class="col-md-12 p-2">
                <label for="infoUbicacion" class="form-label">Ubicación<i
                    style="color: red;"><strong>*</strong></i></label>
                <input type="text" class="form-control" id="infoUbicacion" name="infoUbicacion"
                  [(ngModel)]="infoUbicacion" disabled>
              </div>

              <div class="col-md-12 p-2">
                <label for="ubicacion" class="form-label">Imagen de referenia
                  <i style="color: red;"><strong>*</strong></i></label>
                <div class="container col-md-6 ">
                  <img src="{{imgRuta}}" class="img-fluid rounded" alt="{{imgRuta}}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-md-6 col">
          <button type="submit" [disabled]="!reservas_save.idRecurso" (click)="guardar(reservas_save)"
            (click)="d('Cross click')" class="btn btn-primary">Guardar
          </button>
        </div>
        <div class="col-md-6 col">
          <button type="button" class="btn btn-danger" (click)="d('Cross click')">Cancelar</button>

        </div>
      </div>
    </div>
  </ng-template>
</section>


<ng-template let-d="dismiss" #contentModal data-bs-keyboard="false">
  <div class="m-3 textCenter">
    <h2 class="textCenter"> 📢 🛠Atención</h2>
  </div>
  <div class="modal-body">
    <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
  </div>
</ng-template>


<section class="m-2" *ngIf="roles == 'null' || roles == ' '">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<div id="loading-screen" style="display:none; " #loadingScreens>
  <img src="./assets/img/spinning-circles.svg">
</div>
