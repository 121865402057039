import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Aforo } from '../model/Aforo';
import { Controllers } from '../model/controllers';

@Injectable({
  providedIn: 'root',
})
export class ControllersService {
  // URL BASE
  URL_BASE = environment.HOST;
  //URL_BASE = 'http://172.16.80.15:8080/SistemaReservasApp';

  // API PARA OBTENER TODAS LAS CATEGORIAS
  UrlController = this.URL_BASE + '/api/v1/controlsapi';

  // API PARA guardar, editar y eliminar
  UrlSaveDelete = this.URL_BASE + '/api/v1/controlsapi';

  // API para get and put
  UrlAforo = this.URL_BASE + '/api/v1/aforo';
  UrlAforoPeople = this.URL_BASE+'/api/v1/aforoPeople';

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) {}

  // obtener el las categorias
  getControllers() {
    return this.http.get<Controllers[]>(this.UrlController, this.headers);
  }

  getAforo() {
    return this.http.get<Aforo[]>(this.UrlAforo, this.headers);
  }

  getAforoPeople() {
    return this.http.get(this.UrlAforoPeople, this.headers);
  }


  getAforoPeopleBiliotecaId(id: string) {
    return this.http.get(this.UrlAforoPeople+"/"+ id, this.headers);
  }

  getAforoPeopleEventosId(id: string) {
    return this.http.get(this.UrlAforoPeople+"/"+ id, this.headers);
  }

  // save piso
  /* postCategorias(CategoriasSave:Categorias) {
     console.log(CategoriasSave.rutaImg);
    return this.http.post<Categorias>(this.UrlSaveDelete, CategoriasSave);
  } */

  UpadteControllers(control: Controllers) {
    return this.http.put<Controllers>(
      this.UrlSaveDelete + '/' + control.id,
      control, this.headers
    );
  }

  UpadteAforo(control: Aforo) {
    return this.http.put<Aforo>(
      this.UrlAforo + '/' + control.id,
      control, this.headers
    );
  }



  /* deleteEnableRegistro(control:Controllers){
    return this.http.delete(this.UrlSaveDelete+"/"+control.id);
  } */
}
