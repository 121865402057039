import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import {
  Notifications,
  MbscPopup,
  MbscCalendarEvent,
  MbscPopupOptions,
  MbscDatepickerOptions,
  localeEs,
  setOptions,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  momentTimezone,
} from '@mobiscroll/angular';

import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Categorias } from 'src/app/model/Categorias';
import { Menu } from 'src/app/model/Menu';
import { Pisos } from 'src/app/model/Pisos';
import { Recurso } from 'src/app/model/Recurso';
import { Reservas } from 'src/app/model/Reservas';
import { ReservaSave } from 'src/app/model/ReservaSave';
import { CategoriaService } from 'src/app/Service/categoria.service';
import { ControllersService } from 'src/app/Service/controllers.service';
import { PisosService } from 'src/app/Service/pisos.service';
import { RecursoService } from 'src/app/Service/recurso.service';
import { ReservasService } from 'src/app/Service/reservas.service';
import { ServiceService } from 'src/app/Service/service.service';
import { ToastService } from 'src/app/Service/toast.service';
import th from '@mobiscroll/angular/dist/js/i18n/th';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

setOptions({
  locale: localeEs,
  theme: 'ios',
  themeVariant: 'light',
});

const now = new Date();
const mints = now.getMinutes();
const hours = now.getHours();
const today = new Date(now.setMinutes(-1));
const todayString = new Date(now.setMinutes(mints));
const yesterday = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() - 1
);

const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);

@Component({
  selector: 'app-home-v2',
  templateUrl: './home-v2.component.html',
  styleUrls: ['./home-v2.component.css'],
  providers: [Notifications],
})
export class HomeV2Component implements OnInit {
  profile!: ProfileType;
  user: string | undefined;
  roles: any;
  userEmail: any;
  recurso: Recurso = new Recurso();
  reservas_save: ReservaSave = new ReservaSave();
  reservas_save_date_line: ReservaSave = new ReservaSave();
  categoriaList: Categorias[] = [];
  reservasListShow: Reservas[] = [];
  reserva: ReservaSave[] = [];
  recursoList: Recurso[] = [];
  recursoData: any;
  pisosList: Pisos[] = [];
  pisosListado: Pisos[] = [];
  cateList: Categorias[] = [];
  fechaList: any;
  fechaListV2: any;
  categorias: Categorias[] = [];
  CategoriaSelect: any;
  CategoriaSelectList: any;
  timeUseSelectList: any;
  CategoriaName: any;
  CategoriaId: any;
  noDay: any;
  hoursAtention: any;
  hoursAtentionStart: any;
  hoursAtentionEnd: any;
  noDayList: any;
  tiempoUsoCategory: any;

  // para las fechas
  fechaId: any;
  fechaActual: any;
  horaInicio: string | undefined;
  horaFin: string | undefined;
  horaInicioFor = '07:00:00';
  horaFinFor = '21:00:00';
  minutosUso!: number;
  minutosEspera!: number;
  fechaInicioChange: any;
  tiempoHome: any;
  userStr: String | any;

  // información adicinal
  infoNombreRecurso: any;
  infoDescripcion: any;
  infoUbicacion: any;
  capacidadUso: any;
  imgRuta: any;
  datepickers!: any;
  date: any;
  reservas: any;
  reservasTomorrow: any; // tomorrowany;
  divSize!: number;

  //show divs actions
  public edited = false;
  public edited2 = false;
  public edited3 = false;
  public edited3_1 = false;
  public edited4 = false;
  public editedInfo = false;
  public showMore = true;
  public showLess = false;
  canCheckbox: boolean = false;

  //action de cambio combo box
  verSeleccion: string | undefined;
  favoriteSeason: string | undefined;
  document: any;
  horaDiv: any;
  menus: Menu[] = [];

  // listar reservas dateLine
  myEventsV2: MbscCalendarEvent[] = [];
  myEventsStr: any;
  myResourcesStr: any;
  myResourcesStrs: MbscCalendarEvent[] = [];
  myResourcesChildren: any | undefined;
  myResourcesChildrenStr: MbscCalendarEvent[] = [];
  myResourcesChildrenStr1: MbscCalendarEvent[] = [];
  myResourcesChildrenStr2: MbscCalendarEvent[] = [];
  myResourcesChildrenStr3: MbscCalendarEvent[] = [];
  myResourcesChildrenStrs = ([] = []);

  @ViewChild('loadingScreens') loadingScreens!: ElementRef;
  @ViewChild('contentModal', { static: true }) contentModal!: ElementRef;

  startDate: any;
  endDate: any;
  isLoading!: boolean;
  zone: any;
  readonlyCals: any;
  calendarData: any;
  capacidadMax: any;

  constructor(
    private reservaService: ReservasService,
    private title: Title,
    private http: HttpClient,
    private modalService: NgbModal,
    private recursoService: RecursoService,
    private pisosService: PisosService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private service: ServiceService,
    private cateService: CategoriaService,
    private controlService: ControllersService,
    private router: Router,
    config: NgbModalConfig,
    private notify: Notifications,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.title.setTitle('Inicios | Reservas');
    //this.CategoriaName = "Salas de biblioteca";
    //this.eventSettingsMT();
    this.getRolList();
    this.categoriasList();
    //this.getRecursos();
    //this.getRecursosV2();
    //this.getReservasV2();
    //this.showEventInfTutorial();
    //this.showEventInfoApp();
    window.setInterval(
      () => {
        // Ejemplo: Cada dos minutos esta actualizado las reservas
        //this.getReservasV2();
        //this.eventSettingsMT();
        var categoriaVar = this.CategoriaSelect.split('-');
        //console.log("categoriaVar: "+categoriaVar);
        //console.log(categoriaVar[0]);
        this.getReservasCat(categoriaVar[0]);
        this.eventSettingsMTId(categoriaVar[0]);
        this.getValidateCat(categoriaVar[0]);
      },
      // Intervalo de tiempo
      120000
    );

    setTimeout('document.location.reload()', 960000);
  }

  getNames() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      return this.profile.userPrincipalName;
    });
  }

  editar(reser: Categorias) {
    localStorage.setItem('id', reser.id.toString());
    this.reservas_save.categoriaReserva = reser.id;
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.userEmail = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.roles = data.rol;
        console.log('roles: ' + this.roles);
      });
    });
  }

  //eliminaión logica del registro
  deleteEnable(id: string) {
    this.showEventDeleteReserva();
    this.reservaService.deleteEnableReservaDateLine(id).subscribe((data) => {
      this.showSendMailError();
      Swal.close();
      //this.loadingScreens.nativeElement.style.display = 'none';
      var categoriaVar = this.CategoriaSelect.split('-');
      this.getRecursosCat(categoriaVar[0]);
      this.getReservasCat(categoriaVar[0]);
      this.getValidateCat(categoriaVar[0]);
      this.eventSettingsMTId(categoriaVar[0]);
    });
  }

  //fechas
  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaActualAndHora() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var hora = hoy.getHours();
    var min = hoy.getMinutes();
    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var mint = '';
    var horas = '';

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    if (hora < 10) {
      horas = '0' + hora;
    } else {
      horas = '' + hora;
    }
    if (min < 10) {
      mint = '0' + min;
    } else {
      mint = '' + min;
    }

    fechaActual =
      hoy.getFullYear() +
      '-' +
      months +
      '-' +
      diaSL +
      ' ' +
      horas +
      ':' +
      mint +
      ':' +
      hoy.getSeconds();
    return fechaActual;
  }

  getFechaManana() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();
    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }
  getDay(dateSelect: Date) {
    const fechaComoCadena = dateSelect; // día lunes
    const dias = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia;
    console.log('Nombre de día de la semana: ', nombreDia);
  }

  // control de la ventana emergente
  openLg2(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLg(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLgHome() {
    this.modalService.open(this.contentModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  isEditable = false;
  isLoggedIn = false;
  calendarIds: string[] = [];
  myInvalids: any[] = [];

  // calendario
  @ViewChild('popup', { static: false })
  popup!: MbscPopup;
  @ViewChild('colorPicker', { static: false })
  colorPicker: any;
  state: any = true;
  popupEventTitle: string | undefined;
  popupEventEmail: string | undefined;
  popupEventNames: string | undefined;
  popupIdReserva: string | undefined;
  popupNumberCapacidad: string | undefined;
  popupCapacidadNumber: number | undefined;
  popupValidationPerson: any;
  popupEventDescription = '';
  popupEventAllDay = true;
  popupEventDates: any;
  popupEventStatus = 'busy';
  popupTemaReserva: any;
  popupDescripReserva: any;
  popupCantidadAsistentes: any;
  tiempoUsoCategoryHoras: any;
  tiempoUsoCategoryMints: any;
  popupCapacidadNumber1 = 1;
  calendarSelectedDate: any = new Date();
  switchLabel: any = 'All-day';
  tempColor = '';
  selectedColor = '';
  colorAnchor: HTMLElement | undefined;
  isEditPassHours = false;
  debounce: any;

  eventSettings: MbscEventcalendarOptions | undefined;

  view: MbscEventcalendarView = {};

  myEvents: MbscCalendarEvent[] = [];

  myResourcesArrs: any[] = [];

  tempEvent!: MbscCalendarEvent;

  public momentPlugin = momentTimezone;
  popupHeaderText!: string;
  popupAnchor: HTMLElement | undefined;
  popupAddButtons = [
    'cancel',
    {
      handler: () => {
        this.saveEvent();
      },
      keyCode: 'enter',
      text: 'Guardar',
      cssClass: 'mbsc-popup-button-primary',
    },
  ];
  popupEditButtons = [
    'cancel',
    {
      handler: () => {
        this.editEvent();
      },
      keyCode: 'enter',
      text: 'Actualizar',
      cssClass: 'mbsc-popup-button-primary',
    },
  ];
  popupButtons: any = [];
  popupOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    fullScreen: true,
    onClose: () => {
      if (!this.isEdit) {
        // refresh the list, if add popup was canceled, to remove the temporary event
        this.myEvents = [...this.myEvents];
        console.log(this.myEvents);
      }
    },
    responsive: {
      medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false,
      },
    },
  };
  datePickerResponsive: any = {
    medium: {
      controls: ['calendar'],
      touchUi: false,
    },
  };
  datetimePickerControls = ['time'];
  datetimePickerResponsive = {
    medium: {
      controls: ['calendar', 'time'],
      touchUi: false,
    },
  };
  datePickerOptions: MbscDatepickerOptions = {
    controls: ['time'],
    select: 'range',
    showRangeLabels: false,
    touchUi: false,
    stepMinute: 30,
    timeWheels: '|h:mm A|',
  };
  isEdit = false;
  colorOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    showArrow: false,
    showOverlay: false,
    buttons: [
      'cancel',
      {
        text: 'Set',
        keyCode: 'enter',
        handler: (ev) => {
          this.selectedColor = this.tempColor;
          this.colorPicker.close();
        },
        cssClass: 'mbsc-popup-button-primary',
      },
    ],
    responsive: {
      medium: {
        display: 'anchored',
        buttons: [],
      },
    },
  };
  loadPopupForm(event: MbscCalendarEvent): void {
    var names = this.getNames();

    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.popupEventTitle =
        this.profile.givenName + ' ' + this.profile.surname;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.popupEventDescription = data.rol;
      });
      console.log(event);
      var idResource = event.resource;

      for (var i = 0; i < this.myResourcesArrs.length; i++) {
        for (var j = 0; j < this.myResourcesArrs[i].children.length; j++) {
          if (idResource == this.myResourcesArrs[i].children[j].id) {
            this.popupNumberCapacidad =
              'Máximo para ' +
              this.myResourcesArrs[i].children[j].capacidad +
              ' persona (s)';
            this.popupCapacidadNumber =
              this.myResourcesArrs[i].children[j].capacidad;
          }
        }
      }
      this.popupEventEmail = this.profile.userPrincipalName;
      this.popupEventDates = [event.start, event.end];
      this.popupEventStatus = event.status || 'busy';
      this.popupIdReserva = event.idReserva;
      this.popupTemaReserva = event.asuntoReserva;
      this.popupDescripReserva = event.descripcionReserva;
      this.popupCantidadAsistentes = event.totalAsistentes;

      console.log('popupCantidadAsistentes');
      console.log(this.popupCantidadAsistentes);

      this.selectedColor = event.color || '';
    });
  }
  saveEvent(): void {
    this.tempEvent.title = this.popupEventTitle;
    this.tempEvent.description = this.popupEventDescription;
    this.tempEvent.email = this.popupEventEmail;
    this.tempEvent.start = this.popupEventDates[0];
    this.tempEvent.end = this.popupEventDates[1];
    this.tempEvent.status = this.popupEventStatus;
    this.tempEvent.color = this.selectedColor;
    //this.recurso.nombreRecurso = this.;

    var eventTimeStart = new Date('' + this.tempEvent.start + '');
    var eventTimeEnd = new Date('' + this.tempEvent.end + '');

    var idRecursoInt = 0;
    var idPisoInt = 0;
    var nameRecursoStr = '';
    var idPisoInts = 0;
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idPisoInts = parseInt('' + this.tempEvent + '');

    for (let i = 0; i < this.myResourcesArrs.length; i++) {
      for (let j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idRecursoInt == this.myResourcesArrs[i].children[j].id) {
          idPisoInt = this.myResourcesArrs[i].children[j].piso;
          nameRecursoStr = this.myResourcesArrs[i].children[j].name;
        }
      }
    }

    var fechaResult = this.dateSelect(eventTimeStart, eventTimeEnd);
    var fechaResultStr = fechaResult.split('_');
    var eventTimeStartStr = fechaResultStr[0];
    var eventTimeEndStr = fechaResultStr[1];

    // datos que se envia para guardar una reserva de la version 2
    this.reservas_save_date_line.emailReservador = this.popupEventEmail;
    this.reservas_save_date_line.pisoReserva = idPisoInt;
    this.reservas_save_date_line.idRecurso = idRecursoInt;
    this.reservas_save_date_line.materialReservado = nameRecursoStr;
    this.reservas_save_date_line.fechaReservaInicio = eventTimeStartStr;
    this.reservas_save_date_line.fechaReservaFin = eventTimeEndStr;
    this.reservas_save_date_line.namesReservador =
      this.popupEventTitle?.replace('Reserva - ', '');
    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    this.reservas_save_date_line.numeroAsistentes =
      this.popupCantidadAsistentes;
    console.log('reservas');
    console.log(this.reservas_save_date_line);

    console.log('validar tiempos');
    var result = this.hoursChange();
    var resultList = result.split('-');

    var startTime = resultList[0];
    var endTime = resultList[1];

    if (
      startTime < this.hoursAtentionStart ||
      startTime > this.hoursAtentionEnd ||
      endTime > this.hoursAtentionEnd
    ) {
      this.showEventInfHoursPass(
        this.hoursAtentionStart + ' a ' + this.hoursAtentionEnd
      );
    } else {
      if (this.isEdit) {
        // add the new event to the list
        const start =
          this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

        // handle recurring events
        if (start && start < today) {
          this.notify.toast({
            message:
              'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
          });
        } else {
          var entryHour = moment(eventTimeStart, 'hh:mm');
          var exitHour = moment(eventTimeEnd, 'hh:mm');
          let duration =
            '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
          duration = duration?.replace('-', '');
          var durationInt = parseInt(duration);

          // tiempo de duracion maximo de reserva
          var tiemposUso = parseInt(this.tiempoUsoCategory);
          var tiempoUsoCategorys = tiemposUso + 1;

          // validado para no pasar el tiempo
          if (durationInt < tiempoUsoCategorys) {
            // update the event in the list
            this.myEvents = [...this.myEvents];
            // here you can update the event in your storage as well
            // ...
          } else {
            this.notify.toast({
              message:
                'No se puede reservar, la duración de la reserva debe ser menor a ' +
                this.tiempoUsoCategory +
                ' minutos.',
            });
          }
        }
      } else {
        // add the new event to the list
        const start =
          this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

        // handle recurring events
        if (start && start < today) {
          this.notify.toast({
            message:
              'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
          });
        } else {
          var eventTimeStart = new Date('' + this.tempEvent.start + '');
          var eventTimeEnd = new Date('' + this.tempEvent.end + '');

          var entryHour = moment(eventTimeStart, 'hh:mm');
          var exitHour = moment(eventTimeEnd, 'hh:mm');

          console.log('tempEvent: ' + eventTimeStart.getHours());
          let duration =
            '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
          console.log('duration s' + duration);
          duration = duration?.replace('-', '');
          console.log('duration str' + duration);
          var durationInt = parseInt(duration);

          // tiempo de duracion maximo de reserva
          var tiemposUso = parseInt(this.tiempoUsoCategory);
          var tiempoUsoCategorys = tiemposUso + 1;

          if (this.popupValidationPerson == 1) {
            this.popup.close();
            if (
              this.popupTemaReserva == undefined ||
              this.popupTemaReserva == '' ||
              this.popupDescripReserva == undefined ||
              this.popupDescripReserva == ''
            ) {
              this.showEventErrorNoTimeDescrip();
              this.popup.open();
            } else {
              this.popup.close();
              if (durationInt >= 30) {
                console.log('esta dentro del espacio');

                // validado para no pasar el tiempo
                if (durationInt < tiempoUsoCategorys) {
                  this.showEventInfoOk();

                  this.reservaService
                    .postReservaDateLine(this.reservas_save_date_line)
                    .subscribe(
                      (data) => {
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showSendMail();
                        Swal.close();
                        this.showEventInfoSaveReserva();
                        //this.myEvents = [...this.myEvents, this.tempEvent];
                        // here you can add the event to your storage as well
                        // ...
                        //location.reload();
                        //this.router.navigate(['listarReservas']);
                      },
                      (err) => {
                        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                        if (err.error == 'Reservas excedidas') {
                          Swal.close();
                          var categoriaVar = this.CategoriaSelect.split('-');
                          this.getRecursosCat(categoriaVar[0]);
                          this.getReservasCat(categoriaVar[0]);
                          this.getValidateCat(categoriaVar[0]);
                          this.eventSettingsMTId(categoriaVar[0]);
                          this.showEventInfoError();
                        } else if (err.error == 'Maximo dias') {
                          Swal.close();
                          var categoriaVar = this.CategoriaSelect.split('-');
                          this.getRecursosCat(categoriaVar[0]);
                          this.getReservasCat(categoriaVar[0]);
                          this.getValidateCat(categoriaVar[0]);
                          this.eventSettingsMTId(categoriaVar[0]);
                          this.showEventInfoErrorMaxDias();
                        } else {
                          Swal.close();
                          var categoriaVar = this.CategoriaSelect.split('-');
                          this.getRecursosCat(categoriaVar[0]);
                          this.getReservasCat(categoriaVar[0]);
                          this.getValidateCat(categoriaVar[0]);
                          this.eventSettingsMTId(categoriaVar[0]);
                          this.showEventInfoErrorSave();
                        }
                      }
                    );
                } else {
                  this.notify.toast({
                    message:
                      'No se puede reservar, la duración de la reserva debe ser menor a ' +
                      this.tiempoUsoCategory +
                      ' minutos.',
                  });
                }
              } else {
                console.log('es menor a 30 min');
                this.showEventInfoErrorMenor30min();
              }
            }
          } else {
            if (durationInt >= 30) {
              console.log('esta dentro del espacio');

              // validado para no pasar el tiempo
              if (durationInt < tiempoUsoCategorys) {
                this.showEventInfoOk();

                this.reservaService
                  .postReservaDateLine(this.reservas_save_date_line)
                  .subscribe(
                    (data) => {
                      var categoriaVar = this.CategoriaSelect.split('-');
                      this.getRecursosCat(categoriaVar[0]);
                      this.getReservasCat(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showSendMail();
                      Swal.close();
                      this.showEventInfoSaveReserva();
                      //this.myEvents = [...this.myEvents, this.tempEvent];
                      // here you can add the event to your storage as well
                      // ...
                      //location.reload();
                      //this.router.navigate(['listarReservas']);
                    },
                    (err) => {
                      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                      if (err.error == 'Reservas excedidas') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoError();
                      } else if (err.error == 'Maximo dias') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorMaxDias();
                      } else {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorSave();
                      }
                    }
                  );
              } else {
                this.notify.toast({
                  message:
                    'No se puede reservar, la duración de la reserva debe ser menor a ' +
                    this.tiempoUsoCategory +
                    ' minutos.',
                });
              }
            } else {
              console.log('es menor a 30 min');
              this.showEventInfoErrorMenor30min();
            }
            // close the popup
            this.popup.close();
          }
        }
      }
    }
    // navigate the calendar
    this.calendarSelectedDate = this.popupEventDates[0];
  }
  editEvent(): void {
    this.tempEvent.title = this.popupEventTitle;
    this.tempEvent.description = this.popupEventDescription;
    this.tempEvent.email = this.popupEventEmail;
    this.tempEvent.status = this.popupEventStatus;
    this.tempEvent.color = this.selectedColor;
    var tempEventstart = '';
    var tempEventend = '';
    tempEventstart = this.popupEventDates[0];
    tempEventend = this.popupEventDates[1];

    if (tempEventstart.length <= 19) {
      var eventTimeStart = new Date('' + tempEventstart.replace(' ', 'T') + '');
      var eventTimeEnd = new Date('' + tempEventend.replace(' ', 'T') + '');
    } else {
      var eventTimeStart = new Date('' + tempEventstart + '');
      var eventTimeEnd = new Date('' + tempEventend + '');
    }

    var idRecursoInt = 0;
    var idPisoInt = 0;
    var idEdit = 0;
    var nameRecursoStr = '';
    var idPisoInts = 0;
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idPisoInts = parseInt('' + this.tempEvent + '');
    idEdit = parseInt('' + this.popupIdReserva + '');

    for (let i = 0; i < this.myResourcesArrs.length; i++) {
      for (let j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idRecursoInt == this.myResourcesArrs[i].children[j].id) {
          idPisoInt = this.myResourcesArrs[i].children[j].piso;
          nameRecursoStr = this.myResourcesArrs[i].children[j].name;
        }
      }
    }

    var fechaResult = this.dateSelect(eventTimeStart, eventTimeEnd);
    console.log(fechaResult);
    var fechaResultStr = fechaResult.split('_');
    var eventTimeStartStr = fechaResultStr[0];
    var eventTimeEndStr = fechaResultStr[1];

    // datos que se envia para guardar una reserva de la version 2
    this.reservas_save_date_line.id = idEdit;
    this.reservas_save_date_line.emailReservador = this.popupEventEmail;
    this.reservas_save_date_line.pisoReserva = idPisoInt;
    this.reservas_save_date_line.idRecurso = idRecursoInt;
    this.reservas_save_date_line.materialReservado = nameRecursoStr;
    this.reservas_save_date_line.fechaReservaInicio = eventTimeStartStr;
    this.reservas_save_date_line.fechaReservaFin = eventTimeEndStr;
    this.reservas_save_date_line.namesReservador =
      this.popupEventTitle?.replace('Reserva - ', '');
    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    this.reservas_save_date_line.numeroAsistentes =
      this.popupCantidadAsistentes;
    console.log('editando reservas');
    console.log(this.reservas_save_date_line);

    if (this.isEdit) {
      // add the new event to the list
      const start =
        this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message:
            'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
        });
      } else {
        console.log('Reservas okey');

        var entryHour = moment(eventTimeStart, 'hh:mm');
        var exitHour = moment(eventTimeEnd, 'hh:mm');

        console.log('tempEvent: ' + eventTimeStart.getHours());
        let duration =
          '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
        console.log('duration s' + duration);
        duration = duration?.replace('-', '');
        console.log('duration str' + duration);
        var durationInt = parseInt(duration);

        // tiempo de duracion maximo de reserva
        var tiemposUso = parseInt(this.tiempoUsoCategory);
        var tiempoUsoCategorys = tiemposUso + 1;

        if (this.popupValidationPerson == 1) {
          this.popup.close();
          if (
            this.popupTemaReserva == undefined ||
            this.popupTemaReserva == '' ||
            this.popupDescripReserva == undefined ||
            this.popupDescripReserva == ''
          ) {
            this.showEventErrorNoTimeDescrip();
            this.popup.open();
          } else {
            this.popup.close();
            if (durationInt >= 30) {
              console.log('esta dentro del espacio');
              // validado para no pasar el tiempo
              if (durationInt < tiempoUsoCategorys) {
                this.showEventInfoPut();
                this.reservaService
                  .putReservaDateLine(this.reservas_save_date_line)
                  .subscribe(
                    (data) => {
                      var categoriaVar = this.CategoriaSelect.split('-');
                      this.getRecursosCat(categoriaVar[0]);
                      this.getReservasCat(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showSendMailUpdate();
                      Swal.close();
                      this.showEventInfoEditReserva();
                      //this.myEvents = [...this.myEvents, this.tempEvent];
                      // here you can add the event to your storage as well
                      // ...
                      //location.reload();
                      //this.router.navigate(['listarReservas']);
                    },
                    (err) => {
                      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                      if (err.error == 'Reservas excedidas') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoError();
                      } else if (err.error == 'Maximo dias') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorMaxDias();
                      } else {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-');
                        this.getRecursosCat(categoriaVar[0]);
                        this.getReservasCat(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorSave();
                      }
                    }
                  );
              } else {
                this.notify.toast({
                  message:
                    'No se puede reservar, la duración de la reserva debe ser menor a ' +
                    this.tiempoUsoCategory +
                    ' minutos.',
                });
              }
            } else {
              console.log('es menor a 30 min');
              this.showEventInfoErrorMenor30min();
            }
          }
        } else {
          if (durationInt >= 30) {
            console.log('esta dentro del espacio');
            // validado para no pasar el tiempo
            if (durationInt < tiempoUsoCategorys) {
              this.showEventInfoPut();
              this.reservaService
                .putReservaDateLine(this.reservas_save_date_line)
                .subscribe(
                  (data) => {
                    var categoriaVar = this.CategoriaSelect.split('-');
                    this.getRecursosCat(categoriaVar[0]);
                    this.getReservasCat(categoriaVar[0]);
                    this.getValidateCat(categoriaVar[0]);
                    this.eventSettingsMTId(categoriaVar[0]);
                    this.showSendMailUpdate();
                    Swal.close();
                    this.showEventInfoEditReserva();
                    //this.myEvents = [...this.myEvents, this.tempEvent];
                    // here you can add the event to your storage as well
                    // ...
                    //location.reload();
                    //this.router.navigate(['listarReservas']);
                  },
                  (err) => {
                    // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                    if (err.error == 'Reservas excedidas') {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-');
                      this.getRecursosCat(categoriaVar[0]);
                      this.getReservasCat(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoError();
                    } else if (err.error == 'Maximo dias') {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-');
                      this.getRecursosCat(categoriaVar[0]);
                      this.getReservasCat(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoErrorMaxDias();
                    } else {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-');
                      this.getRecursosCat(categoriaVar[0]);
                      this.getReservasCat(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoErrorSave();
                    }
                  }
                );
            } else {
              this.notify.toast({
                message:
                  'No se puede reservar, la duración de la reserva debe ser menor a ' +
                  this.tiempoUsoCategory +
                  ' minutos.',
              });
            }
          } else {
            console.log('es menor a 30 min');
            this.showEventInfoErrorMenor30min();
          }
          // close the popup
          this.popup.close();
        }
      }
    }
    // navigate the calendar
    this.calendarSelectedDate = this.popupEventDates[0];
    // close the popup
    this.popup.close();
  }
  editDiegoEvent(): void {
    this.tempEvent.start = this.popupEventDates[0];
    this.tempEvent.end = this.popupEventDates[1];

    var eventTimeStart = new Date('' + this.tempEvent.start + '');
    var eventTimeEnd = new Date('' + this.tempEvent.end + '');
    var fechaResult = this.dateSelect(eventTimeStart, eventTimeEnd);
    var fechaResultStr = fechaResult.split('_');
    console.log('asdsa: ' + fechaResultStr);
    var eventTimeStartStr = fechaResultStr[0];
    var eventTimeEndStr = fechaResultStr[1];

    var idRecursoInt = 0;
    var idPisoInt = 0;
    var idEdit = 0;
    var nameRecursoStr = '';
    var idPisoInts = 0;
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idPisoInts = parseInt('' + this.tempEvent + '');

    for (let i = 0; i < this.myResourcesArrs.length; i++) {
      for (let j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idRecursoInt == this.myResourcesArrs[i].children[j].id) {
          idPisoInt = this.myResourcesArrs[i].children[j].piso;
          nameRecursoStr = this.myResourcesArrs[i].children[j].name;
        }
      }
    }

    var idStr = '';
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idEdit = parseInt('' + this.popupIdReserva + '');

    // datos que se envia para guardar una reserva de la version 2
    this.reservas_save_date_line.id = idEdit;
    this.reservas_save_date_line.emailReservador = this.popupEventEmail;
    this.reservas_save_date_line.pisoReserva = idPisoInt;
    this.reservas_save_date_line.idRecurso = idRecursoInt;
    this.reservas_save_date_line.materialReservado = nameRecursoStr;
    this.reservas_save_date_line.fechaReservaInicio = eventTimeStartStr;
    this.reservas_save_date_line.fechaReservaFin = eventTimeEndStr;
    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    console.log('dasd: ' + this.popupNumberCapacidad);
    this.reservas_save_date_line.numeroAsistentes = this.popupNumberCapacidad;
    this.reservas_save_date_line.namesReservador =
      this.popupEventTitle?.replace('Reserva - ', '');
    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    this.reservas_save_date_line.numeroAsistentes =
      this.popupCantidadAsistentes;

    if (this.isEdit) {
      // add the new event to the list
      const start =
        this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message:
            'No se puede reserva, esta fuera del rango del horario de inicio de la reserva.',
        });
      } else {
        var eventTimeStart = new Date('' + eventTimeStartStr + '');
        var eventTimeEnd = new Date('' + eventTimeEndStr + '');

        var entryHour = moment(eventTimeStart, 'hh:mm');
        var exitHour = moment(eventTimeEnd, 'hh:mm');

        console.log(entryHour);

        let duration =
          '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
        duration = duration?.replace('-', '');
        console.log('duration str' + duration);

        var durationInt = parseInt(duration);

        // tiempo de duracion maximo de reserva
        var tiemposUso = parseInt(this.tiempoUsoCategory);
        var tiempoUsoCategorys = tiemposUso + 1;

        // validado para no pasar el tiempo
        if (durationInt < tiempoUsoCategorys) {
          this.popup.close();
          this.showEventInfoPut();
          this.reservaService
            .putReservaDateLine(this.reservas_save_date_line)
            .subscribe(
              (data) => {
                this.showSendMailUpdate();
                Swal.close();

                // navigate the calendar
                this.calendarSelectedDate = this.popupEventDates[0];
                // close the popup
                var categoriaVar = this.CategoriaSelect.split('-');
                this.getRecursosCat(categoriaVar[0]);
                this.getReservasCat(categoriaVar[0]);
                this.getValidateCat(categoriaVar[0]);
                this.eventSettingsMTId(categoriaVar[0]);
                //this.router.navigate(['listarReservas']);
              },
              (err) => {
                this.popup.close();
                // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                if (err.error == 'Reservas excedidas') {
                  Swal.close();
                  this.showEventInfoError();
                } else {
                  Swal.close();
                  this.showEventInfoErrorSave();
                }
              }
            );
        } else {
          this.notify.toast({
            message:
              'No se puede reservar, la duración de la reserva debe ser menor a ' +
              this.tiempoUsoCategory +
              ' minutos.',
          });
        }
      }
    }
  }
  deleteEvent(event: MbscCalendarEvent): void {
    this.myEvents = this.myEvents.filter((item) => item.id !== event.id);
    this.deleteEnable('' + event.idReserva);

    this.notify.snackbar({
      button: {
        action: () => {
          this.myEvents = [...this.myEvents, event];
        },
        text: 'ok',
      },
      message: 'Reserva Eliminada',
    });

    // here you can delete the event from your storage as well
    // ...
  }

  onDeleteClick(): void {
    this.deleteEvent(this.tempEvent);
    this.popup.close();
  }

  selectColor(color: string): void {
    this.tempColor = color;
  }

  openColorPicker(ev: any): void {
    this.selectColor(this.selectedColor || '');
    this.colorAnchor = ev.currentTarget;
    this.colorPicker.open();
  }

  dateSelect(eventTimeStart: Date, eventTimeEnd: Date) {
    let MonthStartStr = null;
    let DayStartStr = null;
    MonthStartStr = eventTimeStart.getMonth() + 1;
    if (MonthStartStr < 10) {
      MonthStartStr = '0' + MonthStartStr;
    } else {
      MonthStartStr = MonthStartStr;
    }
    DayStartStr = eventTimeStart.getDate();
    if (DayStartStr < 10) {
      DayStartStr = '0' + DayStartStr;
    } else {
      DayStartStr = DayStartStr;
    }

    let MonthEndStr = null;
    let DayEndStr = null;
    MonthEndStr = eventTimeEnd.getMonth() + 1;
    if (MonthEndStr < 10) {
      MonthEndStr = '0' + MonthEndStr;
    } else {
      MonthEndStr = MonthEndStr;
    }
    DayEndStr = eventTimeEnd.getDate();
    if (DayEndStr < 10) {
      DayEndStr = '0' + DayEndStr;
    } else {
      DayEndStr = DayEndStr;
    }

    let MinutesStartStr = null;
    let MinutesEndStr = null;
    MinutesStartStr = eventTimeStart.getMinutes();
    if (MinutesStartStr < 10) {
      MinutesStartStr = '0' + MinutesStartStr;
    } else {
      MinutesStartStr = MinutesStartStr;
    }

    MinutesEndStr = eventTimeEnd.getMinutes();
    if (MinutesEndStr < 10) {
      MinutesEndStr = '0' + MinutesEndStr;
    } else {
      MinutesEndStr = MinutesEndStr;
    }

    var eventTimeStartStr =
      eventTimeStart.getFullYear() +
      '-' +
      MonthStartStr +
      '-' +
      DayStartStr +
      ' ' +
      eventTimeStart.getHours() +
      ':' +
      MinutesStartStr +
      ':00';
    var eventTimeEndStr =
      eventTimeEnd.getFullYear() +
      '-' +
      MonthEndStr +
      '-' +
      DayEndStr +
      ' ' +
      eventTimeEnd.getHours() +
      ':' +
      MinutesEndStr +
      ':00';

    return '' + eventTimeStartStr + '_' + eventTimeEndStr;
  }

  onChanges() {
    console.log('Data para ver si puede reservar en ese rango');

    var result = this.hoursChange();
    var resultList = result.split('-');

    var startTime = resultList[0];
    var endTime = resultList[1];

    console.log('startTime: ' + startTime);
    console.log('endTime: ' + startTime);
    console.log('this.hoursAtentionStart: ' + this.hoursAtentionStart);


    // Restringir la selección si está fuera del rango
    if (
      startTime < this.hoursAtentionStart ||
      startTime > this.hoursAtentionEnd ||
      endTime > this.hoursAtentionEnd
    ) {
      const dateStartNew = new Date(this.popupEventDates[0]);
      dateStartNew.setHours(7);
      this.popupEventDates[0] = dateStartNew;

      this.showEventInfHoursPass(
        this.hoursAtentionStart + ' a ' + this.hoursAtentionEnd
      );
    }
  }

  hoursChange() {
    //transfor hora y min de cominzo
    var hoursStartTime = '';
    var minStartTime = '';
    if (this.popupEventDates[0].getHours() < 10) {
      hoursStartTime = '0' + this.popupEventDates[0].getHours();
    } else {
      hoursStartTime = this.popupEventDates[0].getHours();
    }
    if (this.popupEventDates[0].getMinutes() < 10) {
      minStartTime = '0' + this.popupEventDates[0].getMinutes();
    } else {
      minStartTime = this.popupEventDates[0].getMinutes();
    }
    const startTime = hoursStartTime + ':' + minStartTime;

    //transfor hora y min de fin
    var hoursEndTime = '';
    var minEndTime = '';
    if (this.popupEventDates[1].getHours() < 10) {
      hoursEndTime = '0' + this.popupEventDates[1].getHours();
    } else {
      hoursEndTime = this.popupEventDates[1].getHours();
    }
    if (this.popupEventDates[1].getMinutes() < 10) {
      minEndTime = '0' + this.popupEventDates[1].getMinutes();
    } else {
      minEndTime = this.popupEventDates[1].getMinutes();
    }
    const endTime = hoursEndTime + ':' + minEndTime;

    console.log('startTime: ' + startTime);
    console.log('endTime: ' + endTime);

    return startTime + '-' + endTime;
  }

  HoursPassTime() {
    console.log('Data para ver si puede reservar en ese rango');
    console.log(this.popupEventDates);
    console.log(
      this.popupEventDates[0].getHours() +
        ':' +
        this.popupEventDates[0].getMinutes()
    );

    //transfor hora y min de cominzo
    var hoursStartTime = '';
    var minStartTime = '';
    if (this.popupEventDates[0].getHours() < 10) {
      hoursStartTime = '0' + this.popupEventDates[0].getHours();
    } else {
      hoursStartTime = this.popupEventDates[0].getHours();
    }
    if (this.popupEventDates[0].getMinutes() < 10) {
      minStartTime = '0' + this.popupEventDates[0].getMinutes();
    } else {
      minStartTime = this.popupEventDates[0].getMinutes();
    }
    const startTime = hoursStartTime + ':' + minStartTime;

    //transfor hora y min de fin
    var hoursEndTime = '';
    var minEndTime = '';
    if (this.popupEventDates[1].getHours() < 10) {
      hoursEndTime = '0' + this.popupEventDates[1].getHours();
    } else {
      hoursEndTime = this.popupEventDates[1].getHours();
    }
    if (this.popupEventDates[1].getMinutes() < 10) {
      minEndTime = '0' + this.popupEventDates[1].getMinutes();
    } else {
      minEndTime = this.popupEventDates[1].getMinutes();
    }
    const endTime = hoursEndTime + ':' + minEndTime;

    console.log('startTime: ' + startTime);
    console.log('endTime: ' + endTime);
    console.log('this.hoursAtentionStart: ' + this.hoursAtentionStart);

    // Restringir la selección si está fuera del rango
    if (
      startTime < this.hoursAtentionStart ||
      startTime > this.hoursAtentionEnd ||
      endTime > this.hoursAtentionEnd
    ) {

    }
  }

  changeColor(ev: any): void {
    const color = ev.currentTarget.getAttribute('data-value');
    this.selectColor(color);

    if (!this.colorPicker.s.buttons.length) {
      this.selectedColor = color;
      this.colorPicker.close();
    }
  }

  //eventSettings
  eventSettingsMTId(id: number) {
    this.cateService.getNoDia(id).subscribe((data) => {
      //console.log(data);
      var hoursAt;
      this.noDayList = data;
      this.noDay = this.noDayList.noDay;
      this.hoursAtention = this.noDayList.hourAtention;
      hoursAt = this.noDayList.hourAtention.split('-');
      this.hoursAtentionStart = hoursAt[0];
      this.hoursAtentionEnd = hoursAt[1];
      console.log('this.noDayList.hourAtention');
      console.log(this.noDayList.hourAtention);
      const milisegundos = this.tiempoUsoCategoryMints * 60 * 1000;
      console.log('milisegundos');
      console.log(milisegundos);
      var hoursSetting = this.hoursTimeSettings(this.hoursAtentionStart+"", this.hoursAtentionEnd+"");

      var hoursSettingList = hoursSetting.split("-");

      this.eventSettings = {
        invalid: [
          {
            recurring: {
              repeat: 'daily',
              until: yesterday,
            },
          },
          {
            recurring: {
              repeat: 'weekly',
              day: 24,
            },
          },
          {
            start: yesterday,
            end: today,
          },
          {
            recurring: {
              repeat: 'weekly',
              weekDays: this.noDay,
            },
          },
          {
            start: '12:00',
            end: '21:00',
            recurring: {
              repeat: 'weekly',
              weekDays: this.noDay,
            },
          },
          {
            start: '07:00',
            end: '08:00',
            recurring: {
              repeat: 'weekly',
              weekDays: this.noDay,
            },
          },
          {
            start: '07:00',
            end: '21:00',
            recurring: {
              repeat: 'daily',
              interval: 0,
            },
            resource: [
              'gro1',
              'gro2',
              'gro3',
              'gro4',
              'gro5',
              'gro6',
              'gro21',
              'gro22',
              'gro23',
              'gro24',
              'gro101',
              'gro41',
              'gro61',
              'gro81',
              'gro101',
              'gro121',
              'gro141',
              'gro142',
            ], // disable the parent resources with id 'gro2' and 'gro3'
          },
        ],
        clickToCreate: 'single',
        dragToCreate: true,
        dragToMove: true,
        dragToResize: true,
        onEventCreateFailed: (event: any) => {
          const oldEvent = event;
          //console.log('onEventCreateFailed: ' + event.originEvent);

          if (!event.originEvent) {
            this.notify.toast({
              message: 'No se puede reservar.',
            });
          }
        },
        onEventUpdateFailed: (event: any) => {
          if (!event.oldEventOccurrence) {
            this.notify.toast({
              message: 'No se puede reservar.',
            });
          }
        },
        onEventClick: (args: any) => {
          const oldEvent = args.event;
          const start = oldEvent && oldEvent.start ? oldEvent.start : null;

          var todayStr = new Date('' + todayString + '');

          let MonthStartStr = null;
          let DayStartStr = null;
          let MinutosStartStr = null;
          let SegudoStartStr = null;

          MonthStartStr = todayStr.getMonth() + 1;
          if (MonthStartStr < 10) {
            MonthStartStr = '0' + MonthStartStr;
          } else {
            MonthStartStr = MonthStartStr;
          }
          DayStartStr = todayStr.getDate();
          if (DayStartStr < 10) {
            DayStartStr = '0' + DayStartStr;
          } else {
            DayStartStr = DayStartStr;
          }

          MinutosStartStr = todayStr.getMinutes();
          MinutosStartStr = MinutosStartStr;
          if (MinutosStartStr < 10) {
            MinutosStartStr = '0' + MinutosStartStr;
          } else {
            MinutosStartStr = MinutosStartStr;
          }

          SegudoStartStr = todayStr.getSeconds();
          if (SegudoStartStr < 10) {
            SegudoStartStr = '0' + SegudoStartStr;
          } else {
            SegudoStartStr = SegudoStartStr;
          }

          var hoursStr = null;
          if (hours < 10) {
            hoursStr = '0' + hours;
          } else {
            hoursStr = hours;
          }

          var todayStrs =
            todayStr.getFullYear() +
            '-' +
            MonthStartStr +
            '-' +
            DayStartStr +
            ' ' +
            hoursStr +
            ':' +
            MinutosStartStr +
            ':' +
            SegudoStartStr;

          //console.log(todayStrs);

          //console.log('start: ' + start);
          if (this.user == args.event.email) {
            // handle recurring events
            if (start && start < today) {
              this.notify.toast({
                message: 'No se puede reservar.',
              });
            } else {
              if (start && start < todayStrs) {
                this.isEditPassHours = false;
                this.isEdit = false;
                this.notify.toast({
                  message: 'La reserva ya empezó o finalizó',
                });
              } else {
                this.isEditPassHours = true;
                this.isEdit = true;
                this.popupButtons = this.popupEditButtons;
                this.popupHeaderText = 'Editar reserva';
                this.popupAnchor = args.domEvent.currentTarget;
                this.tempEvent = args.event;
                // fill popup form with event data
                this.loadPopupForm(args.event);
                // set popup options
                // open the popup
                this.popup.open();
              }
            }
          }
        },
        onEventCreated: (args: any) => {
          const oldEvent = args.event;
          const start = oldEvent && oldEvent.start ? oldEvent.start : null;

          // handle recurring events
          if (start && start < today) {
            this.notify.toast({
              message: 'No se puede reservar',
            });
            return false;
          } else {
            setTimeout(() => {
              this.isEdit = false;
              this.tempEvent = args.event;
              // fill popup form with event data
              this.loadPopupForm(args.event);
              // set popup options
              this.popupHeaderText = 'Nueva reserva';
              this.popupButtons = this.popupAddButtons;
              this.popupAnchor = args.target;
              // open the popup
              this.popup.open();
            });
            return true;
          }
        },
        onEventUpdate: (args: any) => {
          const oldEvent = args.event;
          const start = oldEvent && oldEvent.start ? oldEvent.start : null;

          // handle recurring events
          if (start && start < today) {
            this.notify.toast({
              message: 'No se puede reservar',
            });
            return false;
          } else {
            setTimeout(() => {
              this.isEdit = false;
              this.tempEvent = args.event;
              // fill popup form with event data
              this.loadPopupForm(args.event);
              // set popup options
              this.popupHeaderText = 'Actualizando la reserva';
              this.popupButtons = this.popupEditButtons;
              this.popupAnchor = args.target;
              // open the popup
              this.popup.open();
            });
            return true;
          }
        },
      };
      this.view = {
        timeline: {
          type: 'day',
          allDay: false,
          startDay: 0,
          endDay: 7,
          startTime: this.hoursAtentionStart,
          endTime: this.hoursAtentionEnd,
        },
      };
      this.datePickerOptions = {
        invalid: [{
          start: '00:00',
          end: hoursSettingList[0],
          recurring: {
              repeat: 'daily'
          }
      }, {
        start: hoursSettingList[1],
        end: '23:59',
        recurring: {
            repeat: 'daily'
        }
    }],
        controls: ['time'],
        select: 'range',
        showRangeLabels: false,
        touchUi: false,
        stepMinute: 30,
        timeWheels: '|h:mm A|',
        minRange: 1800000,
        maxRange: milisegundos,
      };
    });
  }

  hoursTimeSettings(hoursStartTxt: string, hoursEndTxt: string) {

    // para la hora de comienzo
    const horaTexto = hoursStartTxt;
    const partesHora = horaTexto.split(':');
    const hora = new Date();
    hora.setHours(parseInt(partesHora[0], 10));
    hora.setMinutes(parseInt(partesHora[1], 10)-1);
    hora.setSeconds(0);
    hora.setMilliseconds(0);
    var resultHoursStartTxts = hora.getHours().toString().padStart(2, '0')+":"+hora.getMinutes();
    //console.log("hora"+hora.getHours().toString().padStart(2, '0')+":"+hora.getMinutes()); // Resultado: Thu Jan 01 1970 06:00:00 GMT+0000 (Coordinated Universal Time)

    // para la hora de comienzo
    const horaTextoEnd = hoursEndTxt;
    const partesHoraEnd = horaTextoEnd.split(':');
    const horaEnd = new Date();
    horaEnd.setHours(parseInt(partesHoraEnd[0], 10));
    horaEnd.setMinutes(parseInt(partesHoraEnd[1], 10)+30);
    horaEnd.setSeconds(0);
    horaEnd.setMilliseconds(0);
    //console.log("horaEnd"+horaEnd.getHours()+":"+horaEnd.getMinutes().toString().padStart(2, '0')); // Resultado: Thu Jan 01 1970 06:00:00 GMT+0000 (Coordinated Universal Time)
    var resultHoursEndTxts = horaEnd.getHours()+":"+horaEnd.getMinutes().toString().padStart(2, '0');

    return resultHoursStartTxts +"-"+resultHoursEndTxts;
  }

  // listar categorias
  categoriasList() {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.categorias = data;
      for (let i = 0; i < this.categorias.length; i++) {
        if (i == 0) {
          //console.log(this.categorias[i].nombreCategoria);
          this.CategoriaSelect =
            this.categorias[i].id +
            '-_' +
            this.categorias[i].nombreCategoria +
            '_' +
            this.categorias[i].tiempoUso;
          console.log("tiempo maximo: "+this.tiempoUsoCategoryHoras);
          this.tiempoUsoCategoryHoras = this.toHoursAndMinutes(
            this.categorias[i].tiempoUso
          ).hours;
          this.tiempoUsoCategoryMints = this.categorias[i].tiempoUso;
          this.tiempoUsoCategory = this.categorias[i].tiempoUso;
          this.getRecursosCat(this.categorias[i].id);
          this.getReservasCat(this.categorias[i].id);
          this.eventSettingsMTId(this.categorias[i].id);
          this.getValidateCat(this.categorias[i].id);
          this.CategoriaName = this.categorias[i].nombreCategoria;
          this.CategoriaId = this.categorias[i].id;
          this.capacidadMax = this.categorias[i].capacidad;
        } else {
          console.log('Otros');
        }
      }
      //console.log("this.categorias: "+this.categorias );
      //this.CategoriaSelect = "1-Salas de biblioteca";
    });
  }

  categoriasListId(id: number) {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.categorias = data;
      this.CategoriaSelect = 1;
    });
  }
  toHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  selectCategory() {
    this.showEventInfoShowCategorias();

    //console.log('Categoria: ' + this.CategoriaSelect);
    this.CategoriaSelectList = this.CategoriaSelect.split('-_');

    if (this.CategoriaSelectList.length != 1) {
      var userStr = '';

      this.timeUseSelectList = this.CategoriaSelectList[1].split('_');

      this.CategoriaName = this.timeUseSelectList[0];
      this.CategoriaId = this.CategoriaSelectList[0];
      //this.tiempoUsoCategory = this.timeUseSelectList[1];
      this.tiempoUsoCategoryHoras = this.toHoursAndMinutes(
        this.timeUseSelectList[1]
      ).hours;
      this.tiempoUsoCategoryMints = this.timeUseSelectList[1];

      this.tiempoUsoCategory = this.timeUseSelectList[1];
      //console.log(this.toHoursAndMinutes(this.tiempoUsoCategory));
      //console.log("this.tiempoUsoCategory select: "+this.tiempoUsoCategory );
      this.getRecursosCat(this.CategoriaSelectList[0]);
      this.getReservasCat(this.CategoriaSelectList[0]);
      this.getValidateCat(this.CategoriaSelectList[0]);
      this.eventSettingsMTId(this.CategoriaSelectList[0]);
    } else {
      this.showEventInfoErrorNoCategorias();

      setTimeout('Swal.clase', 1200);
    }
  }

  getReservasCat(cat: number) {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.userEmail = this.profile.userPrincipalName;
      this.reservaService
        .getReservasDateLineUserCat(cat, '' + this.user)
        .subscribe(
          (data) => {
            this.myEvents = data;
            Swal.close();
          },
          (err) => {
            //console.log(err);
          }
        );
    });
  }

  getRecursosCat(cat: number) {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.userEmail = this.profile.userPrincipalName;
      this.reservaService
        .getRecursosDateLineUserv3(cat, '' + this.user)
        .subscribe(
          (data) => {
            this.myResourcesArrs = data;
            Swal.close();
          },
          (err) => {
            //console.log(err);
          }
        );
    });
  }

  getValidateCat(cat: number) {
    this.categoriaService.postValidate(cat).subscribe(
      (data) => {
        this.popupValidationPerson = data;
      },
      (err) => {
        //console.log(err);
      }
    );
  }
  //eventos para mostrar el estado de la acción de información del recurso
  showEventInfoOk() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos validando su reserva. Una vez finalizado este proceso, recibirá un correo electrónico de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoError() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de reservas que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,

      //delay: 6000,
    });
  }
  showEventInfoErrorMenor30min() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'Recuerdo que las reservas deben tener una duración mínima de 30 minutos.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },

      //delay: 6000,
    });
  }
  showEventInfoSaveReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'success',
      title: 'Reserva generada o solicitada',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
    //location.reload();
  }

  showEventErrorNoTimeDescrip() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title:
        'Es necesario ingresar el tema, la descripción y el número de participantes.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
    //location.reload();
  }

  showEventInfoEditReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'success',
      title: 'Reserva modificada con éxito',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
    //location.reload();
  }
  showEventInfoErrorMaxDias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Días aún no disponibles de reservas.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorSave() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
    //location.reload();
  }
  showEventInfoErrorAforo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Aforo completo para el horario que seleccionó.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorNoAccess() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventDeleteReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Reserva cancelada',
      text: 'Estamos cancelando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoPut() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos modificando su reserva y una vez terminada le llegará un correo de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoApp() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Recuerde',
      text: 'Está aplicación es de uso exclusivo para pruebas, todas las reservas que hagan serán solo para pruebas y no se guardarán en la base de datos.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Estoy de acuerdo',
    });
  }

  showEventInfoShowCategorias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }

  showEventInfoErrorNoCategorias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Seleccione la categoría correcta',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      timer: 1500,
      //delay: 6000,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 3000);
      },
    });
  }

  showEventInfTutorial() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Tenemos nueva versión del sistema de reservas',
      html: '<video  width="450" height="300" src="./assets/videos/tutorialReservas.mov" autoplay muted loop></video>',

      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Estoy de acuerdo',
    });
  }

  showEventInfHoursPass(hourText: String) {
    this.popup.close();
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'No se puede hacer una reserva fuera del horario disponible',
      html: 'Recuerda el horario es ' + hourText,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }


  //alertas
  showSuccess() {
    this.toastService.show('💾 Reserva añadido con éxito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSuccesUpdate() {
    this.toastService.show('💾 Reserva modificada con éxito...!!!', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMail() {
    this.toastService.show('📤  Reserva creada correctamente', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSendMailUpdate() {
    this.toastService.show('📤  Reserva modificada correctamente', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMailError() {
    this.toastService.show('📢 Reserva cancelada correctamente', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  showRecursos() {
    this.toastService.show('📢 Recursos de biblioteca cargados correctamente', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showNoRecursosV2() {
    this.toastService.show(
      '📢  Todos los recursos han sido usados para la hora seleccionada.',
      {
        classname: 'bg-warning',
        delay: 4000,
      }
    );
  }
  showNoRecursos() {
    this.toastService.show(
      '📢  No existe ningún recurso relacionado con su búsqueda',
      {
        classname: 'bg-danger text-light',
        delay: 3000,
      }
    );
  }
  showAlertTiempoUso() {
    this.toastService.show('📢 Recuerda: Las reserva se realizan para el día', {
      classname: 'bg-info',
      delay: 8000,
    });
  }
  showError() {
    this.toastService.show(
      '⚠ Error al cargar el recurso. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorDate() {
    this.toastService.show(
      '⚠ Error: La fecha seleccionada no puede ser menor a la actual',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorSave() {
    this.toastService.show(
      '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoAccess() {
    this.controlService.getControllers().subscribe((data) => {
      this.toastService.show(
        '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }
  showErrorNoDia() {
    var diasNo = '';
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        diasNo = data[0].diasAtencion;
      }
      this.toastService.show(
        '⚠ Error: No se puede reservar para le fecha seleccionado | ' +
          diasNo +
          '.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }

  showErrorNoHora() {
    this.toastService.show(
      '⚠ Error: Reservas finalizadas por hoy. Si desea realizar una reserva para mañana.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorEx() {
    this.toastService.show(
      '⚠ Error: Solo puede realizar una reserva por día.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorVacio() {
    this.toastService.show('⚠ Error: Esta vacío la seleccionado', {
      classname: 'bg-warning',
      delay: 4000,
    });
  }
  showErrorAforo() {
    this.toastService.show(
      '⚠ Error: Aforo completo para el horario que seleccionó.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  openInfoAlert() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Se comunica que el día de hoy viernes el horario de atención será de 08:00 a 11:00 am. El día lunes retomamos en nuestro horario habitual.',
      footer: 'Agradecemos su comprensión.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 8000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
}
