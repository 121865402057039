import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  setOptions,
  localeEs,
  MbscCalendarEvent,
  Notifications,
  MbscPopup,
  MbscEventcalendarOptions,
  MbscPopupOptions,
  MbscDatepickerOptions,
  MbscEventcalendarView,
} from '@mobiscroll/angular';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Categorias } from 'src/app/model/Categorias';
import { Menu } from 'src/app/model/Menu';
import { Pisos } from 'src/app/model/Pisos';
import { Recurso } from 'src/app/model/Recurso';
import { Reservas } from 'src/app/model/Reservas';
import { ReservaSave } from 'src/app/model/ReservaSave';
import { Usuarios } from 'src/app/model/Usuarios';
import { CategoriaService } from 'src/app/Service/categoria.service';
import { ControllersService } from 'src/app/Service/controllers.service';
import { PisosService } from 'src/app/Service/pisos.service';
import { RecursoService } from 'src/app/Service/recurso.service';
import { ReservasService } from 'src/app/Service/reservas.service';
import { ServiceService } from 'src/app/Service/service.service';
import { ToastService } from 'src/app/Service/toast.service';
import Swal from 'sweetalert2';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

setOptions({
  locale: localeEs,
  theme: 'ios',
  themeVariant: 'light',
});

const now = new Date();
const mints = now.getMinutes();
const hours = now.getHours();
const today = new Date(now.setMinutes(-1));
const todayString = new Date(now.setMinutes(mints));
const yesterday = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() - 1
);

@Component({
  selector: 'app-reservas-administraction',
  templateUrl: './reservas-administraction.component.html',
  styleUrls: ['./reservas-administraction.component.css'],
})
export class ReservasAdministractionComponent implements OnInit {
  profile!: ProfileType;
  user: string | undefined;
  roles: any;
  recurso: Recurso = new Recurso();
  reservas_save: ReservaSave = new ReservaSave();
  reservas_save_date_line: ReservaSave = new ReservaSave();
  users_save: Usuarios = new Usuarios();
  categoriaList: Categorias[] = [];
  reservasListShow: Reservas[] = [];
  reserva: ReservaSave[] = [];
  recursoData: any;
  recursoList: Recurso[] = [];
  pisosList: Pisos[] = [];
  pisosListado: Pisos[] = [];
  cateList: Categorias[] = [];
  fechaList: any;
  fechaListV2: any;
  timeUseSelectList: any;
  tiempoUsoCategory: any;
  CategoriaId: any;

  popupTemaReserva: any;
  popupDescripReserva: any;

  CategoriaName: any;
  CategoriaSelectList: any;
  CategoriaSelect: any;
  categorias: Categorias[] = [];

  // información adicinal
  infoNombreRecurso: any;
  infoDescripcion: any;
  infoUbicacion: any;
  buscarPersona: any;
  capacidadUso: any;

  // para las fechas
  fechaId: any;
  fechaActual: any;
  horaInicio: string | undefined;
  horaFin: string | undefined;
  horaInicioFor = '07:00:00';
  horaFinFor = '21:00:00';
  minutosUso!: number;
  minutosEspera!: number;
  fechaInicioChange: any;
  tiempoHome: any;
  capacidadMax: any;

  // información adicinal
  imgRuta: any;
  datepickers!: any;
  date: any;
  reservas: any;
  reservasTomorrow: any; // tomorrowany;
  divSize!: number;

  //show divs actions
  public editedInicio = false;
  public edited = false;
  public edited2 = false;
  public edited3 = false;
  public edited4 = false;
  public hoyDiv = true;
  public tomorrowDay = false;
  public edited3_1 = false;
  public editedInfo = false;
  public showMore = true;
  public showLess = false;
  public editedCerrar = true;
  canCheckbox: boolean = false;

  //action de cambio combo box
  verSeleccion: string | undefined;
  favoriteSeason: string | undefined;
  document: any;
  horaDiv: any;
  menus: Menu[] = [];

  // listar reservas dateLine
  myEventsV2: MbscCalendarEvent[] = [];
  myEventsStr: any;
  myResourcesStr: any;
  myResourcesStrs: MbscCalendarEvent[] = [];
  myResourcesChildren: any | undefined;
  myResourcesChildrenStr: MbscCalendarEvent[] = [];
  myResourcesChildrenStr1: MbscCalendarEvent[] = [];
  myResourcesChildrenStr2: MbscCalendarEvent[] = [];
  myResourcesChildrenStr3: MbscCalendarEvent[] = [];
  myResourcesChildrenStrs = ([] = []);

  @ViewChild('loadingScreens') loadingScreens!: ElementRef;
  @ViewChild('loadingScreen') loadingScreen!: ElementRef;
  @ViewChild('contentModal', { static: true }) contentModal!: ElementRef;

  noDay: any;
  hoursAtention: any;
  hoursAtentionStart: any;
  hoursAtentionEnd: any;
  noDayList: any;

  // individual column filtering
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  // shows Divs
  public docente = false;
  public estudiante = false;

  constructor(
    private reservaService: ReservasService,
    private title: Title,
    private http: HttpClient,
    private modalService: NgbModal,
    private recursoService: RecursoService,
    private pisosService: PisosService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private service: ServiceService,
    private cateService: CategoriaService,
    private controlService: ControllersService,
    private router: Router,
    config: NgbModalConfig,
    private notify: Notifications
  ) {
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.title.setTitle('Administrador de Reservas | Reservas');
    this.horaDiv = this.getFechaActualAndHora();
    this.getRolList();
    //this.getRecursosV2();
    //this.showEventInfoApp();
    //this.getReservasV2();

    window.setInterval(
      () => {
        // Ejemplo: Cada dos minutos esta actualizado las reservas
        //this.getReservasV2();
        //this.eventSettingsMT();
        var categoriaVar = this.CategoriaSelect.split('-_');
        //console.log(categoriaVar[0]);
        //console.log("categoriaVar: "+categoriaVar);
        this.getReservasV2(categoriaVar[0]);
        this.getRecursosV2(categoriaVar[0]);
        this.getValidateCat(categoriaVar[0]);
        this.eventSettingsMTId(categoriaVar[0]);
        this.horaDiv = this.getFechaActualAndHora();
      },
      // Intervalo de tiempo
      120000
    );

    setTimeout('document.location.reload()', 1800000);

    //setTimeout('document.location.reload()',120000);
  }

  selectCategory() {
    this.showEventInfoShowCategorias();

    //console.log('Categoria: ' + this.CategoriaSelect);
    this.CategoriaSelectList = this.CategoriaSelect.split('-_');
    //console.log('Datos: ' + this.CategoriaSelectList.length);
    if (this.CategoriaSelectList.length != 1) {
      var userStr = '';
      this.timeUseSelectList = this.CategoriaSelectList[1].split('_');

      this.tiempoUsoCategory = this.timeUseSelectList[1];
      this.tiempoUsoCategoryHoras = this.toHoursAndMinutes(this.timeUseSelectList[1]).hours;

      this.CategoriaName = this.timeUseSelectList[0];
      this.CategoriaId = this.CategoriaSelectList[0];

      this.eventSettingsMTId(this.CategoriaSelectList[0]);
      this.getReservasV2(this.CategoriaSelectList[0]);
      this.getRecursosV2(this.CategoriaSelectList[0]);
      this.getValidateCat(this.CategoriaSelectList[0]);
      //this.getSolicitudesListId(this.CategoriaSelectList[0]);
      Swal.close();
    } else {
      Swal.close();
    }
  }

  getNames() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      return this.profile.userPrincipalName;
    });
  }

  editar(reser: Categorias) {
    localStorage.setItem('id', reser.id.toString());
    this.reservas_save.categoriaReserva = reser.id;
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.roles = data.rol;
      });

      this.categoriaService
        .getCategoriaListRolToAdmin('' + this.user + '')
        .subscribe((data) => {
          this.categorias = data;
          for (let i = 0; i < this.categorias.length; i++) {
            if (i == 0) {
              //console.log(this.categorias[i]);
              this.CategoriaSelect =
                this.categorias[i].id +
                '-_' +
                this.categorias[i].nombreCategoria +
                '_' +
                this.categorias[i].tiempoUso;

              this.tiempoUsoCategory = this.categorias[i].tiempoUso;
              this.tiempoUsoCategoryHoras = this.toHoursAndMinutes(this.categorias[i].tiempoUso).hours;
              this.eventSettingsMTId(this.categorias[i].id);
              this.getReservasV2(this.categorias[i].id);
              this.getRecursosV2(this.categorias[i].id);
              this.getValidateCat(this.categorias[i].id);
              this.CategoriaName = this.categorias[i].nombreCategoria;
              this.capacidadMax = this.categorias[i].capacidad;
            } else {
              console.log('Otros');
            }
          }
        });
    });
  }

  toHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  getReservasV2(id: number): void {
    var userStr = '';
    this.reservaService.getReservasDateLinev3(id).subscribe(
      (data) => {
        this.myEvents = data;
        console.log(this.myEvents);
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getRecursosV2(id: number): void {
    var userStr = '';

    this.reservaService.getRecursosDateLinev3(id).subscribe(
      (data) => {
        this.myResourcesArrs = data;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  getDatosPersona() {
    //alert('hola: ' + this.buscarPersona);
    //getDatosPersona de la api
    this.showEventInfoSearch();
    var fileHead = '';
    fileHead = this.buscarPersona;

    if (fileHead == null) {
      // ocultar siguiente paso
      this.editedInicio = false;
      this.editedCerrar = false;
      this.reservas_save.namesReservador = '';
      this.reservas_save.emailReservador = '';

      this.toastService.show('¡El campo de búsqueda está vacio!', {
        classname: 'bg-warning',
        delay: 5000,
      });

      Swal.close();
    } else {
      this.service.getIdUser('' + fileHead + '').subscribe(
        (data) => {

          console.log("datos");
          console.log(data);
          if(data!=null){
            // añadiendo valor a las varibles de nombre y correo
            this.popupEventTitle = data.names + ' ' + data.namesPerson;
            this.popupEventDescription = data.rol;

            this.popupEventEmail = data.userEmail;

            Swal.close();

          } else {
            Swal.close();
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            });
            Toast.fire({
              icon: 'warning',
              title: 'Usuario no encontrado'
            })

          }

        },
        (err) => {
          // ocultar siguiente paso
          this.editedInicio = false;
          this.editedCerrar = false;
          this.reservas_save.namesReservador = '';
          this.reservas_save.emailReservador = '';

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          Toast.fire({
            icon: 'warning',
            title: 'Usuario no encontrado'
          })
          Swal.close();
        }
      );
    }
  }

  eventSettingsMTId(id: number) {
    this.cateService.getNoDia(id).subscribe((data) => {
      //console.log(data);
      var hoursAt;
      this.noDayList = data;
      this.noDay = this.noDayList.noDay;
      this.hoursAtention = this.noDayList.hourAtention;
      hoursAt = this.noDayList.hourAtention.split('-_');
      this.hoursAtentionStart = hoursAt[0];
      this.hoursAtentionEnd = hoursAt[1];
      console.log(this.noDayList.hourAtention);

      this.eventSettings = {
        invalid: [
          {
            recurring: {
              repeat: 'daily',
              until: yesterday,
            },
          },
          {
            recurring: {
              repeat: 'weekly',
              day: 24,
            },
          },
          {
            start: yesterday,
            end: today,
          },
          {
            recurring: {
              repeat: 'weekly',
              weekDays: this.noDay,
            },
          },

          {
            start: '07:00',
            end: '21:00',
            recurring: {
              repeat: 'daily',
              interval: 0,
            },
            resource: [
              'gro1',
              'gro2',
              'gro3',
              'gro4',
              'gro5',
              'gro6',
              'gro21',
              'gro22',
              'gro23',
              'gro24',
              'gro101',
              'gro41',
              'gro61',
              'gro81',
              'gro101',
              'gro121',
              'gro141',
              'gro142',
            ], // disable the parent resources with id 'gro2' and 'gro3'
          },
        ],
        clickToCreate: 'single',
        dragToCreate: true,
        dragToMove: true,
        dragToResize: true,
        onEventCreateFailed: (event) => {
          const oldEvent = event;
          console.log('onEventCreateFailed: ' + event.originEvent);
          //const start = oldEvent && oldEvent.start ? oldEvent.start : null;

          // handle recurring events

          if (!event.originEvent) {
            this.notify.toast({
              message: 'No se puede reservar.',
            });
          }
        },
        onEventUpdateFailed: (event) => {
          if (!event.oldEventOccurrence) {
            this.notify.toast({
              message: 'No se puede reservar.',
            });
          }
        },
        onEventClick: (args) => {
          this.isEditPassHours = true;
          this.isEdit = true;
          this.isSearch = false;
          //this.popupButtons = this.popupEditButtons;
          this.popupHeaderText = 'Eliminar reserva';
          this.popupAnchor = args.domEvent.currentTarget;
          this.tempEvent = args.event;
          // fill popup form with event data
          this.loadPopupForm(args.event);
          // set popup options
          // open the popup
          this.popup.open();
        },
        onEventCreated: (args) => {
          const oldEvent = args.event;
          const start = oldEvent && oldEvent.start ? oldEvent.start : null;

          // handle recurring events
          if (start && start < today) {
            this.notify.toast({
              message: 'No se puede reservar.',
            });
            return false;
          } else {
            setTimeout(() => {
              this.isEdit = false;
              this.tempEvent = args.event;
              // fill popup form with event data
              this.loadPopupForm(args.event);
              // set popup options
              this.popupHeaderText = 'Nueva reserva';
              this.popupButtons = this.popupAddButtons;

              this.popupAnchor = args.target;
              // open the popup
              this.popup.open();
            });
            return true;
          }
        },
        onEventUpdate: (args) => {
          const oldEvent = args.oldEvent;
          const start = oldEvent && oldEvent.start ? oldEvent.start : null;
          const oldEventOccurrence = args.oldEventOccurrence;
          const occurrenceStart =
            oldEventOccurrence && oldEventOccurrence.start
              ? oldEventOccurrence.start
              : null;

          var eventTimeStart = new Date('' + oldEvent.start + '');
          var eventTimeEnd = new Date('' + oldEvent.end + '');

          var entryHour = moment(eventTimeStart, 'hh:mm');
          var exitHour = moment(eventTimeEnd, 'hh:mm');

          //console.log('tempEvent: ' + eventTimeStart.getHours());
          let duration =
            '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
          //console.log('duration s' + duration);
          duration = duration?.replace('-', '');
          //console.log('duration str' + duration);
          var durationInt = parseInt(duration);
          // tiempo de duracion maximo de reserva
          var tiemposUso = parseInt(this.tiempoUsoCategory);
          var tiempoUsoCategorys = tiemposUso + 1;

          if (durationInt >= 30) {
            // validado para no pasar el tiempo
            if (durationInt < tiempoUsoCategorys) {
              //console.log('duration ' + durationInt);

              this.myEvents = [...this.myEvents, this.tempEvent];
              // here you can add the event to your storage as well
              // ...
            } else {
              this.notify.toast({
                message:
                  'No se puede reservar, la duración de la reserva debe ser menor a ' +
                  this.tiempoUsoCategory +
                  ' minutos.',
              });
            }
          } else {
            this.showEventInfoErrorMenor30min();
          }
        },
      };
      this.view = {
        timeline: {
          type: 'day',
          allDay: false,
          startDay: 0,
          endDay: 6,
          startTime: this.hoursAtentionStart,
          endTime: this.hoursAtentionEnd,
        },
      };
    });
  }

  getValidateCat(cat: number) {
    this.categoriaService.postValidate(cat).subscribe(
      (data) => {
        this.popupValidationPerson = data;
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  //eliminaión logica del registro
  deleteEnable(id: string) {
    this.showEventDeleteReserva();
    this.reservaService.deleteEnableReservaDateLine(id).subscribe((data) => {
      this.showSendMailError();
      Swal.close();
      //this.loadingScreens.nativeElement.style.display = 'none';
      location.reload();
    });
  }

  //fechas
  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaActualAndHora() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var hora = hoy.getHours();
    var min = hoy.getMinutes();
    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var mint = '';
    var horas = '';

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    if (hora < 10) {
      horas = '0' + hora;
    } else {
      horas = '' + hora;
    }
    if (min < 10) {
      mint = '0' + min;
    } else {
      mint = '' + min;
    }

    fechaActual =
      hoy.getFullYear() +
      '-' +
      months +
      '-' +
      diaSL +
      ' ' +
      horas +
      ':' +
      mint +
      ':' +
      hoy.getSeconds();
    return fechaActual;
  }

  getFechaManana() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();
    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }
  getDay(dateSelect: Date) {
    const fechaComoCadena = dateSelect; // día lunes
    const dias = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia;
    console.log('Nombre de día de la semana: ', nombreDia);
  }

  rolUsers() {
    if (this.users_save.rol != 'ESTUDIANTE') {
      this.docente = true;
      this.estudiante = false;
    } else {
      this.estudiante = true;
      this.docente = false;
    }
  }

  getDatosUser() {
    //getDatosPersona de la api
    this.showEventInfoSearchUser();
    var fileHead = '';
    fileHead = this.users_save.id;

    if (fileHead == null) {
      // ocultar siguiente paso
      this.editedInicio = false;
      this.editedCerrar = false;
      this.reservas_save.namesReservador = '';
      this.reservas_save.emailReservador = '';

      this.toastService.show('¡El campo de búsqueda está vacio!', {
        classname: 'bg-warning',
        delay: 5000,
      });

      Swal.close();
    } else {
      this.service.getPersonaUser('' + fileHead + '').subscribe(
        (data) => {
          // añadiendo valor a las varibles de nombre y correo
          this.users_save.names = data.names;
          this.users_save.namesPerson = data.namesPerson;
          this.users_save.username = data.username;
          this.users_save.userEmail = data.userEmail;
          this.users_save.rol = data.rol;
          this.users_save.modalidad = data.modalidad;
          this.users_save.nivelAcademico = data.nivelAcademico;
          this.users_save.programa = data.programa;
          this.users_save.facultad = data.facultad;
          this.users_save.departamento = data.departamento;
          this.users_save.centroUniversitario = data.centroUniversitario;
          this.users_save.enable = data.enable;
          console.log(data.enable);
          this.rolUsers();
          Swal.close();
        },
        (err) => {
          // ocultar siguiente paso
          this.users_save.names = '';
          this.users_save.namesPerson = '';
          this.users_save.username = '';
          this.users_save.userEmail = '';

          this.toastService.show(
            '¡Usuario no encontrado!',
            {
              classname: 'bg-warning',
              delay: 5000,
            }
          );

          Swal.close();
        }
      );
    }
  }

  guardarUser(user: Usuarios) {
    this.showEventInfoCreateUser();
    this.service.postUser(user).subscribe((data) => {
      Swal.close();
      this.showSuccess();
      this.showSendMail();
      location.reload();
    });
  }

  // control de la ventana emergente
  openLg2(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLg(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLgHome() {
    this.modalService.open(this.contentModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  // calendario
  @ViewChild('popup', { static: false })
  popup!: MbscPopup;
  @ViewChild('colorPicker', { static: false })
  colorPicker: any;
  state: any = true;
  popupEventTitle: string | undefined;
  popupEventEmail: string | undefined;
  popupEventNames: string | undefined;
  popupIdReserva: string | undefined;
  popupEventDescription = '';
  popupEventAllDay = true;
  popupEventDates: any;
  popupEventStatus = 'busy';
  popupCantidadAsistentes: any;
  popupValidationPerson: any;
  tiempoUsoCategoryHoras: any;
  calendarSelectedDate: any = new Date();
  switchLabel: any = 'All-day';
  tempColor = '';
  selectedColor = '';
  colorAnchor: HTMLElement | undefined;
  isEditPassHours = false;
  isSearch = true;
  popupNumberCapacidad: any;
  popupCapacidadNumber: number | undefined;

  colors = [
    '#ffeb3c',
    '#ff9900',
    '#f44437',
    '#ea1e63',
    '#9c26b0',
    '#3f51b5',
    '',
    '#009788',
    '#4baf4f',
    '#7e5d4e',
  ];

  eventSettings: MbscEventcalendarOptions = {
    invalid: [
      {
        recurring: {
          repeat: 'daily',
          until: yesterday,
        },
      },
      {
        start: yesterday,
        end: today,
      },

      {
        recurring: {
          repeat: 'weekly',
          weekDays: 'SU',
        },
      },

      {
        start: '12:00',
        end: '23:59',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA',
        },
      },
      {
        start: '07:00',
        end: '08:00',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA',
        },
      },
      {
        recurring: {
          repeat: 'daily',
        },
        resource: ['gro1', 'gro2', 'gro3', 'gro4'], // disable the parent resources with id 'gro2' and 'gro3'
      },
    ],
    clickToCreate: 'single',
    dragToCreate: true,
    dragToMove: true,
    dragToResize: true,
    onEventCreateFailed: (event) => {
      const oldEvent = event;
      console.log('onEventCreateFailed: ' + event.originEvent);
      //const start = oldEvent && oldEvent.start ? oldEvent.start : null;

      // handle recurring events

      if (!event.originEvent) {
        this.notify.toast({
          message: 'No se puede reservar.',
        });
      }
    },
    onEventUpdateFailed: (event) => {
      if (!event.oldEventOccurrence) {
        this.notify.toast({
          message: 'No se puede reservar.',
        });
      }
    },
    onEventClick: (args) => {
      this.isEditPassHours = true;
      this.isEdit = true;
      this.isSearch = false;
      //this.popupButtons = this.popupEditButtons;
      this.popupHeaderText = 'Eliminar reserva';
      this.popupAnchor = args.domEvent.currentTarget;
      this.tempEvent = args.event;
      // fill popup form with event data
      this.loadPopupForm(args.event);
      // set popup options
      // open the popup
      this.popup.open();
    },
    onEventCreated: (args) => {
      const oldEvent = args.event;
      const start = oldEvent && oldEvent.start ? oldEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message: 'No se puede reservar.',
        });
        return false;
      } else {
        setTimeout(() => {
          this.isEdit = false;
          this.tempEvent = args.event;
          // fill popup form with event data
          this.loadPopupForm(args.event);
          // set popup options
          this.popupHeaderText = 'Nueva reserva';
          this.popupButtons = this.popupAddButtons;

          this.popupAnchor = args.target;
          // open the popup
          this.popup.open();
        });
        return true;
      }
    },
    onEventUpdate: (args) => {
      const oldEvent = args.oldEvent;
      const start = oldEvent && oldEvent.start ? oldEvent.start : null;
      const oldEventOccurrence = args.oldEventOccurrence;
      const occurrenceStart =
        oldEventOccurrence && oldEventOccurrence.start
          ? oldEventOccurrence.start
          : null;

      var eventTimeStart = new Date('' + oldEvent.start + '');
      var eventTimeEnd = new Date('' + oldEvent.end + '');

      var entryHour = moment(eventTimeStart, 'hh:mm');
      var exitHour = moment(eventTimeEnd, 'hh:mm');

      console.log('tempEvent: ' + eventTimeStart.getHours());
      let duration = '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
      console.log('duration s' + duration);
      duration = duration?.replace('-', '');
      console.log('duration str' + duration);
      var durationInt = parseInt(duration);
      // tiempo de duracion maximo de reserva
      var tiemposUso = parseInt(this.tiempoUsoCategory);
      var tiempoUsoCategorys = tiemposUso + 1;

      if (durationInt >= 30) {
        // validado para no pasar el tiempo
        if (durationInt < tiempoUsoCategorys) {
          console.log('duration ' + durationInt);

          this.myEvents = [...this.myEvents, this.tempEvent];
          // here you can add the event to your storage as well
          // ...
        } else {
          this.notify.toast({
            message:
              'No se puede reservar, la duración de la reserva debe ser menor a ' +
              this.tiempoUsoCategory +
              ' minutos.',
          });
        }
      } else {
        this.showEventInfoErrorMenor30min();
      }
    },
  };

  view: MbscEventcalendarView = {};

  myEvents: MbscCalendarEvent[] = [];

  myResourcesArr: MbscCalendarEvent[] = [];
  myResourcesArrs: any[] = [];

  tempEvent!: MbscCalendarEvent;

  popupHeaderText!: string;
  popupAnchor: HTMLElement | undefined;
  /*
    botones para activar las acciones del line time
  */
  popupAddButtons = [
    'cancel',
    {
      handler: () => {
        this.saveEvent();
      },
      keyCode: 'enter',
      text: 'Guardar',
      cssClass: 'mbsc-popup-button-primary',
    },
  ];
  popupSearchButtons = [
    'cancel',
    {
      handler: () => {
        this.getDatosPersona();
      },
      keyCode: 'enter',
      text: 'Guardar',
    },
  ];
  popupEditButtons = [
    'cancel',
    {
      handler: () => {
        this.editEvent();
      },
      keyCode: 'enter',
      text: 'Actualizar',
      cssClass: 'mbsc-popup-button-primary',
    },
  ];
  popupButtons: any = [];
  popupOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    fullScreen: true,
    onClose: () => {
      if (!this.isEdit) {
        // refresh the list, if add popup was canceled, to remove the temporary event
        this.myEvents = [...this.myEvents];
      }
    },
    responsive: {
      medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false,
      },
    },
  };

  datePickerResponsive: any = {
    medium: {
      controls: ['calendar'],
      touchUi: false,
    },
  };
  datetimePickerControls = ['time'];
  datetimePickerResponsive = {
    medium: {
      controls: ['calendar', 'time'],
      touchUi: false,
    },
  };
  datePickerOptions: MbscDatepickerOptions = {
    controls: ['time'],
    select: 'range',
    showRangeLabels: false,
    touchUi: false,
    stepMinute: 30,
    timeWheels: '|h:mm A|',
  };
  isEdit = false;
  colorOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    showArrow: false,
    showOverlay: false,
    buttons: [
      'cancel',
      {
        text: 'Set',
        keyCode: 'enter',
        handler: (ev) => {
          this.selectedColor = this.tempColor;
          this.colorPicker.close();
        },
        cssClass: 'mbsc-popup-button-primary',
      },
    ],
    responsive: {
      medium: {
        display: 'anchored',
        buttons: [],
      },
    },
  };
  loadPopupForm(event: MbscCalendarEvent): void {
    var names = this.getNames();

    console.log("this.popupEventEmail: "+event.email);
    if(event.email == undefined || this.popupEventEmail == ""){
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }

    this.popupEventTitle = event.names;

    this.service.RolUser('' + event.email + '').subscribe((data) => {
      this.popupEventDescription = data.rol;
    });
    this.popupEventEmail = event.email;
    this.popupEventDates = [event.start, event.end];
    this.popupEventStatus = event.status || 'busy';
    this.popupIdReserva = event.idReserva;
    this.selectedColor = event.color || '';
    this.popupTemaReserva = event.asuntoReserva;
    this.popupDescripReserva = event.descripcionReserva;
    this.popupCantidadAsistentes = event.totalAsistentes;

    var idResource = event.resource;

    for (var i = 0; i < this.myResourcesArrs.length; i++) {
      for (var j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idResource == this.myResourcesArrs[i].children[j].id) {
          console.log('datos sdsadas');
          console.log(this.myResourcesArrs[i].children[j].capacidad);
          console.log(this.myResourcesArrs[i].children[j]);
          this.popupNumberCapacidad =
            'Máximo para ' +
            this.myResourcesArrs[i].children[j].capacidad +
            ' persona (s)';
            this.popupCapacidadNumber = this.myResourcesArrs[i].children[j].capacidad;
        }
      }
    }
  }
  saveEvent(): void {
    this.tempEvent.title = this.popupEventTitle;
    this.tempEvent.description = this.popupEventDescription;
    this.tempEvent.email = this.popupEventEmail;
    this.tempEvent.start = this.popupEventDates[0];
    this.tempEvent.end = this.popupEventDates[1];
    this.tempEvent.status = this.popupEventStatus;
    this.tempEvent.color = this.selectedColor;

    //this.recurso.nombreRecurso = this.;
    console.log('this.tempEvent.id: ' + this.tempEvent.id);

    var eventTimeStart = new Date('' + this.tempEvent.start + '');
    var eventTimeEnd = new Date('' + this.tempEvent.end + '');

    var idRecursoInt = 0;
    var idPisoInt = 0;
    var idPisoInts = 0;
    var nameRecursoStr = '';
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idPisoInts = parseInt('' + this.tempEvent + '');

    for (let i = 0; i < this.myResourcesArrs.length; i++) {
      for (let j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idRecursoInt == this.myResourcesArrs[i].children[j].id) {
          idPisoInt = this.myResourcesArrs[i].children[j].piso;
          nameRecursoStr = this.myResourcesArrs[i].children[j].name;
        }
      }
    }

    console.log('idPisoInt: ' + idPisoInt);

    var fechaResult = this.dateSelect(eventTimeStart, eventTimeEnd);
    var fechaResultStr = fechaResult.split('_');
    var eventTimeStartStr = fechaResultStr[0];
    var eventTimeEndStr = fechaResultStr[1];

    // datos que se envia para guardar una reserva de la version 2
    this.reservas_save_date_line.emailReservador = this.popupEventEmail;
    this.reservas_save_date_line.pisoReserva = idPisoInt;
    this.reservas_save_date_line.idRecurso = idRecursoInt;
    this.reservas_save_date_line.materialReservado = nameRecursoStr;
    this.reservas_save_date_line.fechaReservaInicio = eventTimeStartStr;
    this.reservas_save_date_line.fechaReservaFin = eventTimeEndStr;
    this.reservas_save_date_line.namesReservador =
      this.popupEventTitle?.replace('Reserva - ', '');

    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    this.reservas_save_date_line.numeroAsistentes = this.popupCantidadAsistentes;
    if (this.isEdit) {
      // add the new event to the list
      const start =
        this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message:
            'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
        });
      } else {
        var entryHour = moment(eventTimeStart, 'hh:mm');
        var exitHour = moment(eventTimeEnd, 'hh:mm');
        let duration =
          '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
        duration = duration?.replace('-', '');
        var durationInt = parseInt(duration);
        // tiempo de duracion maximo de reserva
        var tiemposUso = parseInt(this.tiempoUsoCategory);
        var tiempoUsoCategorys = tiemposUso + 1;

        // validado para no pasar el tiempo
        if (durationInt < tiempoUsoCategorys) {
          // update the event in the list
          this.myEvents = [...this.myEvents];
          // here you can update the event in your storage as well
          // ...
        } else {
          this.notify.toast({
            message:
              'No se puede reservar, la duración de la reserva debe ser menor a ' +
              this.tiempoUsoCategory +
              ' minutos.',
          });
        }
      }
    } else {
      // add the new event to the list
      const start =
        this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message:
            'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
        });
      } else {
        var eventTimeStart = new Date('' + this.tempEvent.start + '');
        var eventTimeEnd = new Date('' + this.tempEvent.end + '');

        var entryHour = moment(eventTimeStart, 'hh:mm');
        var exitHour = moment(eventTimeEnd, 'hh:mm');

        console.log('tempEvent: ' + eventTimeStart.getHours());
        let duration =
          '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
        console.log('duration s' + duration);
        duration = duration?.replace('-', '');
        console.log('duration str' + duration);
        var durationInt = parseInt(duration);
        // tiempo de duracion maximo de reserva
        var tiemposUso = parseInt(this.tiempoUsoCategory);
        var tiempoUsoCategorys = tiemposUso + 1;


        if(this.popupValidationPerson == 1){
          this.popup.close();
          if(this.popupTemaReserva == undefined || this.popupTemaReserva == "" || this.popupDescripReserva == undefined || this.popupDescripReserva == ""){
            this.showEventErrorNoTimeDescrip();
            this.popup.open();
          } else {
            this.popup.close();
            if (durationInt >= 30) {
              // validado para no pasar el tiempo
              if (durationInt < tiempoUsoCategorys) {
                this.showEventInfoOk();
                //this.loadingScreen.nativeElement.style.display = 'block';
                this.reservaService
                  .postReservaDateLine(this.reservas_save_date_line)
                  .subscribe(
                    (data) => {
                      //this.showSuccess();
                      this.showSendMail();
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                    },
                    (err) => {
                      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                      if (err.error == 'Aforo completo') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-_');
                        this.getRecursosV2(categoriaVar[0]);
                        this.getReservasV2(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorAforo();
                        //this.loadingScreen.nativeElement.style.display = 'none';
                        //this.showErrorAforo();
                      } else if (err.error == 'No tiene acceso al compus') {
                        Swal.close();
                        this.showEventInfoErrorNoAccess();
                        var categoriaVar = this.CategoriaSelect.split('-_');
                        this.getRecursosV2(categoriaVar[0]);
                        this.getReservasV2(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        //this.loadingScreen.nativeElement.style.display = 'none';
                        //this.showErrorNoAccess();
                      } else if (err.error == 'Reservas excedidas') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-_');
                        this.getRecursosV2(categoriaVar[0]);
                        this.getReservasV2(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoError();
                        //this.loadingScreen.nativeElement.style.display = 'none';
                        //this.showErrorEx();
                      } else if (err.error == 'Maximo dias') {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-_');
                        this.getRecursosV2(categoriaVar[0]);
                        this.getReservasV2(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorMaxDias();
                        //this.loadingScreen.nativeElement.style.display = 'none';
                        //this.showErrorEx();
                      } else {
                        Swal.close();
                        var categoriaVar = this.CategoriaSelect.split('-_');
                        this.getRecursosV2(categoriaVar[0]);
                        this.getReservasV2(categoriaVar[0]);
                        this.getValidateCat(categoriaVar[0]);
                        this.eventSettingsMTId(categoriaVar[0]);
                        this.showEventInfoErrorSave();
                        //this.loadingScreen.nativeElement.style.display = 'none';
                        //this.showErrorSave();
                      }
                    }
                  );
              } else {
                this.notify.toast({
                  message:
                    'No se puede reservar, la duración de la reserva debe ser menor a ' +
                    this.tiempoUsoCategory +
                    ' minutos.',
                });
              }
            } else {
              this.showEventInfoErrorMenor30min();
            }
          }
        } else {
          if (durationInt >= 30) {
            // validado para no pasar el tiempo
            if (durationInt < tiempoUsoCategorys) {
              this.showEventInfoOk();
              //this.loadingScreen.nativeElement.style.display = 'block';
              this.reservaService
                .postReservaDateLine(this.reservas_save_date_line)
                .subscribe(
                  (data) => {
                    //this.showSuccess();
                    this.showSendMail();
                    Swal.close();
                    var categoriaVar = this.CategoriaSelect.split('-_');
                    this.getRecursosV2(categoriaVar[0]);
                    this.getReservasV2(categoriaVar[0]);
                    this.getValidateCat(categoriaVar[0]);
                    this.eventSettingsMTId(categoriaVar[0]);
                  },
                  (err) => {
                    // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                    if (err.error == 'Aforo completo') {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoErrorAforo();
                      //this.loadingScreen.nativeElement.style.display = 'none';
                      //this.showErrorAforo();
                    } else if (err.error == 'No tiene acceso al compus') {
                      Swal.close();
                      this.showEventInfoErrorNoAccess();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      //this.loadingScreen.nativeElement.style.display = 'none';
                      //this.showErrorNoAccess();
                    } else if (err.error == 'Reservas excedidas') {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoError();
                      //this.loadingScreen.nativeElement.style.display = 'none';
                      //this.showErrorEx();
                    } else if (err.error == 'Maximo dias') {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoErrorMaxDias();
                      //this.loadingScreen.nativeElement.style.display = 'none';
                      //this.showErrorEx();
                    } else {
                      Swal.close();
                      var categoriaVar = this.CategoriaSelect.split('-_');
                      this.getRecursosV2(categoriaVar[0]);
                      this.getReservasV2(categoriaVar[0]);
                      this.getValidateCat(categoriaVar[0]);
                      this.eventSettingsMTId(categoriaVar[0]);
                      this.showEventInfoErrorSave();
                      //this.loadingScreen.nativeElement.style.display = 'none';
                      //this.showErrorSave();
                    }
                  }
                );
            } else {
              this.notify.toast({
                message:
                  'No se puede reservar, la duración de la reserva debe ser menor a ' +
                  this.tiempoUsoCategory +
                  ' minutos.',
              });
            }
          } else {
            this.showEventInfoErrorMenor30min();
          }
          // close the popup
          this.popup.close();
        }


      }
    }
    // navigate the calendar
    this.calendarSelectedDate = this.popupEventDates[0];
    // close the popup
    this.popup.close();
  }

  editEvent(): void {
    this.tempEvent.start = this.popupEventDates[0];
    this.tempEvent.end = this.popupEventDates[1];

    var eventTimeStart = new Date('' + this.tempEvent.start + '');
    var eventTimeEnd = new Date('' + this.tempEvent.end + '');

    var fechaResult = this.dateSelect(eventTimeStart, eventTimeEnd);
    var fechaResultStr = fechaResult.split('_');
    var eventTimeStartStr = fechaResultStr[0];
    var eventTimeEndStr = fechaResultStr[1];

    var idRecursoInt = 0;
    var idPisoInt = 0;
    var idEdit = 0;
    var nameRecursoStr = '';
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');

    for (let i = 0; i < this.myResourcesArrs.length; i++) {
      for (let j = 0; j < this.myResourcesArrs[i].children.length; j++) {
        if (idRecursoInt == this.myResourcesArrs[i].children[j].id) {
          idPisoInt = this.myResourcesArrs[i].children[j].piso;
          nameRecursoStr = this.myResourcesArrs[i].children[j].name;
        }
      }
    }

    var idStr = '';
    idRecursoInt = parseInt('' + this.tempEvent.resource + '');
    idEdit = parseInt('' + this.popupIdReserva + '');

    // datos que se envia para guardar una reserva de la version 2
    this.reservas_save_date_line.id = idEdit;
    this.reservas_save_date_line.emailReservador = this.popupEventEmail;
    this.reservas_save_date_line.pisoReserva = idPisoInt;
    this.reservas_save_date_line.idRecurso = idRecursoInt;
    this.reservas_save_date_line.materialReservado = nameRecursoStr;
    this.reservas_save_date_line.fechaReservaInicio = eventTimeStartStr;
    this.reservas_save_date_line.fechaReservaFin = eventTimeEndStr;
    this.reservas_save_date_line.namesReservador =
      this.popupEventTitle?.replace('Reserva - ', '');

    this.reservas_save_date_line.temaReserva = this.popupTemaReserva;
    this.reservas_save_date_line.descripcionReserva = this.popupDescripReserva;
    this.reservas_save_date_line.numeroAsistentes = this.popupNumberCapacidad;

    if (this.isEdit) {
      // add the new event to the list
      const start =
        this.tempEvent && this.tempEvent.start ? this.tempEvent.start : null;

      // handle recurring events
      if (start && start < today) {
        this.notify.toast({
          message:
            'No se puede reservar, esta fuera del rango del horario de inicio de la reserva.',
        });
      } else {
        var eventTimeStart = new Date('' + this.tempEvent.start + '');
        var eventTimeEnd = new Date('' + this.tempEvent.end + '');

        var entryHour = moment(eventTimeStart, 'hh:mm');
        var exitHour = moment(eventTimeEnd, 'hh:mm');

        let duration =
          '' + moment.duration(entryHour.diff(exitHour)).asMinutes();
        duration = duration?.replace('-', '');
        console.log('duration str' + duration);

        var durationInt = parseInt(duration);
        // tiempo de duracion maximo de reserva
        var tiemposUso = parseInt(this.tiempoUsoCategory);
        var tiempoUsoCategorys = tiemposUso + 1;

        // validado para no pasar el tiempo
        if (durationInt < tiempoUsoCategorys) {
          this.popup.close();
          this.showEventInfoPut();
          this.reservaService
            .putReservaDateLine(this.reservas_save_date_line)
            .subscribe(
              (data) => {
                this.showSendMailUpdate();
                Swal.close();

                // navigate the calendar
                this.calendarSelectedDate = this.popupEventDates[0];
                // close the popup
                location.reload();
                //this.router.navigate(['listarReservas']);
              },
              (err) => {
                this.popup.close();
                // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
                if (err.error == 'Reservas excedidas') {
                  Swal.close();
                  this.showEventInfoError();
                } else {
                  Swal.close();
                  this.showEventInfoErrorSave();
                }
              }
            );
        } else {
          this.notify.toast({
            message:
              'No se puede reservar, la duración de la reserva debe ser menor a ' +
              this.tiempoUsoCategory +
              ' minutos.',
          });
        }
      }
    }
  }

  deleteEvent(event: MbscCalendarEvent): void {
    this.myEvents = this.myEvents.filter((item) => item.id !== event.id);
    this.deleteEnable('' + event.idReserva);

    this.notify.snackbar({
      button: {
        action: () => {
          this.myEvents = [...this.myEvents, event];
        },
        text: 'ok',
      },
      message: 'Reserva Eliminada',
    });

    // here you can delete the event from your storage as well
    // ...
  }

  onDeleteClick(): void {
    this.deleteEvent(this.tempEvent);
    this.popup.close();
  }

  selectColor(color: string): void {
    this.tempColor = color;
  }

  openColorPicker(ev: any): void {
    this.selectColor(this.selectedColor || '');
    this.colorAnchor = ev.currentTarget;
    this.colorPicker.open();
  }

  changeColor(ev: any): void {
    const color = ev.currentTarget.getAttribute('data-value');
    this.selectColor(color);

    if (!this.colorPicker.s.buttons.length) {
      this.selectedColor = color;
      this.colorPicker.close();
    }
  }

  dateSelect(eventTimeStart: Date, eventTimeEnd: Date) {
    let MonthStartStr = null;
    let DayStartStr = null;
    MonthStartStr = eventTimeStart.getMonth() + 1;
    if (MonthStartStr < 10) {
      MonthStartStr = '0' + MonthStartStr;
    } else {
      MonthStartStr = MonthStartStr;
    }
    DayStartStr = eventTimeStart.getDate();
    if (DayStartStr < 10) {
      DayStartStr = '0' + DayStartStr;
    } else {
      DayStartStr = DayStartStr;
    }

    let MonthEndStr = null;
    let DayEndStr = null;
    MonthEndStr = eventTimeEnd.getMonth() + 1;
    if (MonthEndStr < 10) {
      MonthEndStr = '0' + MonthEndStr;
    } else {
      MonthEndStr = MonthEndStr;
    }
    DayEndStr = eventTimeEnd.getDate();
    if (DayEndStr < 10) {
      DayEndStr = '0' + DayEndStr;
    } else {
      DayEndStr = DayEndStr;
    }

    let MinutesStartStr = null;
    let MinutesEndStr = null;
    MinutesStartStr = eventTimeStart.getMinutes();
    if (MinutesStartStr < 10) {
      MinutesStartStr = '0' + MinutesStartStr;
    } else {
      MinutesStartStr = MinutesStartStr;
    }

    MinutesEndStr = eventTimeEnd.getMinutes();
    if (MinutesEndStr < 10) {
      MinutesEndStr = '0' + MinutesEndStr;
    } else {
      MinutesEndStr = MinutesEndStr;
    }

    var eventTimeStartStr =
      eventTimeStart.getFullYear() +
      '-' +
      MonthStartStr +
      '-' +
      DayStartStr +
      ' ' +
      eventTimeStart.getHours() +
      ':' +
      MinutesStartStr +
      ':00';
    var eventTimeEndStr =
      eventTimeEnd.getFullYear() +
      '-' +
      MonthEndStr +
      '-' +
      DayEndStr +
      ' ' +
      eventTimeEnd.getHours() +
      ':' +
      MinutesEndStr +
      ':00';

    return '' + eventTimeStartStr + '_' + eventTimeEndStr;
  }

  //eventos para mostrar el estado de la acción de información del recurso
  showEventInfoOk() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos validando su reserva. Una vez finalizado este proceso, recibirá un correo electrónico de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoError() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de reservas que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorMenor30min() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'Recuerdo que las reservas deben tener una duración mínima de 30 minutos.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },

      //delay: 6000,
    });
  }
  showEventInfoErrorSave() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }

  showEventInfoErrorMaxDias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Días aún no disponibles de reservas.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }

  showEventInfoErrorAforo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Aforo completo para el horario que seleccionó.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorNoAccess() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventDeleteReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Reserva eliminada',
      text: 'Estamos eliminando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoPut() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos modificando su reserva y una vez terminada le llegará un correo de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoCreateUser() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos validando al información y creando la cuenta.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoApp() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Recuerde',
      text: 'Está aplicación es de uso exclusivo para pruebas, todas las reservas que hagan serán solo para pruebas y no se guardarán en la base de datos.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Estoy de acuerdo',
    });
  }

  showEventInfoSearchUser() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos validando al información.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }

  showEventInfoSearch() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      text: 'Estamos recuperando los datos del usuario.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }

  showEventInfoShowCategorias() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espere un momento',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }

  showEventErrorNoTimeDescrip() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title: 'Es necesario ingresar el tema, la descripción y el número de participantes.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
    //location.reload();
  }

  //alertas
  showSuccess() {
    this.toastService.show('💾 Reserva añadido con éxito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSuccesUpdate() {
    this.toastService.show('💾 Reserva modificada con éxito...!!!', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMail() {
    this.toastService.show('📤  Reserva creada correctamente', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSendMailUpdate() {
    this.toastService.show('📤  Reserva modificada correctamente', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMailError() {
    this.toastService.show('📢 Reserva eliminada correctamente', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  showRecursos() {
    this.toastService.show('📢 Recursos de biblioteca cargados correctamente', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showNoRecursosV2() {
    this.toastService.show(
      '📢  Todos los recursos han sido usados para la hora seleccionada.',
      {
        classname: 'bg-warning',
        delay: 4000,
      }
    );
  }
  showNoRecursos() {
    this.toastService.show(
      '📢  No existe ningún recurso relacionado con su búsqueda',
      {
        classname: 'bg-danger text-light',
        delay: 3000,
      }
    );
  }
  showAlertTiempoUso() {
    this.toastService.show('📢 Recuerda: Las reserva se realizan para el día', {
      classname: 'bg-info',
      delay: 8000,
    });
  }
  showError() {
    this.toastService.show(
      '⚠ Error al cargar el recurso. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorDate() {
    this.toastService.show(
      '⚠ Error: La fecha seleccionada no puede ser menor a la actual',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorSave() {
    this.toastService.show(
      '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoAccess() {
    this.controlService.getControllers().subscribe((data) => {
      this.toastService.show(
        '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }
  showErrorNoDia() {
    var diasNo = '';
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        diasNo = data[0].diasAtencion;
      }
      this.toastService.show(
        '⚠ Error: No se puede reservar para le fecha seleccionado | ' +
          diasNo +
          '.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }

  showErrorNoHora() {
    this.toastService.show(
      '⚠ Error: Reservas finalizadas por hoy. Si desea realizar una reserva para mañana.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorEx() {
    this.toastService.show(
      '⚠ Error: Solo puede realizar una reserva por día.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorVacio() {
    this.toastService.show('⚠ Error: Esta vacío la seleccionado', {
      classname: 'bg-warning',
      delay: 4000,
    });
  }
  showErrorAforo() {
    this.toastService.show(
      '⚠ Error: Aforo completo para el horario que seleccionó.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  openInfoAlert() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Se comunica que el día de hoy viernes el horario de atención será de 08:00 a 11:00 am. El día lunes retomamos en nuestro horario habitual.',
      footer: 'Agradecemos su comprensión.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 8000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
}
