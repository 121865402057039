<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2" *ngIf=" roles == 'ADMINISTRADOR'">
  <mat-card class="colorCard text-center">
    <h1>Listado de Recursos | Reservas UTPL</h1>
  </mat-card>
  <br>
  <div class="d-flex justify-content-end">
    <button class="btn colorUTPLModal m-1" (click)="openLg(content)">Añadir Recurso</button>
    <button class="btn colorUTPLBotton m-1" placement="left" ngbTooltip="Información"><i
        class="bi bi-info-circle"></i></button>
  </div>
  <hr>
  <br>
  <div id="textP">

  </div>
  <div class="row">

  </div>
  <div class="p-2 table-responsive justify-content-center">
    <table datatable [dtOptions]="dtOptions" class="row-border hover example table table-striped table-sm"
    *ngIf="recursos.length">

      <thead>
        <tr>
          <th class="vrt-align-center"> Recurso </th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Capacidad</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </thead>
      <tbody>
        <!--<tr  *ngIf="registro.opcionTitulacion==='Tesis'" > -->

        <tr *ngFor="let recurso of recursos" class="vrt-align">
          <th class="vrt-align-center">{{recurso.nombreRecurso}}</th>
          <td class="vrt-align-center">{{recurso.id}} </td>
          <td class="vrt-align-center">{{recurso.ubicacion}} </td>
          <td class="vrt-align-center">{{recurso.capacidad}} </td>
          <td class="vrt-align-center">
            <!-- <button (click)="update(registro)" type="button" class="btn btn-warning m-2">Editar</button>-->
            <button class="btn btn-warning m-2" (click)="updateShow(recurso)" (click)="open(update)"
              ngbTooltip="Editar"><i class="bi bi-pencil-square"></i></button>
            <button (click)="deleteEnable(recurso)" type="button" class="btn btn-danger " ngbTooltip="Deshabilitar">
              <i class="bi bi-patch-exclamation-fill"></i></button>
            <button (click)="updateShow(recurso)" (click)="openLg(duplicar)" class="btn colorUTPLBottonDpl m-2"
              placement="top" ngbTooltip="Duplicar"><i class="bi bi-clipboard-check"></i></button>
          </td>
        </tr>

      </tbody>

      <tfoot>
        <tr>
          <th class="vrt-align-center">Categoría</th>
          <th scope="col" class="vrt-align-center">Descripción</th>
          <th scope="col" class="vrt-align-center">Ubicación</th>
          <th scope="col" class="vrt-align-center">Capacidad</th>
          <th scope="col" class="vrt-align-center">Editar y Deshabilitar</th>
        </tr>
      </tfoot>
    </table>
  </div>
</section>

<section class="m-2" *ngIf="roles == 'ESTUDIANTE' || roles == 'DOCENTE'  || roles == 'BIBLIOTECARIO' || roles == 'ADMINISTRATIVO' || roles == 'null' || roles == 'ADMIN-BBC' || roles == 'AUTORIDADES'">
  <div class="container" >

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<!-- formulario para registar-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h3 class="modal-title" id="modal-basic-title">Formulario registro de recursos de Biblioteca</h3>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del recurso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="recurso_save.nombreRecurso">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="recurso_save.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #ubicacion
            [(ngModel)]="recurso_save.ubicacion">
        </div>
        <div class="col-md-12 mb-2">
          <label for="capacidad" class="form-label"> Capacidad de uso
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="capacidad" id="capacidad" name="capacidad"
              aria-describedby="basic-addon2" [(ngModel)]="recurso_save.capacidad">
            <span class="input-group-text" id="basic-addon2">Personas</span>
          </div>
        </div>
        <div class="col-12 bg-light ">
          <label for="idCategoria" class="form-label">Categoría<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idCategoria" id="idCategoria"
            [(ngModel)]="recurso_save.idCategoria">
            <option *ngFor="let bsc of categorias" value="{{bsc.id}}">{{bsc.nombreCategoria}}</option>
          </select>
        </div>

        <div class="col-12 bg-light ">
          <label for="idPiso" class="form-label">Subcategoría<i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idPiso" id="idPiso"
            [(ngModel)]="recurso_save.idPiso">
            <option *ngFor="let bsc of pisos" value="{{bsc.id}}">{{bsc.nombrePiso}}</option>
          </select>
        </div>


        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" [disabled]="!recurso_save.ubicacion" (click)="saveRegister(recurso_save)"
          (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario para editar-->
<ng-template #update let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h2 class="modal-title" id="modal-basic-title">Actualizar registro de recursos de Biblioteca</h2>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2 ">
          <label for="nombreCategoria" class="form-label">Nombre del recurso
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
            [(ngModel)]="recurso_update.nombreRecurso">
        </div>

        <br><br>
        <div class="col-md-12 mb-2">
          <label for="descripcion" class="form-label"> Descripción
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="descripcion" name="descripcion" #descripcion
            [(ngModel)]="recurso_update.descripcion">
        </div>
        <br><br>
        <div class="col-md-12 mb-2">
          <label for="ubicacion" class="form-label"> Ubicación
            <i style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="ubicacion" name="ubicacion" #ubicacion
            [(ngModel)]="recurso_update.ubicacion">
        </div>
        <div class="col-md-12 mb-2">
          <label for="capacidad" class="form-label"> Capacidad de <i class="fas fa-user-clock    "></i>
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="capacidad" id="capacidad" name="capacidad"
              aria-describedby="basic-addon2" [(ngModel)]="recurso_update.capacidad">
            <span class="input-group-text" id="basic-addon2">Personas</span>
          </div>
        </div>
        <br>

        <div class="col-12 bg-light ">
          <label for="idCategoria" class="form-label">Categoría<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idCategoria" id="idCategoria"
            [(ngModel)]="recurso_update.idCategoria">
            <option *ngFor="let bsc of categorias" value="{{bsc.id}}">{{bsc.nombreCategoria}}</option>
          </select>
        </div>

        <div class="col-12 bg-light ">
          <label for="idPiso" class="form-label">Subcategoría<i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idPiso" id="idPiso"
            [(ngModel)]="recurso_update.idPiso">
            <option *ngFor="let bsc of pisos" value="{{bsc.id}}">{{bsc.nombrePiso}}- {{bsc.ubicacion}}</option>
          </select>
        </div>


        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6">
        <button type="submit" (click)="actualizar(recurso_update)" (click)="d('Cross click')"
          class="btn btn-warning">Actualizar
        </button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario para duplicar-->
<ng-template #duplicar let-c="close" let-d="dismiss">
  <div class="modal-header modal-lg">
    <h3 class="modal-title" id="modal-basic-title">Formulario para duplicar</h3>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 mb-2 ">
        <label for="nombreCategoria" class="form-label">Nombre de la categoría a duplicar
          <i style="color: red;"><strong>*</strong></i></label>
        <input type="text" class="form-control" id="nombreCategoria" name="nombreCategoria" #nombreCategoria
          [(ngModel)]="recurso_update.nombreRecurso" disabled>
      </div>
    </div>
    <form action="" method="post" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-12 mb-2">
          <label for="capacidad" class="form-label"> Número de copias
            <i style="color: red;"><strong>*</strong></i></label>
          <div class="input-group mb-3">
            <input type="number" class="form-control" aria-label="capacidad" id="capacidad" name="capacidad"
              aria-describedby="basic-addon2" [(ngModel)]="numeroDuplicar">
            <span class="input-group-text" id="basic-addon2">Copias del recurso</span>
          </div>
        </div>


        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <!---(click)="duplicarRegistro(recurso_save)"-->
      <div class="col-md-6">
        <button type="submit" (click)="duplicarRegistro(recurso_update)"
          class="btn colorUTPLBottonDpl ">Duplicar</button>
      </div>
      <div class="col-md-6  col-md-offset-2">
        <button type="button" class="btn btn-danger" (click)="c('Save click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
