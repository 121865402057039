export class RecursoSave {
  id!: number;
  nombreRecurso!: string;
  descripcion!: string;
  ubicacion!: string;
  estadoUso!: string;
  capacidad!: number;
  enable!: number;
  idPiso!: number | undefined;
  idCategoria!: number | undefined;
}
