import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Roles } from '../model/Roles';
import { FileService } from '../Service/file.service';
import { RolService } from '../Service/rol.service';
import { ServiceService } from '../Service/service.service';
import { ToastService } from '../Service/toast.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
})
export class RolesComponent implements OnInit, AfterViewInit, OnDestroy {
  // Update img
  //URL BASE
  URL_BASE = environment.HOST;
  //URL TO IMG SAVE
  UrlIMG = this.URL_BASE + '/api/v1/imgbiblio';
  selectedFileNme: File | undefined;

  // data table
  profile!: ProfileType;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  rol: Roles[] = [];
  rolesSave: Roles = new Roles();
  rolesUpdate: Roles = new Roles();
  selectedFile: File | undefined;
  nameImage: string | undefined;
  fileName: File | undefined;
  rutaImage: string | undefined;
  user: string | undefined;
  rols: any;

  constructor(
    private modalService: NgbModal,
    private title: Title,
    usernameElement: ElementRef,
    private rolService: RolService,
    private toastService: ToastService,
    private http: HttpClient,
    private fileService: FileService,
    private service: ServiceService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Roles | Reservas');
    this.getRolList();
    this.rolesList();

  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.rols = data.rol;
      });
    });
  }

  // listar categorias
  rolesList() {
    this.rolService.getRoles().subscribe((data) => {
      this.rol = data;
      this.dtOptions = {
        pagingType: 'full_numbers',
        processing: true,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };

      this.dtTrigger.next();
    });
  }


  // agregar registro
  /*saveRegister(cate: Categorias) {
    this.cateService.postCategorias(cate).subscribe((data) => {
      this.showSuccess();
      location.reload();
    });
  }
  */

  // actualizar registros
  updateShow(cate: Roles): void {
    localStorage.setItem('id', cate.idRol.toString());
    console.log();
    this.rolesUpdate.name = cate.name;
    this.rolesUpdate.description = cate.description;
    this.rolesUpdate.numberReserva = cate.numberReserva;
    this.rolesUpdate.idRol = cate.idRol;
  }
  actualizar(cate: Roles) {
    console.log(this.selectedFile);
    console.log(this.fileName);

    this.rolService.UpadteRoles(cate).subscribe((data) => {
      this.rolesUpdate = data;
      this.showSuccess();
      location.reload();
    });
  }

  //eliminaión logica del registro
  /*deleteEnable(cate: Categorias) {
    this.cateService.deleteEnableRegistro(cate).subscribe((data) => {
      this.rol = this.rol.filter((r) => r !== cate);
      this.showDanger();
    }, (err) => {
      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
      this.showNotDelete();
    });
  }*/

  // control de la ventana emergente
  openLg(content: any) {
    this.modalService.open(content);
  }
  open(update: any) {
    this.modalService.open(update);
  }

  // alertas
  showSuccess() {
    this.toastService.show('💾 Registro añadido con exito...!!!', {
      classname: 'bg-info',
      delay: 5000,
    });
  }
  showDanger() {
    this.toastService.show('❌ Registro Eliminado con exito...!!!', {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  showNotDelete() {
    this.toastService.show(
      '⚠ No se puede eliminar el registro, exite un relación asociada a este registro',
      {
        classname: 'bg-danger text-light',
        delay: 9000,
      }
    );
  }
  showUpdate() {
    this.toastService.show('📝 Registro actualizado con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }
  showUpdateImgError() {
    this.toastService.show('❌ No se pudo cargada la imagen con exito', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

  }
}
