import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Pisos } from '../model/Pisos';

@Injectable({
  providedIn: 'root',
})
export class PisosService {
  // URL BASE
  URL_BASE = environment.HOST;

  // API PARA OBTENER TODAS LOS PISOS
  UrlPisos = this.URL_BASE + '/api/v1/allpisos';

  // API PARA REGISTAR UN NUEVO PISO
  UrlSaveDelete = this.URL_BASE + '/api/v1/pisos';

  // API PARA OBTENER TODAS LOS PISOS
  UrlRecusosDL = this.URL_BASE + '/api/v1/allpisos';

  // authentication
  username = environment.username;
  password = environment.password;
  headers = {
    headers: new HttpHeaders()
      .set(
        'Authorization',
        'Basic '+btoa(this.username+":"+this.password)
      )

      .set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) {}

  // obtener los pisos
  getPisos() {
    return this.http.get<Pisos[]>(this.UrlPisos, this.headers);
  }

  // save piso
  postPisos(pisoSave:Pisos) {
    return this.http.post<Pisos>(this.UrlSaveDelete, pisoSave, this.headers);
  }

  UpadteRegistro(pisoU:Pisos){
    return this.http.put<Pisos>(this.UrlSaveDelete+"/"+pisoU.id, pisoU, this.headers);
  }

  deleteEnableRegistro(piso:Pisos){
    return this.http.delete(this.UrlSaveDelete+"/"+piso.id, this.headers);
  }

  // getrecursoslinetime
  getRecursosDL() {
    return this.http.get<Pisos[]>(this.UrlPisos, this.headers);
  }
}
