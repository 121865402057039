import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CategoriasComponent } from './categorias/categorias.component';
import { ControllersComponent } from './controllers/controllers.component';
import { PisosComponent } from './pisos/pisos.component';
import { RecursosComponent } from './recursos/recursos.component';
import { ReservaltsComponent } from './reservalts/reservalts.component';
import { RolesComponent } from './roles/roles.component';
import { HomeV2Component } from './V2/home-v2/home-v2.component';
import { ReservaBibliotecarioComponent } from './V2/reserva-bibliotecario/reserva-bibliotecario.component';
import { ReservaV2Component } from './V2/reserva-v2/reserva-v2.component';
import { OutlookComponent } from './V2/outlook/outlook.component';
import { AdministracionReservasComponent } from './V2/administracion-reservas/administracion-reservas.component';
import { ReservasAdministractionComponent } from './V2/reservas-administraction/reservas-administraction.component';

const routes: Routes = [
  //{path:'', redirectTo:'inicio', pathMatch: 'full' },
  //{path:'', component:HomeV2Component, pathMatch: 'full', canActivate: [MsalGuard] },

  {path:'', component:HomeV2Component, canActivate: [MsalGuard] },
  {path:'listarReservas', component:ReservaltsComponent, canActivate: [MsalGuard] },
  {path:'addsubcategoria', component:PisosComponent, canActivate: [MsalGuard] },
  {path:'addcategoria', component:CategoriasComponent, canActivate: [MsalGuard] },
  {path:'addrecursos', component:RecursosComponent, canActivate: [MsalGuard] },
  {path:'reservasAdmin', component:ReservaBibliotecarioComponent, canActivate: [MsalGuard] },
  {path:'administracionReservas', component:ReservasAdministractionComponent, canActivate: [MsalGuard] },
  {path:'roles', component:RolesComponent, canActivate: [MsalGuard] },
  {path:'controllers', component:ControllersComponent, canActivate: [MsalGuard] },

  // VERSION 2 DEL PROCEO DE RESERVAS POST PANDEMIA
  {path:'reservasAdministrador', component:ReservaV2Component,  canActivate: [MsalGuard] },
  {path:'validarReservas', component:AdministracionReservasComponent, canActivate: [MsalGuard]},

  {path:'outlook', component:OutlookComponent,  canActivate: [MsalGuard] },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
