import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepickerConfig,
  NgbModal,
  NgbModalConfig,
  NgbRadioGroup,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { Categorias } from '../model/Categorias';
import { Pisos } from '../model/Pisos';
import { PrestamoSave } from '../model/PrestamoSave';
import { Recurso } from '../model/Recurso';
import { Reservas } from '../model/Reservas';
import { ReservaSave } from '../model/ReservaSave';
import { CategoriaService } from '../Service/categoria.service';
import { ControllersService } from '../Service/controllers.service';
import { PisosService } from '../Service/pisos.service';
import { RecursoService } from '../Service/recurso.service';
import { ReservasService } from '../Service/reservas.service';
import { ServiceService } from '../Service/service.service';
import { ToastService } from '../Service/toast.service';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-reservalts',
  templateUrl: './reservalts.component.html',
  styleUrls: ['./reservalts.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class ReservaltsComponent implements OnInit {
  //Timepicker
  time: NgbTimeStruct = { hour: 8, minute: 0, second: 0 };
  timesStr: NgbTimeStruct = { hour: 8, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 15;
  secondStep = 30;
  meridian = true;
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  // data table
  profile!: ProfileType;
  reservas_save: ReservaSave = new ReservaSave();
  prestamo_save: PrestamoSave = new PrestamoSave();
  reservas_editar: ReservaSave = new ReservaSave();
  user: string | undefined;
  reservasListShow: Reservas[] = [];

  recurso: Recurso = new Recurso();
  roles: any;
  reserva: ReservaSave[] = [];
  recursoList: Recurso[] = [];
  pisosList: Pisos[] = [];
  pisosListado: Pisos[] = [];
  cateList: Categorias[] = [];
  catePrestamosList: Categorias[] = [];
  fechaList: any;
  fechaListV2: any;
  // para las fechas
  fechaId: any;
  fechaActual: any;
  horaInicio: string | undefined;
  horaFin: string | undefined;
  minutosUso!: number;
  minutosEspera!: number;
  fechaInicioChange!: any;
  model!: NgbDateStruct;
  @ViewChild('fechaRsrv') fechaRsrv!: ElementRef;
  @ViewChild('fechaRsrvPresmo') fechaRsrvPresmo!: ElementRef;
  @ViewChild('loadingScreens') loadingScreens!: ElementRef;

  // información adicinal
  infoNombreRecurso: any;
  infoDescripcion: any;
  infoUbicacion: any;
  capacidadUso: any;
  imgRuta: any;
  datepickers!: any;
  datepickersPrestamo!: any;
  date: any;
  reservas: any;
  reservasTomorrow: any; // tomorrowany;

  //show divs actions
  public edited = false;
  public edited2 = false;
  public edited3 = false;
  public edited3_1 = false;
  public edited4 = false;
  public editedInfo = false;
  public showMore = true;
  public showLess = false;
  canCheckbox: boolean = false;
  public showDatePrestamo = false;
  public selectDate_prestamo = false;
  //action de cambio combo box
  verSeleccion: string | undefined;
  favoriteSeason: string | undefined;
  document: any;
  horaDiv: any;

  // card bootstrap
  active = 1;

  // temporizador
  times!: Time;
  timerId!: number;
  // para ocultar piso
  @ViewChild('piso41') piso41!: ElementRef;

  @ViewChild('contentModal', { static: true }) contentModal!: ElementRef;
  @ViewChild('contentModalEdit', { static: true }) contentModalEdit!: ElementRef;
  //date _picker
  constructor(
    private reservaService: ReservasService,
    private title: Title,
    private http: HttpClient,
    private modalService: NgbModal,
    private recursoService: RecursoService,
    private pisosService: PisosService,
    private toastService: ToastService,
    private categoriaService: CategoriaService,
    private service: ServiceService,
    private controlService: ControllersService,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.title.setTitle('Historial de reservas | Reservas');
    var restult = this.getHoraActual();
    this.time = { hour: restult, minute: 0, second: 0 };
    this.getDateTomorrow();
    //this.openLgHome();
    //this.openInfoAlert();
    this.getRolAndList();
    //this.getCategorias();
    this.desableCategory();
    this.getRolList();
    this.desablePiso();
    this.enablePrestamos();
  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        this.roles = data.rol;
      });
    });
  }

  getRolAndList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.reservaService.getReserva('' + this.user + '').subscribe((data) => {
        this.horaDiv = this.getFechaActualAndHora();
        this.reservas = data;
      });
      this.reservaService
        .getReservaTomorrow('' + this.user + '')
        .subscribe((data) => {
          this.reservasTomorrow = data;
        });
        /* this.reservaService
        .getReservaTomorrowV2('' + this.user + '')
        .subscribe((data) => {
          this.reservasTomorrow = data;
        }); */
      //getReservaManana
    });
  }

  enviarvalor() {
    console.log(this.time);
  }

  openLgHome() {
    this.modalService.open(this.contentModal, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  desablePiso() {
    var pisoPlantaBaja = 1;
    var pisoPrimeroPlantaAlta = 2;
    var pisoSegundaPlantaAlta = 3;
    var pisoTerceraPlantaAlta = 4;
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.pisosService.getPisos().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();
          if (rolPiso == 'ESTUDIANTE') {
            for (let i = 0; i < data.length; i++) {
              if (data[i].id != pisoTerceraPlantaAlta) {
                this.pisosListado.push(data[i]);
              }
            }
          } else if (rolPiso == 'DOCENTE' || rolPiso == 'ADMINISTRATIVO') {
            for (let i = 0; i < data.length; i++) {
              if (
                data[i].id != pisoPlantaBaja &&
                data[i].id != pisoPrimeroPlantaAlta &&
                data[i].id != pisoSegundaPlantaAlta
              ) {
                this.pisosListado.push(data[i]);
              }
            }
          } else {
            this.pisosListado = data;
          }
        });
      });
    });
  }
  desableCategory() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.categoriaService.getCategoria().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();

          for (let i = 0; i < data.length; i++) {
            this.cateList.push(data[i]);

          }
        });
      });
    });
  }


  enablePrestamos() {
    var sala = 1;
    var prestamoLibro = 161;
    var devolucionLibro = 162;
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser('' + this.user + '').subscribe((data) => {
        var rolPiso = data.rol;
        this.categoriaService.getCategoria().subscribe((data) => {
          this.horaDiv = this.getFechaActualAndHora();

          for (let i = 0; i < data.length; i++) {
            if (data[i].id == prestamoLibro || data[i].id == devolucionLibro) {
              this.catePrestamosList.push(data[i]);
            }
          }

          console.log(this.catePrestamosList);
        });
      });
    });
  }

  // envio de mail de reserva generada
  enviarMail() {
    this.reservaService
      .sendMail(
        '' + this.reservas_save.emailReservador + '',
        '' + this.reservas_save.namesReservador + '',
        '' + this.reservas_save.materialReservado + '',
        '' + this.reservas_save.fechaReservaInicio + '',
        '' + this.reservas_save.fechaReservaFin + ''
      )
      .subscribe(
        (data) => {},
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.loadingScreens.nativeElement.style.display = 'block';
          this.showSendMail();
          location.reload();
        }
      );
  }
  // envio de mail de reserva modificada
  enviarMailUpdate() {
    this.reservaService
      .sendMailUpdate(
        '' + this.reservas_editar.emailReservador + '',
        '' + this.reservas_editar.namesReservador + '',
        '' + this.reservas_editar.materialReservado + '',
        '' + this.reservas_editar.fechaReservaInicio + '',
        '' + this.reservas_editar.fechaReservaFin + ''
      )
      .subscribe(
        (data) => {},
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.showSendMailUpdate();
          location.reload();
        }
      );
  }
  // envio de mail de reserva eliminada
  enviarMailError(
    materialReservado: String,
    fechaInicio: String,
    fechaFin: String
  ) {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.reservaService
        .sendMailError(
          '' + this.profile.userPrincipalName + '',
          '' + this.profile.givenName + ' ' + this.profile.surname + '',
          '' + materialReservado + '',
          '' + fechaInicio + '',
          '' + fechaFin + ''
        )
        .subscribe(
          (data) => {},
          (err) => {
            // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
            this.loadingScreens.nativeElement.style.display = 'none';
            this.showSendMailError();
            location.reload();
          }
        );
    });
  }

  //eliminaión logica del registro
  deleteEnable(recursosDelete: ReservaSave) {
    this.showEventDeleteReserva();
    //this.loadingScreens.nativeElement.style.display = 'block';
    this.reservaService
      .deleteEnableRegistro(recursosDelete)
      .subscribe((data) => {
        /* this.enviarMailError(
          '' + recursosDelete.materialReservado + '',
          '' + recursosDelete.fechaReservaInicio + '',
          '' + recursosDelete.fechaReservaFin + ''
        ); */
        this.showSendMailError();
        Swal.close();
        //this.loadingScreens.nativeElement.style.display = 'none';
        location.reload();
      });
  }

  // agregar registro
  guardar(reserva: ReservaSave) {
    this.showEventInfoOk();
    //this.loadingScreen.nativeElement.style.display = 'block';
    this.reservaService.postReserva(reserva).subscribe(
      (data) => {
        this.showSuccess();
        //this.enviarMail();
        this.showSendMail();
        Swal.close();
        //this.loadingScreen.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoError();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // agrefar registro de cita de prestamo o devolución de libro
  guardarPrestamo(reserva: PrestamoSave) {
    this.showEventInfoOk();
    //this.loadingScreen.nativeElement.style.display = 'block';
    this.reservaService.postPrestamo(reserva).subscribe(
      (data) => {
        this.showSuccess();
        //this.enviarMail();
        this.showSendMail();
        Swal.close();
        //this.loadingScreen.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoErrorPrestamo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  // update reserva
  updateShow(reserva: ReservaSave) {
    //this.loadingScreens.nativeElement.style.display = 'block';
    this.showEventUpdate();
    this.reservaService.putReserva(reserva).subscribe(
      (data) => {
        //this.enviarMailUpdate();
        this.showSendMailUpdate();
        Swal.close();
        //this.loadingScreens.nativeElement.style.display = 'none';
        location.reload();
      },
      (err) => {
        // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
        if (err.error == 'Aforo completo') {
          Swal.close();
          this.showEventInfoErrorAforo();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorAforo();
        } else if (err.error == 'No tiene acceso al compus') {
          Swal.close();
          this.showEventInfoErrorNoAccess();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorNoAccess();
        } else if (err.error == 'Reservas excedidas') {
          Swal.close();
          this.showEventInfoError();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorEx();
        } else {
          Swal.close();
          this.showEventInfoErrorSave();
          //this.loadingScreen.nativeElement.style.display = 'none';
          //this.showErrorSave();
        }
      }
    );
  }

  //editar reserva
  editarReserva(reser: Reservas): void {
    localStorage.setItem('id', reser.id.toString());
    this.reservas_editar.id = reser.id;
    this.reservas_editar.namesReservador = reser.namesReservador;
    this.reservas_editar.emailReservador = reser.emailReservador;
    this.reservas_editar.pisoReserva = reser.pisoReserva;
    this.reservas_editar.categoriaReserva = reser.categoriaReserva;
    this.reservas_editar.idRecurso = reser.recurso.id;
    this.reservas_editar.fechaReservaInicio = reser.fechaReservaInicio;
    //this.getRecursoEditButton(reser.pisoReserva, reser.categoriaReserva);

    var minutosusos = reser.recurso.categoria.tiempoUso;
    var minutosEsperas = reser.recurso.categoria.tiempoEspera;
    this.getFechaActualEdit(
      reser.fechaReservaInicio,
      minutosusos,
      minutosEsperas
    );

    this.desactivarInpuntEditChangeV2(reser.categoriaReserva);
    this.reservas_editar.fechaReservaFin = reser.fechaReservaFin;
    this.reservas_editar.materialReservado = reser.recurso.nombreRecurso;

    var pisoss = reser.pisoReserva;
    var categoriass = reser.categoriaReserva;
    var fechaInicios = reser.fechaReservaInicio;
    var fechaFins = reser.fechaReservaFin;

    this.RecursoEditV2(
      pisoss,
      categoriass,
      '' + fechaInicios + '',
      '' + fechaFins + ''
    );
    this.capacidadUso = reser.recurso.capacidad;
    this.infoDescripcion = reser.recurso.descripcion;
    this.infoUbicacion = reser.recurso.ubicacion;
    this.imgRuta = reser.recurso.categoria.rutaImg;
  }

  // cargar el resto de datos
  desactivarInpunt() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.idRecurso;
    if (dataSelectors != 'Seleccione') {
      this.edited3 = true;
      this.recursoService
        .getRecursoId('' + this.reservas_save.idRecurso + '')
        .subscribe((data) => {
          this.reservas_save.materialReservado = data.nombreRecurso;
          this.minutosUso = data.categoria.tiempoUso;
          this.capacidadUso = data.capacidad;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.categoria.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }

  //eventos para mostrar div de información del recurso
  showInfo() {
    this.showMore = false;
    this.editedInfo = true;
    this.showLess = true;
  }
  showInfoLess() {
    this.showMore = true;
    this.editedInfo = false;
    this.showLess = false;
  }
  showFechaPrestamo() {
    this.showDatePrestamo = true;
  }

  // cargar datos del usuario al formulario de reserva
  cargarUser() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.reservas_save.namesReservador =
        this.profile.givenName + ' ' + this.profile.surname;
      this.reservas_save.emailReservador = this.profile.userPrincipalName;
    });
  }

  cargarUserPD() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.prestamo_save.namesReservador =
        this.profile.givenName + ' ' + this.profile.surname;
      this.prestamo_save.emailReservador = this.profile.userPrincipalName;
    });
  }

  //evento para cargar las categorias
  PisoChanged() {
    this.edited = true;
    this.edited3 = false;
  }

  // Version 2 - evento para cargar los recursos
  dataChangedv2(event: any) {
    this.edited2 = true;
    this.edited3 = false;
  }
  // Version 2 - vento para el changeDatepiker
  changeDatepikersV2(event: any) {
    //var fechaInicio = this.fechaRsrv.nativeElement.value.replace(' ', '');
    //console.log(this.getFechaActual());

    this.edited3 = false;
    if (this.datepickers != undefined) {
      var datepicker = this.datepickers;

      var fechaSelect = '';
      var month = '';
      var day = '';
      if (datepicker.month < 10) {
        month = '0' + datepicker.month;
      } else {
        month = datepicker.month;
      }
      if (datepicker.day < 10) {
        day = '0' + datepicker.day;
      } else {
        day = datepicker.day;
      }

      // calculando fecha seleccionado
      fechaSelect = datepicker.year + '-' + month + '-' + day;
      const dateSelect = new Date(
        datepicker.year + '-' + month + '-' + day + 'T08:00:00'
      );

      // dias de no atencia de la biblioteca
      var diasNo = '';
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          diasNo = data[0].diasAtencion;
        }

        if (diasNo.includes(this.getDay(dateSelect))) {
          this.showErrorNoDia();
          this.edited3_1 = false;
          this.edited3 = false;
        } else {
          if (fechaSelect < this.getFechaActual()) {
            this.showErrorDate();
            this.edited3_1 = false;
            this.edited3 = false;
          } else {
            var restults = this.getHoraActual();
            this.timesStr = { hour: restults, minute: 0, second: 0 };
            this.getFechaActualSelect(fechaSelect);
            this.edited3_1 = true;
          }
        }
      });
    } else {
      this.edited3_1 = false;
      this.showErrorVacio();
    }
  }
  // Version 2 - evento para cargar el input de salida de la fecha de fin
  changeFechaV2(event: any) {
    // obteniendo la fecha de inicio para calcular el tiempo de uso del recurso
    if (this.reservas_save.fechaReservaInicio != 'Seleccione') {
      var fechaInicioChanges = this.reservas_save.fechaReservaInicio.replace(
        ' ',
        'T'
      );
      var fechaSelectSalida = this.reservas_save.fechaReservaInicio.split(' ');

      //add minutos a la hora final
      var fechaI2 = new Date(fechaInicioChanges);
      var minutoSumar = this.minutosUso;
      var fechaSalist = '';
      var getHora;
      var getMin;

      // calculando hora de salida
      if (minutoSumar != 0) {
        fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);
        if (fechaI2.getHours() < 10) {
          getHora = '0' + fechaI2.getHours();
        } else {
          getHora = fechaI2.getHours();
        }

        if (fechaI2.getMinutes() < 10) {
          getMin = '0' + fechaI2.getMinutes();
        } else {
          getMin = fechaI2.getMinutes();
        }
        fechaSalist = getHora + ':' + getMin + ':' + fechaI2.getSeconds() + '0';
      }

      if (fechaSalist == '22:00:00') {

        this.reservas_save.fechaReservaFin =
          fechaSelectSalida[0] + ' ' + '21:00:00';
         } else {
          this.reservas_save.fechaReservaFin =
          fechaSelectSalida[0] + ' ' + fechaSalist;
         }
      this.edited3 = true;
      this.RecursosV2();
    } else {
      this.reservas_save.fechaReservaFin = '';
    }
  }
  // Version 2 - evento para cargar el input de salida de la fecha de fin
  changeFechaEditV2() {
    // obteniendo la fecha de inicio para calcular el tiempo de uso del recurso
    //console.log("event "+ this.reservas_editar.fechaReservaInicio);
    this.desactivarInpuntEditV2();
    if (this.reservas_editar.fechaReservaInicio != 'Seleccione') {
      var fechaInicioChanges = this.reservas_editar.fechaReservaInicio.replace(
        ' ',
        'T'
      );
      var fechaSelectSalida =
        this.reservas_editar.fechaReservaInicio.split(' ');

      //add minutos a la hora final
      var fechaI2 = new Date(fechaInicioChanges);
      var minutoSumar = this.minutosUso;
      var fechaSalist = '';
      var getHora;
      var getMin;

      //console.log("minutoSumar"+minutoSumar);
      // calculando hora de salida
      if (minutoSumar != 0) {
        fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);
        if (fechaI2.getHours() < 10) {
          getHora = '0' + fechaI2.getHours();
        } else {
          getHora = fechaI2.getHours();
        }

        if (fechaI2.getMinutes() < 10) {
          getMin = '0' + fechaI2.getMinutes();
        } else {
          getMin = fechaI2.getMinutes();
        }
        fechaSalist = getHora + ':' + getMin + ':' + fechaI2.getSeconds() + '0';

        //console.log("fechaSalist"+fechaSalist);
      }

      if (fechaSalist == '22:00:00') {
        this.reservas_editar.fechaReservaFin =
        fechaSelectSalida[0] + ' ' + "21:00:00";
      } else {
        this.reservas_editar.fechaReservaFin =
        fechaSelectSalida[0] + ' ' + fechaSalist;
      }


      this.RecursoEditStrV2();
    } else {
      this.reservas_editar.fechaReservaFin = '';
    }
  }
  // Version 2 - cargar el resto de datos
  desactivarInpuntV2() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + this.reservas_save.categoriaReserva + '')
        .subscribe((data) => {
          //this.reservas_save.materialReservado = data.nombreCategoria;
          this.minutosUso = data.tiempoUso;
          this.minutosEspera = data.tiempoEspera;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }
  desactivarInpuntEditV2() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_editar.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + this.reservas_editar.categoriaReserva + '')
        .subscribe(
          (data) => {
            //this.reservas_editar.materialReservado = data.nombreCategoria;
            this.minutosUso = data.tiempoUso;
            this.minutosEspera = data.tiempoEspera;
            this.infoDescripcion = data.descripcion;
            this.infoUbicacion = data.ubicacion;
            this.imgRuta = data.rutaImg;
            //console.log("this.minutosUso:"+this.minutosUso+ " this.minutosEspera: "+this.minutosEspera);
            this.RecursoEditStrV2();
          },
          (err) => {
            this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_save.fechaReservaFin = '';
          }
        );
    } else {
      this.edited3 = false;
    }
  }
  desactivarInpuntEditStrV2() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_editar.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + this.reservas_editar.categoriaReserva + '')
        .subscribe((data) => {
          //this.reservas_editar.materialReservado = data.nombreCategoria;
          this.minutosUso = data.tiempoUso;
          this.minutosEspera = data.tiempoEspera;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.rutaImg;
          console.log(
            'this.minutosUso:' +
              this.minutosUso +
              ' this.minutosEspera: ' +
              this.minutosEspera
          );
        });
    } else {
      this.edited3 = false;
    }
  }
  desactivarInpuntEditChangeV2(idCategoriaRs: number) {
    var dataSelectors = '' + idCategoriaRs;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + idCategoriaRs + '')
        .subscribe((data) => {
          //this.reservas_editar.materialReservado = data.nombreCategoria;
          this.minutosUso = data.tiempoUso;
          this.minutosEspera = data.tiempoEspera;
          this.infoDescripcion = data.descripcion;
          this.infoUbicacion = data.ubicacion;
          this.imgRuta = data.rutaImg;
        });
    } else {
      this.edited3 = false;
    }
  }

  // Version 3 - evento para el select de fechas de prestamo o devolución de libro
  desactivarInpuntPrestamoV2() {
    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.prestamo_save.tipoReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoIdv2('' + this.prestamo_save.tipoReserva + '')
        .subscribe((data) => {
          //this.reservas_save.materialReservado = data.nombreCategoria;
          this.minutosUso = data.tiempoUso;
          this.minutosEspera = data.tiempoEspera;
        });
    } else {
      this.showDatePrestamo = false;
    }
  }
  // Version 3 - evento para el changeDatepiker de prestamo o devolución de libro
  DatepikersPrestamoV2(event: any) {
    //var fechaInicio = this.fechaRsrv.nativeElement.value.replace(' ', '');
    //console.log(this.getFechaActual());
    if (this.datepickersPrestamo != undefined) {
      var datepicker = this.datepickersPrestamo;

      var fechaSelect = '';
      var month = '';
      var day = '';
      if (datepicker.month < 10) {
        month = '0' + datepicker.month;
      } else {
        month = datepicker.month;
      }
      if (datepicker.day < 10) {
        day = '0' + datepicker.day;
      } else {
        day = datepicker.day;
      }

      // calculando fecha seleccionado
      fechaSelect = datepicker.year + '-' + month + '-' + day;
      const dateSelect = new Date(
        datepicker.year + '-' + month + '-' + day + 'T08:00:00'
      );

      // dias de no atencia de la biblioteca
      var diasNo = '';
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          diasNo = data[0].diasAtencion;
        }

        if (diasNo.includes(this.getDay(dateSelect))) {
          this.showErrorNoDia();
          this.selectDate_prestamo = false;
        } else {
          if (fechaSelect < this.getFechaActual()) {
            this.showErrorDate();
            this.selectDate_prestamo = false;
          } else {
            this.getFechaActualSelect(fechaSelect);
            this.selectDate_prestamo = true;
          }
        }
      });
    } else {
      this.selectDate_prestamo = false;
      this.showErrorVacio();
    }
  }
  // Version 3 - evento para cargar el input de salida de la fecha de fin de prestamo o devolución de libro
  changeFechaPrestamoV2(event: any) {
    // obteniendo la fecha de inicio para calcular el tiempo de uso del recurso
    if (this.prestamo_save.fechaReservaInicio != 'Seleccione') {
      var fechaInicioChanges = this.prestamo_save.fechaReservaInicio.replace(
        ' ',
        'T'
      );
      var fechaSelectSalida = this.prestamo_save.fechaReservaInicio.split(' ');

      //add minutos a la hora final
      var fechaI2 = new Date(fechaInicioChanges);
      var minutoSumar = this.minutosUso;
      var fechaSalist = '';
      var getHora;
      var getMin;

      // calculando hora de salida
      if (minutoSumar != 0) {
        fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);
        if (fechaI2.getHours() < 10) {
          getHora = '0' + fechaI2.getHours();
        } else {
          getHora = fechaI2.getHours();
        }

        if (fechaI2.getMinutes() < 10) {
          getMin = '0' + fechaI2.getMinutes();
        } else {
          getMin = fechaI2.getMinutes();
        }
        fechaSalist = getHora + ':' + getMin + ':' + fechaI2.getSeconds() + '0';
      }

      this.prestamo_save.fechaReservaFin =
        fechaSelectSalida[0] + ' ' + fechaSalist;
      console.log('prestamo: ' + this.prestamo_save.fechaReservaFin);
    } else {
      this.prestamo_save.fechaReservaFin = '';
    }
  }

  // Version 2 - cargar los recursos
  RecursosV2() {
    var pisos = this.reservas_save.pisoReserva;
    var categorias = this.reservas_save.categoriaReserva;
    var fechaInicio = this.reservas_save.fechaReservaInicio;
    var fechaFin = this.reservas_save.fechaReservaFin;

    this.recursoService
      .getRecursoSelectv3(
        '' + pisos + '',
        '' + categorias + '',
        '' + fechaInicio + '',
        '' + fechaFin + ''
      )
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.showRecursos();
            this.edited3 = true;
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.capacidadUso = data[i].capacidad;
            }
          } else {
            this.recursoList = data;
            this.edited3 = false;
            this.showNoRecursos();
            /* this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_save.fechaReservaFin = ''; */
          }
        },
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.edited3 = false;
          this.showNoRecursos();
          /* this.minutosUso = 0;
          this.minutosEspera = 0;
          this.reservas_save.fechaReservaFin = ''; */
        }
      );
  }
  RecursoEditStrV2() {
    var pisos = this.reservas_editar.pisoReserva;
    var categorias = this.reservas_editar.categoriaReserva;
    var fechaInicio = this.reservas_editar.fechaReservaInicio;
    var fechaFin = this.reservas_editar.fechaReservaFin;
    //console.log("fechaFin"+fechaFin);
    this.recursoService
      .getRecursoSelectv3(
        '' + pisos + '',
        '' + categorias + '',
        '' + fechaInicio + '',
        '' + fechaFin + ''
      )
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.showRecursos();
            this.edited3 = true;
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.capacidadUso = data[i].capacidad;
              this.minutosUso = data[i].categoria.tiempoUso;
              this.minutosEspera = data[i].categoria.tiempoEspera;
            }
          } else {
            this.recursoList = data;
            this.edited3 = false;
            this.showNoRecursos();
            /* this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_editar.fechaReservaInicio = '';
            this.reservas_editar.fechaReservaFin = ''; */
          }
        },
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.edited3 = false;
          this.recursoList = [];
          this.showNoRecursos();
          /* this.minutosUso = 0;
          this.minutosEspera = 0;
          this.reservas_editar.fechaReservaFin = ''; */
        }
      );
  }
  RecursoEditV2(
    pisos: number,
    categorias: number,
    fechaInicio: string,
    fechaFin: string
  ) {
    this.recursoService
      .getRecursoSelectv3(
        '' + pisos + '',
        '' + categorias + '',
        '' + fechaInicio + '',
        '' + fechaFin + ''
      )
      .subscribe(
        (data) => {
          if (data.length != 0) {
            this.showRecursos();
            this.edited3 = true;
            this.recursoList = data;
            for (let i = 0; i < data.length; i++) {
              this.capacidadUso = data[i].capacidad;
            }
          } else {
            this.recursoList = data;
            this.showNoRecursos();
            this.edited3 = false;
           /*  this.minutosUso = 0;
            this.minutosEspera = 0;
            this.reservas_editar.fechaReservaFin = ''; */
          }
        },
        (err) => {
          // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
          this.edited3 = false;
          this.showNoRecursos();
          /* this.minutosUso = 0;
          this.minutosEspera = 0;
          this.reservas_editar.fechaReservaFin = ''; */
        }
      );
  }
  changeRecursoSelectV2() {
    var idRecurso = this.reservas_editar.idRecurso;

    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoId('' + idRecurso + '')
        .subscribe((data) => {
          this.reservas_editar.materialReservado = data.nombreRecurso;
        });
    } else {
      this.edited3 = false;
    }
  }
  changeRecursoSelectSaveV2() {
    var idRecurso = this.reservas_save.idRecurso;

    //this.showAlertTiempoUso();
    var dataSelectors = '' + this.reservas_save.categoriaReserva;
    if (dataSelectors != 'Seleccione') {
      this.recursoService
        .getRecursoId('' + idRecurso + '')
        .subscribe((data) => {
          this.reservas_save.materialReservado = data.nombreRecurso;
        });
    } else {
      this.edited3 = false;
    }
  }

  // para listar todos los pisos de biblioteca utpl
  getPisos() {
    this.pisosService.getPisos().subscribe((data) => {
      this.pisosList = data;
    });
  }

  // para listar todos las categorias de uso
  getCategorias() {
    this.categoriaService.getCategoria().subscribe((data) => {
      this.cateList = data;
    });
  }

  //evento para cargar las horas disponibles
  getFechaActualSelect(fechaSelect: String) {
    if (this.minutosUso != 0) {
      var minutos = this.minutosUso + this.minutosEspera;
      var horaInicioAT = '';
      var horaFinAT = '';
      // obteniendo los datos datos del servicio donde esta el horario de atención
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          horaInicioAT = data[0].horaInicio;
          horaFinAT = data[0].horaFin;
        }

        //calcular el tiempo de uso del recurso
        var start = moment(horaInicioAT, 'H:mm:ss');
        var end = moment(horaFinAT, 'H:mm:ss');
        var rangeArray = calculateRange(start, end, 60);
        var result = rangeArray.map(function (hour) {
          return hour.format('HH:mm:ss');
        });
        var resultsV2 = [];
        resultsV2 = result.join(',').split(',');

        // crear el areglo de tiempo de uso del recurso con el tiempo de espero.
        var currentValue = this.getFechaActual();
        var fechas = currentValue;
        this.fechaActual = fechas;
        var fechaHoraActual = this.getFechaActualAndHora();
        var fechaHoraActualStr = fechaHoraActual.split(' ');
        this.fechaListV2 = [];

        // for para añadir los valores de la hora
        for (let j = 0; j < resultsV2.length - 1; j++) {
          var constList;
          if (fechaSelect == this.fechaActual) {
            if (resultsV2[j] > fechaHoraActualStr[1]) {
              constList = {
                fechaId: j + 1,
                fechaActual: fechaSelect,
                horaInicio: resultsV2[j],
                horaFin: resultsV2[j + 1],
              };
              this.fechaListV2.push(constList);
            }
          } else {
            constList = {
              fechaId: j + 1,
              fechaActual: fechaSelect,
              horaInicio: resultsV2[j],
              horaFin: resultsV2[j + 1],
            };
            this.fechaListV2.push(constList);
          }
        }
        //this.reservas_save.fechaReservaInicio = '';
        this.reservas_save.fechaReservaFin = '';
      });
    } else {
      this.fechaListV2 = [];
    }
    //console.log(this.fechaListV2);
  }
  getFechaActualEdit(
    fechaSelect: String,
    minutosuso: number,
    minutosespera: number
  ) {
    if (minutosuso != 0) {
      var fechaSelectSalida = fechaSelect.split(' ');
      var minutos = minutosuso + minutosespera;
      var horaInicioAT = '';
      var horaFinAT = '';
      // obteniendo los datos datos del servicio donde esta el horario de atención
      this.controlService.getControllers().subscribe((data) => {
        for (var i = 0; i < data.length; i++) {
          horaInicioAT = data[0].horaInicio;
          horaFinAT = data[0].horaFin;
        }

        //calcular el tiempo de uso del recurso
        var start = moment(horaInicioAT, 'H:mm:ss');
        var end = moment(horaFinAT, 'H:mm:ss');
        var rangeArray = calculateRange(start, end, 60);
        var result = rangeArray.map(function (hour) {
          return hour.format('HH:mm:ss');
        });
        var resultsV2 = [];
        resultsV2 = result.join(',').split(',');

        // crear el areglo de tiempo de uso del recurso con el tiempo de espero.
        var currentValue = this.getFechaActual();
        var fechas = currentValue;
        this.fechaActual = fechas;
        var fechaHoraActual = this.getFechaActualAndHora();
        var fechaHoraActualStr = fechaHoraActual.split(' ');
        this.fechaListV2 = [];

        // for para añadir los valores de la hora
        for (let j = 0; j < resultsV2.length - 1; j++) {
          var constList;

          if (fechaSelectSalida[0] == this.fechaActual) {
            if (fechaHoraActualStr[1] < resultsV2[j]) {
              constList = {
                fechaId: j + 1,
                fechaActual: fechaSelectSalida[0],
                horaInicio: resultsV2[j],
                horaFin: resultsV2[j + 1],
              };

              this.fechaListV2.push(constList);
            }
          } else {
            constList = {
              fechaId: j + 1,
              fechaActual: fechaSelectSalida[0],
              horaInicio: resultsV2[j],
              horaFin: resultsV2[j + 1],
            };

            this.fechaListV2.push(constList);
          }
        }

        this.reservas_editar.fechaReservaInicio = fechaSelect;
        //this.reservas_save.fechaReservaFin = '';

        //console.log("editar"+this.fechaListV2);
      });
    } else {
      this.fechaListV2 = [];
    }
  }

  temporizador() {
    let date = new Date('2021-11-12');
    //this.timerId = countdown(date, (ts)=> {})
  }

  //fechas
  getFechaActual() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();

    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var fechaInicio;
    var fechaSalida;

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }
    fechaActual = hoy.getFullYear() + '-' + months + '-' + diaSL;
    return fechaActual;
  }

  getFechaActualAndHora() {
    var hoy = new Date();
    var month = hoy.getMonth() + 1;
    var dia = hoy.getDate();
    var hora = hoy.getHours();
    var min = hoy.getMinutes();
    var fechaActual = '';
    var months = '';
    var diaSL = '';
    var mint = '';
    var horas = '';

    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }

    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    if (hora < 10) {
      horas = '0' + hora;
    } else {
      horas = '' + hora;
    }
    if (min < 10) {
      mint = '0' + min;
    } else {
      mint = '' + min;
    }

    fechaActual =
      hoy.getFullYear() +
      '-' +
      months +
      '-' +
      diaSL +
      ' ' +
      horas +
      ':' +
      mint +
      ':' +
      hoy.getSeconds();
    return fechaActual;
  }

  getFechaManana() {
    var months = '';
    var diaSL = '';
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let manana = new Date(hoy.getTime() + DIA_EN_MILISEGUNDOS);
    var month = manana.getMonth() + 1;
    var dia = manana.getDate();
    if (month < 10) {
      months = '0' + month;
    } else {
      months = '' + month;
    }
    if (dia < 10) {
      diaSL = '0' + dia;
    } else {
      diaSL = '' + dia;
    }

    var FechaManana = manana.getFullYear() + '-' + months + '-' + diaSL;
    return FechaManana;
  }
  getDay(dateSelect: Date) {
    const fechaComoCadena = dateSelect; // día lunes
    const dias = [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ];
    const numeroDia = new Date(fechaComoCadena).getDay();
    const nombreDia = dias[numeroDia];
    return nombreDia;
    console.log('Nombre de día de la semana: ', nombreDia);
  }
  getHoraActual() {
    var hoy = new Date();
    var hora = hoy.getHours();
    var fechaActual = null;
    fechaActual = hora;
    return fechaActual;
  }

  // control de la ventana emergente
  openLg2(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  openLg(content: any) {
    this.modalService.open(content, { size: 'lg', scrollable: false });
  }

  //alertas
  showSuccess() {
    this.toastService.show('💾 Reserva añadido con éxito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSuccesUpdate() {
    this.toastService.show('💾 Reserva modificada con éxito...!!!', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMail() {
    this.toastService.show('📤  Reserva creada correctamente', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showSendMailUpdate() {
    this.toastService.show('📤  Reserva modificada correctamente', {
      classname: 'bg-warning',
      delay: 7000,
    });
  }
  showSendMailError() {
    this.toastService.show('📢 Reserva eliminada correctamente', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  showRecursos() {
    this.toastService.show('📢 Recursos de biblioteca cargados correctamente', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  showNoRecursos() {
    this.toastService.show(
      '📢  Todos los recursos han sido usados para la hora seleccionada.',
      {
        classname: 'bg-warning',
        delay: 4000,
      }
    );
  }
  showAlertTiempoUso() {
    this.toastService.show('📢 Recuerda: Las reserva se realizan para el día', {
      classname: 'bg-info',
      delay: 8000,
    });
  }
  showError() {
    this.toastService.show(
      '⚠ Error al cargar el recurso. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoRecursos() {
    this.toastService.show(
      '⚠ Error: no existe ningún recurso asociado a la búsqueda que requiere. Intente nuevamente.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorDate() {
    this.toastService.show(
      '⚠ Error: La fecha seleccionada no puede ser menor a la actual',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorSave() {
    this.toastService.show(
      '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorNoAccess() {
    this.controlService.getControllers().subscribe((data) => {
      this.toastService.show(
        '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }
  showErrorNoDia() {
    var diasNo = '';
    this.controlService.getControllers().subscribe((data) => {
      for (var i = 0; i < data.length; i++) {
        diasNo = data[0].diasAtencion;
      }
      this.toastService.show(
        '⚠ Error: No se puede reservar para le fecha seleccionado | ' +
          diasNo +
          '.',
        {
          classname: 'bg-danger text-light',
          delay: 6000,
        }
      );
    });
  }

  showErrorNoHora() {
    this.toastService.show(
      '⚠ Error: Reservas finalizadas por hoy. Si desea realizar una reserva para mañana.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorEx() {
    this.toastService.show(
      '⚠ Error: Solo puede realizar una reserva por día.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }

  showErrorAforo() {
    this.toastService.show(
      '⚠ Error: Aforo completo para el horario que seleccionó.',
      {
        classname: 'bg-danger text-light',
        delay: 6000,
      }
    );
  }
  showErrorVacio() {
    this.toastService.show('⚠ Error: Esta vacío la seleccionado', {
      classname: 'bg-warning',
      delay: 4000,
    });
  }


  getDateTomorrow() {
    var hoy = new Date();
    var tomorrow = new Date(hoy.getTime() + 24 * 60 * 60 * 1000);
    var dds = tomorrow.getDate();
    var mms = tomorrow.getMonth() + 1; //January is 0!
    var yyyy = tomorrow.getFullYear();
    var ddst;
    var mmst;
    var tomorrowst;
    if (dds < 10) {
      ddst = '0' + dds;
    } else {
      ddst = dds;
    }
    if (mms < 10) {
      mmst = '0' + mms;
    } else {
      mmst = mms;
    }
    tomorrowst = yyyy + '-' + mmst + '-' + ddst;
    console.log(tomorrowst);
  }

  //eventos para mostrar el estado de la acción de información del recurso
  showEventInfoOk() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Espero un momento',
      text: 'Estamos validando su reserva y una vez terminada le llegará un correo de confirmación.',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  showEventInfoError() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de reservas que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorPrestamo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Usted a excedido el número de prestamos que puede realizar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 4000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorSave() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Ya existe una reserva para el recurso seleccionado.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorAforo() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: Aforo completo para el horario que seleccionó.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 3000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventInfoErrorNoAccess() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: '⚠ Error: No tiene acceso al campus para el día seleccionado y por ende no podra reservar.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventUpdate() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'warning',
      title: 'Espero un momento',
      text: '⚙️ Estamos modificando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }
  showEventDeleteReserva() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'error',
      title: 'Reserva eliminada',
      text: 'Estamos eliminando su reserva. En un momento le llegará un correo de confirmación.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      // html: 'I will close in <b></b> milliseconds.',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 100);
      },
    });
  }
  openInfoAlert2() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text:  'Se comunica que el día de hoy viernes el horario de atención será de 08:00 a 11:00 am. El día lunes retomamos en nuestro horario habitual.',
      footer: 'Agradecemos su comprensión.',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 8000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = Swal.getTimerLeft()?.toString() || '';
            }
          }
        }, 200);
      },
    });
  }

  openInfoAlert() {
    let timerInterval: any = undefined!;
    Swal.fire({
      icon: 'info',
      title: 'Estimada comunidad universitaria',
      text: 'Del 19 de agosto al 09 de septiembre del 2022, la Biblioteca UTPL por remodelación, atenderá a través del servicio de Teleasistencia.',
      footer:
        '<center> Horario de atención: <br>08:00 a 13:00  <br>15:00 a 18:00 <br> Agradecemos su comprensión. </center>',
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      timer: 18000,
      timerProgressBar: false,
      showConfirmButton: false,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }
}
function calculateRange(start: any, end: any, minutes: any) {
  var range = [];
  for (
    var hour = moment(start);
    hour.isBefore(end);
    hour.add(minutes, 'minutes')
  ) {
    range.push(moment(hour));
  }
  range.push(moment(end));
  return range;
}
function removeItemFromArr(foo: any, arg1: string) {
  throw new Error('Function not implemented.');
}

function contador(fecha: string, min: number) {
  var actividad = fecha;
  //var actividad = Date.UTC(2019, 8, 13, 6, 45, 0);// <-- idealmente
  var fechaI2 = new Date(actividad);
  let minutoSumar = min;

  console.log(fechaI2);

  if (minutoSumar != 0) {
    fechaI2.setMinutes(fechaI2.getMinutes() + minutoSumar);

    console.log(fechaI2);
  }
  return fechaI2;
}
