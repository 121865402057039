import { HttpClient, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Categorias } from '../model/Categorias';
import { CategoriaService } from '../Service/categoria.service';
import { FileService } from '../Service/file.service';
import { ServiceService } from '../Service/service.service';
import { ToastService } from '../Service/toast.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css'],
})
export class CategoriasComponent implements OnInit, AfterViewInit, OnDestroy {

  // Update img
  //URL BASE
  URL_BASE = environment.HOST;
  //URL TO IMG SAVE
  UrlIMG = this.URL_BASE + '/api/v1/imgbiblio';
  selectedFileNme: File | undefined;


  // data table
  profile!: ProfileType;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  categoria: Categorias[] = [];
  categoriaSave: Categorias = new Categorias();
  categoriaUpdate: Categorias = new Categorias();
  selectedFile: File | undefined;
  nameImage: string | undefined;
  fileName: File | undefined;
  rutaImage: string | undefined;
  user: string | undefined;
  roles: any;

  constructor(
    private modalService: NgbModal,
    private title: Title,
    usernameElement: ElementRef,
    private cateService: CategoriaService,
    private toastService: ToastService,
    private http: HttpClient,
    private fileService: FileService,
    private service: ServiceService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Listar categorias | Reservas');
    this.categoriaList();
    this.getRolList();

  }

  getRolList() {
    this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
      this.profile = profile;
      this.user = this.profile.userPrincipalName;
      this.service.RolUser(''+this.user+'').subscribe((data) => {
        this.roles = data.rol;
      });
    });
  }

  // listar categorias
  categoriaList() {
    this.cateService.getCategoria().subscribe((data) => {
      this.categoria = data;
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json',
        },
      };

      this.dtTrigger.next();
    });

  }

  dataChanged(event: any) {
    console.log("img upload");
    let files = event.srcElement.files;
    console.log(files);
    this.selectedFileNme = event.target.files[0];
    this.selectedFile = event.target.files[0];
    this.categoriaSave.rutaImg = this.selectedFileNme?.name;
    this.categoriaUpdate.rutaImg = this.selectedFileNme?.name;

    this.fileName = event.target.files[0];
      let formData = new FormData();
      formData.append("files", event.target.files[0], event.target.files[0].name);
      console.log(this.fileName);
    //Make a call to the Spring Boot Application to save the image
    this.fileService.upload(formData).subscribe((response) => {
      this.showUpdateImg();
    },(err) => {
      this.showUpdateImgError();
    });
  }

  // agregar registro
  saveRegister(cate: Categorias) {
    this.cateService.postCategorias(cate).subscribe((data) => {
      this.showSuccess();
      location.reload();
    });
  }

  // actualizar registros
  updateShow(cate: Categorias): void {
    localStorage.setItem('id', cate.id.toString());
    console.log();
    this.categoriaUpdate.nombreCategoria = cate.nombreCategoria;
    this.categoriaUpdate.descripcion = cate.descripcion;
    this.categoriaUpdate.ubicacion = cate.ubicacion;
    this.categoriaUpdate.tiempoEspera = cate.tiempoEspera;
    this.categoriaUpdate.tiempoUso = cate.tiempoUso;
    this.rutaImage = cate.rutaImg;
    this.categoriaUpdate.maximoDia = cate.maximoDia;
    this.categoriaUpdate.horarioAtencion = cate.horarioAtencion;
    this.categoriaUpdate.diasNoLabora = cate.diasNoLabora;
    this.categoriaUpdate.validarReservas = cate.validarReservas;

    console.log(this.categoriaUpdate.rolAccess);
    console.log(this.categoriaUpdate.validarReservas);
    this.categoriaUpdate.rolAccess = cate.rolAccess;

    this.categoriaUpdate.rutaImg = cate.rutaImg?.replace(
      'https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/images/',
      ''
    );
    this.nameImage = cate.rutaImg?.replace(
      'https://bibliotecautpl.utpl.edu.ec/SistemaReservasApp/api/v1/images/',
      ''
    );
    this.categoriaUpdate.id = cate.id;
  }
  actualizar(cate: Categorias) {
    console.log(this.selectedFile);
    console.log(this.fileName);

    this.cateService.UpadteRegistro(cate).subscribe((data) => {
      this.categoriaUpdate = data;
      this.showSuccess();
      location.reload();
    });
  }

  //eliminaión logica del registro
  deleteEnable(cate: Categorias) {
    this.cateService.deleteEnableRegistro(cate).subscribe((data) => {
      this.categoria = this.categoria.filter((r) => r !== cate);
      this.showDanger();
    }, (err) => {
      // Entra aquí si el servicio entrega un código http de error EJ: 404, 500
      this.showNotDelete();
    });
  }



  // control de la ventana emergente
  openLg(content: any) {
    this.modalService.open(content, { scrollable: true });
  }
  open(update: any) {
    this.modalService.open(update, { scrollable: true });
  }

  // alertas
  showSuccess() {
    this.toastService.show('💾 Registro añadido con exito...!!!', {
      classname: 'bg-info',
      delay: 5000,
    });
  }
  showDanger() {
    this.toastService.show('❌ Registro Eliminado con exito...!!!', {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  showNotDelete() {
    this.toastService.show('⚠ No se puede eliminar el registro, exite un relación asociada a este registro', {
      classname: 'bg-danger text-light',
      delay: 9000,
    });
  }
  showUpdate() {
    this.toastService.show('📝 Registro actualizado con exito...!!!', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showUpdateImg() {
    this.toastService.show('🏞 Imagen cargada con exito', {
      classname: 'bg-info',
      delay: 7000,
    });
  }

  showUpdateImgError() {
    this.toastService.show('❌ No se pudo cargada la imagen con exito', {
      classname: 'bg-danger text-light',
      delay: 7000,
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
}
