<!-- Cargar menu de navigation-->
<app-navbarv2></app-navbarv2>

<section class="m-2"
  *ngIf=" roles == 'ESTUDIANTE' || roles == 'DOCENTE' || roles === 'TECNICO DOCENTE' || roles == 'ADMINISTRADOR' || roles == 'ADMINISTRATIVO'  || roles == 'AUTORIDADES'  || roles == 'EVENTOS' || roles == 'ADMIN_COMPUTO'  || roles == 'ADMIN_VIDEO_CONFERENCIA' || roles == 'ADMIN_SALA_ACAD' || roles == 'BIBLIOTECARIO'  || roles == 'ADMIN_APASUTPL' || roles == 'BIBLIOTECA_AUTO' || roles == 'ADMIN_SLREUNIONES'  || roles == 'ADMIN_SLREUNIONES_EF_D' || roles == 'ADMIN_SLREUNIONES_EF_C' || roles == 'ADMIN_SLREUNIONES_EF_F'">

  <div class="card colorCard">
    <div class="card-body text-center titlePage">
      <strong>Historial de reservas | Reservas UTPL</strong>
      <div class="form-text">Reservas realizadas | <b>{{horaDiv}}</b></div>
    </div>
  </div>
  <br>

  <div class="m-3">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Reservas de hoy</a>
        <ng-template ngbNavContent>
          <!-- <div class="m-3" id="dcalc">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col" *ngFor="let reserva of reservas">
                <div class="card h-100">
                  <div class="card-header">
                    <h2><i class="bi bi-check-circle-fill ColorBien"></i> {{reserva.recurso.nombreRecurso}} |
                      {{reserva.recurso.pisos.nombrePiso}}</h2>
                  </div>
                  <img src="{{reserva.recurso.categoria.rutaImg}}" class="" alt="{{reserva.recurso.categoria.rutaImg}}">
                  <div class="card-body">
                    <div class=" card-text">
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Recurso Reservado</strong></i></label> <br>
                        <label class="form-label">El recurso reservado es
                          <b><i>{{reserva.recurso.nombreRecurso}}. </i></b><br>
                          Tiene una capacidad de <b><i>{{reserva.recurso.capacidad}}</i></b> persona</label>
                      </div>
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Hora y fecha de la reserva:</strong></i></label> <br>
                        <label class="form-label">Desde <b><i>{{reserva.fechaReservaInicio}}</i></b> - hasta
                          <b><i>{{reserva.fechaReservaFin}}</i></b> </label>
                      </div>
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Nombre y Apellidos que quien reserva:</strong></i></label>
                        <br>
                        <label class="form-label">{{reserva.namesReservador}}</label>
                      </div>
                      <div class=" mb-3" *ngIf="reserva.fechaReservaInicio > horaDiv">
                        <label class="form-label"><i><strong>Estado de entrada:</strong></i></label> <br>
                        <label class="form-label " *ngIf="reserva.accesoBibliteca==='Si'"><i
                            class="bi bi-lock-fill fs-4 mb-3 ColorBien"></i> Si puede acceder</label>
                        <label class="form-label " *ngIf="reserva.accesoBibliteca==='No'"><i
                            class="bi bi-patch-exclamation-fill fs-4 mb-3 ColorMal"></i> {{reserva.estadoUso}}</label>
                        <br>
                        <div class=" mb-3  d-flex justify-content-center" *ngIf="reserva.fechaReservaInicio > horaDiv">
                          <button class="btn colorUTPLBottonDpl m-1 " placement="top" ngbTooltip="editar"
                            (click)="editarReserva(reserva)" (click)="openLg(editar)"><i
                              class="bi bi-pencil-square"></i> Editar
                            Reserva</button>
                          <button class="btn btn-outline-danger m-1 " placement="top" ngbTooltip="Eliminar"
                            (click)="deleteEnable(reserva)"> <i class="bi bi-trash-fill"></i> Eliminar Reserva</button>
                        </div>
                      </div>
                      <div class=" mb-3" *ngIf="reserva.fechaReservaInicio < horaDiv">
                        <label class="form-label"><i><strong>Reserva activa o
                              finalizada</strong></i></label>&nbsp;&nbsp;
                        <button class="btn colorUTPLBottonDpl2" placement="top"
                          ngbTooltip="Reserva activa o finalizada"> <i class="bi bi-check-circle-fill"></i></button>
                      </div>

                    </div>
                  </div>
                  <div class="card-footer">
                    <small class="text-muted">Su reserva fue realizada el {{reserva.fechaRegistro}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card m-3"  *ngFor="let reserva of reservas">
            <div class="card-header">
              <div class="row">
                <div class=" cardFont"><i class="bi bi-geo-fill"></i> {{reserva.recurso.categoria.nombreCategoria}}</div>
                <div class=" cardFont">{{reserva.recurso.nombreRecurso}}</div>
                <div class=" cardFont2">{{reserva.recurso.pisos.nombrePiso}}</div>
              </div>
              <div class="cardFont"><div class="cardFont2"></div></div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <img src="{{reserva.recurso.categoria.rutaImg}}" class="col-md-12"
                    src="{{reserva.recurso.categoria.rutaImg}}">
                </div>
                <br>
                <div class="col-md-6">

                  <!-- Recurso Reservado-->
                  <div class=" mb-2">
                    <label class="form-label"><label><strong>Recurso Reservado: </strong></label></label> <br>
                    <label class="form-label">El recurso reservado es
                      <b>{{reserva.recurso.nombreRecurso}}.</b><br>
                      Capacidad máxima de <b>{{reserva.recurso.capacidad}}</b> persona (s)</label>
                  </div>

                  <!-- Hora y fecha de la reserva-->
                  <div class=" mb-3">
                    <label class="form-label"><strong>Hora y fecha de la reserva:</strong></label> <br>
                    <label class="form-label">Desde <b>{{reserva.fechaReservaInicio?.replace(" ", " | ")}}</b> - hasta
                      <b>{{reserva.fechaReservaFin?.replace(" ", " | ")}}</b> </label>
                  </div>

                  <!-- nombres de quien reservo-->
                  <div class=" mb-3">
                    <label class="form-label"><strong>Nombres y Apellidos de quién realizó la reserva:</strong></label>
                    <br>
                    <label class="form-label">{{reserva.namesReservador}}</label>
                  </div>
                  <div class=" mb-3" *ngIf="reserva.fechaReservaInicio < horaDiv">
                    <label class="form-label"><label><strong>Reserva activa o finalizada</strong></label></label>&nbsp;&nbsp;
                    <button class="btn colorUTPLBottonDpl2" placement="top" ngbTooltip="Reserva activa o finalizada">
                      <i class="bi bi-check-circle-fill"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <small class="text-muted">La reserva se realizó el {{reserva.fechaRegistro}}</small>
            </div>
          </div>

          <div class="container col-md-11 pt-4" *ngIf="reservas?.length == 0" style="padding-bottom: 19%;">
            <div class="card ">
              <h5 class="card-header"><i class="bi bi-x-circle-fill ColorMal"></i> Alerta</h5>
              <div class="card-body">
                <p class="card-text">No existen reservas planificadas para hoy</p>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Próximas reservas</a>
        <ng-template ngbNavContent>
          <!-- <div class="m-3" id="dcalc">
            <div class="row row-cols-1 row-cols-md-1 g-4">
              <div class="col" *ngFor="let reserva of reservasTomorrow">
                <div class="card h-100">
                  <div class="card-header">
                    <h2><i class="bi bi-check-circle-fill ColorBien"></i> {{reserva.recurso.nombreRecurso}} |
                      {{reserva.recurso.pisos.nombrePiso}}</h2>
                  </div>
                  <img src="{{reserva.recurso.categoria.rutaImg}}" class="" alt="{{reserva.recurso.categoria.rutaImg}}">
                  <div class="card-body">
                    <div class=" card-text">
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Recurso Reservado</strong></i></label> <br>
                        <label class="form-label">El recurso reservado es
                          <b><i>{{reserva.recurso.nombreRecurso}}. </i></b><br>
                          Tiene una capacidad de <b><i>{{reserva.recurso.capacidad}}</i></b> persona</label>
                      </div>
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Hora y fecha de la reserva:</strong></i></label> <br>
                        <label class="form-label">Desde <b><i>{{reserva.fechaReservaInicio}}</i></b> - hasta
                          <b><i>{{reserva.fechaReservaFin}}</i></b> </label>
                      </div>
                      <div class=" mb-3">
                        <label class="form-label"><i><strong>Nombre y Apellidos que quien reserva:</strong></i></label> <br>
                        <label class="form-label">{{reserva.namesReservador}}</label>
                      </div>
                      <div class=" mb-3" *ngIf="reserva.fechaReservaInicio > horaDiv">
                        <label class="form-label"><i><strong>Estado de entrada:</strong></i></label> <br>
                        <label class="form-label " *ngIf="reserva.accesoBibliteca==='Si'"><i
                            class="bi bi-lock-fill fs-4 mb-3 ColorBien"></i> Si puede acceder</label>
                        <label class="form-label " *ngIf="reserva.accesoBibliteca==='No'"><i
                            class="bi bi-patch-exclamation-fill fs-4 mb-3 ColorMal"></i> {{reserva.estadoUso}}</label>
                        <br>
                        <div class=" mb-3  d-flex justify-content-center" *ngIf="reserva.fechaReservaInicio > horaDiv">
                          <button class="btn colorUTPLBottonDpl m-1 " placement="top" ngbTooltip="editar"
                            (click)="editarReserva(reserva)" (click)="openLg(editar)"><i class="bi bi-pencil-square"></i> Editar
                            Reserva</button>
                          <button class="btn btn-outline-danger m-1 " placement="top" ngbTooltip="Eliminar"
                            (click)="deleteEnable(reserva)"> <i class="bi bi-trash-fill"></i> Eliminar Reserva</button>
                        </div>
                      </div>
                      <div class=" mb-3" *ngIf="reserva.fechaReservaInicio < horaDiv">
                        <label class="form-label"><i><strong>Reserva activa o finalizada</strong></i></label>&nbsp;&nbsp;
                        <button class="btn colorUTPLBottonDpl2" placement="top" ngbTooltip="Reserva activa o finalizada"> <i
                            class="bi bi-check-circle-fill"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <small class="text-muted">Su reserva fue realizada el {{reserva.fechaRegistro}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="card m-3"  *ngFor="let reserva of reservasTomorrow">
            <div class="card-header">
              <div class="row">
                <div class=" cardFont">{{reserva.recurso.nombreRecurso}}</div>
                <div class=" cardFont2">{{reserva.recurso.pisos.nombrePiso}}</div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-5">
                  <img src="{{reserva.recurso.categoria.rutaImg}}" class="col-md-12"
                    src="{{reserva.recurso.categoria.rutaImg}}">
                </div>
                <br>
                <div class="col-md-6">

                  <!-- Recurso Reservado-->
                  <div class=" mb-2">
                    <label class="form-label"><label><strong>Recurso Reservado: </strong></label></label> <br>
                    <label class="form-label">El recurso reservado es
                      <b>{{reserva.recurso.nombreRecurso}}.</b><br>
                      Tiene una capacidad de <b>{{reserva.recurso.capacidad}}</b> persona</label>
                  </div>

                  <!-- Hora y fecha de la reserva-->
                  <div class=" mb-3">
                    <label class="form-label"><strong>Hora y fecha de la reserva:</strong></label> <br>
                    <label class="form-label">Desde <b>{{reserva.fechaReservaInicio?.replace(" ", " | ")}}</b> - hasta
                      <b>{{reserva.fechaReservaFin?.replace(" ", " | ")}}</b> </label>
                  </div>

                  <!-- nombres de quien reservo-->
                  <div class=" mb-3">
                    <label class="form-label"><strong>Nombres y Apellidos de quién realizó la reserva:</strong></label>
                    <br>
                    <label class="form-label">{{reserva.namesReservador}}</label>
                  </div>
                  <div class=" mb-3" *ngIf="reserva.fechaReservaInicio < horaDiv">
                    <label class="form-label"><label><strong>Reserva activa o finalizada</strong></label></label>&nbsp;&nbsp;
                    <button class="btn colorUTPLBottonDpl2" placement="top" ngbTooltip="Reserva activa o finalizada">
                      <i class="bi bi-check-circle-fill"></i></button>
                  </div>
                </div>
              </div>
            </div>


            <div class="card-footer">
              <small class="text-muted">La reserva se realizó el {{reserva.fechaRegistro}}</small>
            </div>
          </div>

          <div class="container col-md-11 pt-4" *ngIf="reservasTomorrow?.length == 0" style="padding-bottom: 19%;">
            <div class="card">
              <h5 class="card-header"><i class="bi bi-x-circle-fill ColorMal"></i> Alerta</h5>
              <div class="card-body">
                <p class="card-text">No existen reservas planificadas para los próximos días</p>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <pre>Página: {{ active }}</pre>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

</section>

<section class="m-2" *ngIf="roles == 'null'   || roles == 'ADMIN-BBC' || roles == 'ALUMNI'">
  <div class="container">

    <div class="row text-center">
      <br><br><br><br>
      <img src="./assets/img/403-forbidden.png" col-12>
      <hr class="col-12">
      <h1>
        Usted no tiene permisos para realizar esta acción
      </h1>
    </div>

  </div>

  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</section>

<!--  Version 2 - formulario para editar una reserva -->
<ng-template #FormularioEdit let-c="close" let-d="dismiss">
  <div class="modal-header ">
    <h4 class="modal-title">Formulario de edición de Reserva Biblioteca</h4>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <mat-card class="colorCard text-center">
        <h3><b>Editar reserva para recurso de Biblioteca</b></h3>
      </mat-card>
      <div class="row m-2">

        <!-- Datos per.-->
        <div class="col-md-12 p-2" style="display: none;">
          <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs" #nombresApellidosEs
            [(ngModel)]="reservas_editar.namesReservador" disabled>
        </div>
        <div class="col-md-12 p-2" style="display: none;">
          <label for="emailReservador" class="form-label">Email institucional<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
            [(ngModel)]="reservas_editar.emailReservador" disabled>
        </div>

        <!-- Categorías-->
        <div class="col-md-12 p-2">
          <label for="categoriaReserva" class="form-label">Categorías<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="categoriaReserva" id="categoriaReserva"
            [(ngModel)]="reservas_editar.categoriaReserva" (change)="desactivarInpuntEditV2()">
            <option *ngFor="let cate of cateList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
          </select>
        </div>

        <!-- Piso-->
        <div class="col-md-12 p-2" >
          <label for="pisoReserva" class="form-label">Piso<i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="pisoReserva" id="pisoReserva"
            [(ngModel)]="reservas_editar.pisoReserva" (change)="desactivarInpuntEditV2()" >
            <option *ngFor="let pisos of pisosListado" value="{{pisos.id}}">{{pisos.nombrePiso}}</option>
          </select>
        </div>


        <!-- fecha Reserva Inicio y Fin-->
        <div class="col-md-12 p-2 row">
          <div class="col-lg-6 col">
            <label for="fechaReservaInicio" class="form-label">Fecha Reserva Inicio<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
              id="fechaReservaInicio" [(ngModel)]="reservas_editar.fechaReservaInicio"
               (change)="changeFechaEditV2()">
               <option selected>Seleccione</option>
              <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                {{fechals.fechaActual}} {{fechals.horaInicio}}</option>
            </select>
          </div>
          <div class="col-lg-6 col">
            <label for="fechaReservaFin" class="form-label">Fecha Reserva Fin<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaFin" id="fechaReservaFin"
              [(ngModel)]="reservas_editar.fechaReservaFin" disabled>
              <option value="{{reservas_editar.fechaReservaFin}}">{{reservas_editar.fechaReservaFin}}</option>
            </select>

          </div>
        </div>


        <!-- Recurso de Biblioteca-->
        <div class="col-md-12 p-2" >
          <label for="idRecurso" class="form-label">Recurso de Biblioteca<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idRecurso" id="idRecurso"
            [(ngModel)]="reservas_editar.idRecurso" (change)="changeRecursoSelectV2()">
            <option selected>Seleccione</option>
            <option *ngFor="let bsc of recursoList" value="{{bsc.id}}">{{bsc.nombreRecurso}}</option>
          </select>
        </div>


        <!-- Información adicional-->
        <div class="col-md-12 p-2" >
          <hr class="container col-11">
          <div class="container p-2">
            <div class="row justify-content-center">
              <div class="col-6" style="text-align: center;vertical-align: middle;">
                <i><b>Información de Ubicación del recurso seleccionado.</b></i>
              </div>
              <div class="col-4">
                <button type="button" class="btn btn-info" (click)="showInfo()" *ngIf="showMore"><i
                    class="bi bi-chevron-double-down"></i> Ver más</button>
                <button type="button" class="btn btn-danger" (click)="showInfoLess()" *ngIf="showLess"><i
                    class="bi bi-chevron-double-up"></i> Ver menos</button>
              </div>
            </div>
          </div>

          <div class="container col-md-11" *ngIf="editedInfo">
            <hr class="container col-11">
            <div class="col-md-12 p-2" style="display: none">
              <label for="materialReservado" class="form-label">Nombre<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="materialReservado" name="materialReservado"
                [(ngModel)]="reservas_editar.materialReservado" disabled>
            </div>
            <div class="col-md-12 mb-2">
              <label for="capacidadUso" class="form-label"> Capacidad de uso del recurso
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="input-group mb-3">
                <input type="number" class="form-control" aria-label="capacidadUso" id="capacidadUso"
                  name="capacidadUso" aria-describedby="basic-addon2" [(ngModel)]="capacidadUso" disabled>
                <span class="input-group-text" id="basic-addon2">Personas</span>
              </div>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoDescripcion" class="form-label">Descripción<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoDescripcion" name="infoDescripcion"
                [(ngModel)]="infoDescripcion" disabled>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoUbicacion" class="form-label">Ubicación<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoUbicacion" name="infoUbicacion"
                [(ngModel)]="infoUbicacion" disabled>
            </div>

            <div class="col-md-12 p-2">
              <label for="ubicacion" class="form-label">Imagen de referenia
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="container col-md-6 ">
                <img src="{{imgRuta}}" class="img-fluid rounded" alt="{{imgRuta}}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6 col">
        <button type="submit" (click)="updateShow(reservas_editar)"
        (click)="d('Cross click')" class="btn colorUTPLBottonDpl">Actualizar
        </button>
      </div>
      <div class="col-md-6 col">
        <button type="button" class="btn btn-danger" (click)="d('Cross click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>


<!-- formulario V2 para crear una reserva -->
<ng-template #FormularioCreate let-c="close" let-d="dismiss">
  <div class="modal-header ">
    <h4 class="modal-title">Formulario de Reserva Biblioteca</h4>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <mat-card class="colorCard text-center">
        <h3><b>Crear reserva para recurso de Biblioteca</b></h3>
      </mat-card>
      <div class="row m-2">

        <!-- Datos per.-->
        <div class="col-md-12 p-2" style="display: none;">
          <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs" #nombresApellidosEs
            [(ngModel)]="reservas_save.namesReservador" disabled>
        </div>
        <div class="col-md-12 p-2" style="display: none;">
          <label for="emailReservador" class="form-label">Email institucional<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
            [(ngModel)]="reservas_save.emailReservador" disabled>
        </div>

        <!-- Categorías-->
        <div class="col-md-12 p-2">
          <label for="categoriaReserva" class="form-label">Categorías<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="categoriaReserva" id="categoriaReserva"
            [(ngModel)]="reservas_save.categoriaReserva" (change)="PisoChanged()" (change)="desactivarInpuntV2()">
            <option *ngFor="let cate of cateList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
          </select>
        </div>

        <!-- Piso-->
        <div class="col-md-12 p-2" *ngIf="edited">
          <label for="pisoReserva" class="form-label">Piso<i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="pisoReserva" id="pisoReserva"
            [(ngModel)]="reservas_save.pisoReserva" (change)="dataChangedv2($event)">
            <option *ngFor="let pisos of pisosListado" value="{{pisos.id}}">{{pisos.nombrePiso}}</option>
          </select>
        </div>

        <!-- Fecha de la reserva-->
        <div class="col-md-12 p-2" *ngIf="edited2">
          <label for="idRecurso" class="form-label">Fecha de la reserva<i
              style="color: red;"><strong>*</strong></i></label>
          <div class="row">
            <div class="col-md-12 col">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" type="text" name="dp" id="fechaRsrv" ngbDatepicker
                  #d="ngbDatepicker" [(ngModel)]="datepickers" #fechaRsrv (click)="d.toggle()"
                  (dateSelect)="changeDatepikersV2($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" (dateSelect)="changeDatepikersV2($event)"
                    type="button"><i class="bi bi-calendar3"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- fecha Reserva Inicio y Fin-->
        <div class="col-md-12 p-2 row" *ngIf="edited3_1">
          <div class="col-lg-6 col">
            <label for="fechaReservaInicio" class="form-label">fecha Reserva Inicio<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
              id="fechaReservaInicio" [(ngModel)]="reservas_save.fechaReservaInicio" (change)="changeFechaV2($event)">
              <option selected>Seleccione</option>
              <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                {{fechals.fechaActual}} {{fechals.horaInicio}}</option>

            </select>
          </div>
          <div class="col-lg-6 col">
            <label for="fechaReservaFin" class="form-label">fecha Reserva Fin<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaFin" id="fechaReservaFin"
              [(ngModel)]="reservas_save.fechaReservaFin" disabled>
              <option value="{{reservas_save.fechaReservaFin}}">{{reservas_save.fechaReservaFin}}</option>
            </select>

          </div>
        </div>


        <!-- Recurso de Biblioteca-->
        <div class="col-md-12 p-2" *ngIf="edited3">
          <label for="idRecurso" class="form-label">Recurso de Biblioteca<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idRecurso" id="idRecurso" (change)="changeRecursoSelectSaveV2()"
            [(ngModel)]="reservas_save.idRecurso">
            <option selected>Seleccione</option>
            <option *ngFor="let bsc of recursoList" value="{{bsc.id}}">{{bsc.nombreRecurso}}</option>
          </select>
        </div>


        <!-- Información adicional-->
        <div class="col-md-12 p-2" *ngIf="edited3">
          <hr class="container col-11">
          <div class="container p-2">
            <div class="row justify-content-center">
              <div class="col-6" style="text-align: center;vertical-align: middle;">
                <i><b>Información de Ubicación del recurso seleccionado.</b></i>
              </div>
              <div class="col-4">
                <button type="button" class="btn btn-info" (click)="showInfo()" *ngIf="showMore"><i
                    class="bi bi-chevron-double-down"></i> Ver más</button>
                <button type="button" class="btn btn-danger" (click)="showInfoLess()" *ngIf="showLess"><i
                    class="bi bi-chevron-double-up"></i> Ver menos</button>
              </div>
            </div>
          </div>

          <div class="container col-md-11" *ngIf="editedInfo">
            <hr class="container col-11">
            <div class="col-md-12 p-2" style="display: none">
              <label for="materialReservado"  class="form-label">Nombre<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="materialReservado" name="materialReservado"
                [(ngModel)]="reservas_save.materialReservado" disabled>
            </div>
            <div class="col-md-12 mb-2">
              <label for="capacidadUso" class="form-label"> Capacidad de uso del recurso
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="input-group mb-3">
                <input type="number" class="form-control" aria-label="capacidadUso" id="capacidadUso"
                  name="capacidadUso" aria-describedby="basic-addon2" [(ngModel)]="capacidadUso" disabled>
                <span class="input-group-text" id="basic-addon2">Personas</span>
              </div>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoDescripcion" class="form-label">Descripción<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoDescripcion" name="infoDescripcion"
                [(ngModel)]="infoDescripcion" disabled>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoUbicacion" class="form-label">Ubicación<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoUbicacion" name="infoUbicacion"
                [(ngModel)]="infoUbicacion" disabled>
            </div>

            <div class="col-md-12 p-2">
              <label for="ubicacion" class="form-label">Imagen de referenia
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="container col-md-6 ">
                <img src="{{imgRuta}}" class="img-fluid rounded" alt="{{imgRuta}}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6 col">
        <button type="submit" [disabled]="!reservas_save.idRecurso" (click)="guardar(reservas_save)"
        (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6 col">
        <button type="button" class="btn btn-danger" (click)="d('Cross click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario V3 para crear una reserva -->
<ng-template #FormularioCreateV3 let-c="close" let-d="dismiss">
  <div class="modal-header ">
    <h4 class="modal-title">Formulario de Reserva Biblioteca</h4>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <mat-card class="colorCard text-center">
        <h3><b>Crear reserva para recurso de Biblioteca</b></h3>
      </mat-card>
      <div class="row m-2">

        <!-- Datos per.-->
        <div class="col-md-12 p-2" style="display: none;">
          <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs" #nombresApellidosEs
            [(ngModel)]="reservas_save.namesReservador" disabled>
        </div>
        <div class="col-md-12 p-2" style="display: none;">
          <label for="emailReservador" class="form-label">Email institucional<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
            [(ngModel)]="reservas_save.emailReservador" disabled>
        </div>

        <!-- Categorías-->
        <div class="col-md-12 p-2">
          <label for="categoriaReserva" class="form-label">Categorías<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="categoriaReserva" id="categoriaReserva"
            [(ngModel)]="reservas_save.categoriaReserva" (change)="PisoChanged()" (change)="desactivarInpuntV2()">
            <option *ngFor="let cate of cateList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
          </select>
        </div>

        <!-- Piso-->
        <div class="col-md-12 p-2" *ngIf="edited">
          <label for="pisoReserva" class="form-label">Piso<i style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="pisoReserva" id="pisoReserva"
            [(ngModel)]="reservas_save.pisoReserva" (change)="dataChangedv2($event)">
            <option *ngFor="let pisos of pisosListado" value="{{pisos.id}}">{{pisos.nombrePiso}}</option>
          </select>
        </div>

        <!-- Fecha de la reserva-->
        <div class="col-md-12 p-2" *ngIf="edited2">
          <label for="idRecurso" class="form-label">Fecha de la reserva<i
              style="color: red;"><strong>*</strong></i></label>
          <div class="row">
            <div class="col-md-12 col">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" type="text" name="dp" id="fechaRsrv" ngbDatepicker
                  #d="ngbDatepicker" [(ngModel)]="datepickers" #fechaRsrv (click)="d.toggle()"
                  (dateSelect)="changeDatepikersV2($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" (dateSelect)="changeDatepikersV2($event)"
                    type="button"><i class="bi bi-calendar3"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- fecha Reserva Inicio y Fin-->
        <div class="col-md-12 p-2 row" *ngIf="edited3_1">
          <div class="col-lg-6 col">
            <label for="fechaReservaInicio" class="form-label">fecha Reserva Inicio<i
                style="color: red;"><strong>*</strong></i></label>
            <ngb-timepicker [(ngModel)]="timesStr" [meridian]="meridian" [minuteStep]="minuteStep" [readonlyInputs]="true"></ngb-timepicker>
            <hr>
            <button class="btn btn-default" type="button" (click)="enviarvalor()">Enviar</button>
            <pre>Selected time: {{time | json}}</pre>
            <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
              id="fechaReservaInicio" [(ngModel)]="reservas_save.fechaReservaInicio" (change)="changeFechaV2($event)">
              <option selected>Seleccione</option>
              <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                {{fechals.fechaActual}} {{fechals.horaInicio}}</option>

            </select>
          </div>
          <div class="col-lg-6 col">
            <label for="fechaReservaFin" class="form-label">fecha Reserva Fin<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaFin" id="fechaReservaFin"
              [(ngModel)]="reservas_save.fechaReservaFin" disabled>
              <option value="{{reservas_save.fechaReservaFin}}">{{reservas_save.fechaReservaFin}}</option>
            </select>

          </div>
        </div>


        <!-- Recurso de Biblioteca-->
        <div class="col-md-12 p-2" *ngIf="edited3">
          <label for="idRecurso" class="form-label">Recurso de Biblioteca<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="idRecurso" id="idRecurso" (change)="changeRecursoSelectSaveV2()"
            [(ngModel)]="reservas_save.idRecurso">
            <option selected>Seleccione</option>
            <option *ngFor="let bsc of recursoList" value="{{bsc.id}}">{{bsc.nombreRecurso}}</option>
          </select>
        </div>


        <!-- Información adicional-->
        <div class="col-md-12 p-2" *ngIf="edited3">
          <hr class="container col-11">
          <div class="container p-2">
            <div class="row justify-content-center">
              <div class="col-6" style="text-align: center;vertical-align: middle;">
                <i><b>Información de Ubicación del recurso seleccionado.</b></i>
              </div>
              <div class="col-4">
                <button type="button" class="btn btn-info" (click)="showInfo()" *ngIf="showMore"><i
                    class="bi bi-chevron-double-down"></i> Ver más</button>
                <button type="button" class="btn btn-danger" (click)="showInfoLess()" *ngIf="showLess"><i
                    class="bi bi-chevron-double-up"></i> Ver menos</button>
              </div>
            </div>
          </div>

          <div class="container col-md-11" *ngIf="editedInfo">
            <hr class="container col-11">
            <div class="col-md-12 p-2" style="display: none">
              <label for="materialReservado"  class="form-label">Nombre<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="materialReservado" name="materialReservado"
                [(ngModel)]="reservas_save.materialReservado" disabled>
            </div>
            <div class="col-md-12 mb-2">
              <label for="capacidadUso" class="form-label"> Capacidad de uso del recurso
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="input-group mb-3">
                <input type="number" class="form-control" aria-label="capacidadUso" id="capacidadUso"
                  name="capacidadUso" aria-describedby="basic-addon2" [(ngModel)]="capacidadUso" disabled>
                <span class="input-group-text" id="basic-addon2">Personas</span>
              </div>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoDescripcion" class="form-label">Descripción<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoDescripcion" name="infoDescripcion"
                [(ngModel)]="infoDescripcion" disabled>
            </div>
            <div class="col-md-12 p-2">
              <label for="infoUbicacion" class="form-label">Ubicación<i
                  style="color: red;"><strong>*</strong></i></label>
              <input type="text" class="form-control" id="infoUbicacion" name="infoUbicacion"
                [(ngModel)]="infoUbicacion" disabled>
            </div>

            <div class="col-md-12 p-2">
              <label for="ubicacion" class="form-label">Imagen de referenia
                <i style="color: red;"><strong>*</strong></i></label>
              <div class="container col-md-6 ">
                <img src="{{imgRuta}}" class="img-fluid rounded" alt="{{imgRuta}}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6 col">
        <button type="submit" [disabled]="!reservas_save.idRecurso" (click)="guardar(reservas_save)"
        (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6 col">
        <button type="button" class="btn btn-danger" (click)="d('Cross click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>

<!-- formulario V2 para crear una prestamo o Devolución -->
<ng-template #FormularioPrestamo let-c="close" let-d="dismiss">
  <div class="modal-header ">
    <h4 class="modal-title">Formulario de cita para prestamo o devolución de libros</h4>
    <button type="button" class="btn" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" method="post" enctype="multipart/form-data">
      <mat-card class="colorCard text-center">
        <h3><b>Crear cita para prestamo o devolución de libros de Biblioteca</b></h3>
      </mat-card>
      <div class="row m-2">

        <!-- Datos per.-->
        <div class="col-md-12 p-2" style="display: none;">
          <label for="nombresApellidosEs" class="form-label">Nombre y Apellidos del reservador<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="nombresApellidosEs" name="nombresApellidosEs" #nombresApellidosEs
            [(ngModel)]="prestamo_save.namesReservador" disabled>
        </div>
        <div class="col-md-12 p-2" style="display: none;">
          <label for="emailReservador" class="form-label">Email institucional<i
              style="color: red;"><strong>*</strong></i></label>
          <input type="text" class="form-control" id="emailReservador" name="emailReservador" #emailReservador
            [(ngModel)]="prestamo_save.emailReservador" disabled>
        </div>

        <!-- Categorías-->
        <div class="col-md-12 p-2">
          <label for="tipoReserva" class="form-label">Categorías de Cita<i
              style="color: red;"><strong>*</strong></i></label>
          <select class="form-select" aria-label="Default select example" name="tipoReserva" id="tipoReserva"
            [(ngModel)]="prestamo_save.tipoReserva" (change)="showFechaPrestamo()"  (change)="desactivarInpuntPrestamoV2()" >
            <option *ngFor="let cate of catePrestamosList" value="{{cate.id}}">{{cate.nombreCategoria}}</option>
          </select>
        </div>

        <!-- Fecha de la reserva-->
        <div class="col-md-12 p-2" *ngIf="showDatePrestamo">
          <label for="idRecursos" class="form-label">Fecha de la reserva<i
              style="color: red;"><strong>*</strong></i></label>
          <div class="row">
            <div class="col-md-12 col">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" type="text" name="dp" id="fechaRsrvPresmo" ngbDatepicker
                  #d="ngbDatepicker" [(ngModel)]="datepickersPrestamo" #fechaRsrvPresmo (click)="d.toggle()"
                  (dateSelect)="DatepikersPrestamoV2($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" (dateSelect)="DatepikersPrestamoV2($event)"
                    type="button"><i class="bi bi-calendar3"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- fecha Reserva Inicio y Fin-->
        <div class="col-md-12 p-2 row" *ngIf="selectDate_prestamo">
          <div class="col-lg-6 col">
            <label for="fechaReservaInicio" class="form-label">fecha Reserva Inicio<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaInicio"
              id="fechaReservaInicio" [(ngModel)]="prestamo_save.fechaReservaInicio" (change)="changeFechaPrestamoV2($event)">
              <option selected>Seleccione</option>
              <option *ngFor="let fechals of fechaListV2" value="{{fechals.fechaActual}} {{fechals.horaInicio}}">
                {{fechals.fechaActual}} {{fechals.horaInicio}}</option>

            </select>
          </div>
          <div class="col-lg-6 col">
            <label for="fechaReservaFin" class="form-label">fecha Reserva Fin<i
                style="color: red;"><strong>*</strong></i></label>
            <select class="form-select" aria-label="Default select example" name="fechaReservaFin" id="fechaReservaFin"
              [(ngModel)]="prestamo_save.fechaReservaFin" disabled>
              <option value="{{prestamo_save.fechaReservaFin}}">{{prestamo_save.fechaReservaFin}}</option>
            </select>

          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-md-6 col">
        <button type="submit" [disabled]="!prestamo_save.fechaReservaFin" (click)="guardarPrestamo(prestamo_save)"
        (click)="d('Cross click')" class="btn btn-primary">Guardar
        </button>
      </div>
      <div class="col-md-6 col">
        <button type="button" class="btn btn-danger" (click)="d('Cross click')">Cancelar</button>

      </div>
    </div>
  </div>
</ng-template>


<div id="loading-screen" style="display:none; " #loadingScreens>
  <img src="./assets/img/spinning-circles.svg">
</div>


<ng-template let-d="dismiss" #contentModal data-bs-keyboard="false">
  <div class="m-3 textCenter">
    <h2 class="textCenter"> 📢 🛠Atención</h2>
  </div>
  <div class="modal-body">
    <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
  </div>
</ng-template>

<ng-template let-d="dismiss" #contentModalEdit data-bs-keyboard="false">
  <div class="m-3 textCenter">
    <h2 class="textCenter"> 📢 🛠 ⚙️ Atención</h2>
  </div>
  <div class="modal-body">
    <img src="./assets/img/problemas_tecnicos.jpeg" width="100%">
  </div>
</ng-template>
