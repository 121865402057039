export class Categorias {
  id!: number;
  nombreCategoria!: string;
  descripcion!: string;
  ubicacion!: string;
  estadoUso!: string;
  rutaImg!: string | undefined;
  tiempoUso!: number ;
  tiempoEspera!: number;
  enable!: boolean;
  capacidad: any;
  maximoDia!: string | undefined;
  rolAccess!: string | undefined;
  diasLabora!: string | undefined;
  diasNoLabora!: string | undefined;
  horarioAtencion!: string | undefined;
  validarReservas!: boolean;
}
