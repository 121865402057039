export const environment = {
  production: true,
  username:"reservaST",
  password:"reserv@sSTMad&12$",
  HOST: 'https://bibliotecautpl.utpl.edu.ec/appReservasUTPL'
  //HOST: 'https://bibliotecautpl.utpl.edu.ec/SistemaReservasAppDEV'
  //HOST: 'http://localhost:8183'
  //HOST: 'http://172.16.80.15:8080/appReservasUTPL'
  //HOST: 'http://172.16.80.15:8080/appDEVReservasUTPL'
  //HOST: 'https://dspace.utpl.edu.ec/appReservasUTPL'
  //HOST: 'http://localhost:9090'
};
