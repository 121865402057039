export class ReservaSave {
  id!: number;
  fechaRegistro!: string;
  fechaReservaInicio!: String;
  fechaReservaFin!: string;
  emailReservador!: string| undefined;
  namesReservador!: string | undefined;
  idUsuario!: number | undefined;
  pisoReserva: number | undefined;
  categoriaReserva!: number | undefined;
  materialReservado!: string | undefined;
  accesoBibliteca!: string | undefined;
  idRecurso!: number | undefined;
  idRecursoStr!: string | undefined;
  temaReserva!: string | undefined;
  descripcionReserva!: string | undefined;
  numeroAsistentes!: string | undefined;

}
